import { fade, fadeOut } from './../../../animations/fade';
import { MarketAnalysisDto } from './../../../model/report.interface';
import { RelatedProductsServiceService } from './../../../services/related-products-service.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-compettitors',
    templateUrl: './compettitors.component.html',
    styleUrls: ['./compettitors.component.scss'],
    animations: [fade, fadeOut]
})
export class CompettitorsComponent implements OnInit {
    compettitorsInsights: any[] = [{ benefit: true }, { benefit: false }, { benefit: false }, { benefit: true }, { benefit: true }, { benefit: true }];
    marketAnalysis$: Observable<MarketAnalysisDto>;

    constructor(private marketAnalysis: RelatedProductsServiceService) { }

    ngOnInit(): void {
        this.marketAnalysis$ = this.marketAnalysis.marketAnalysisSubject;
    }
}
