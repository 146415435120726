import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-usermanual',
    templateUrl: './usermanual.component.html',
    styleUrls: ['./usermanual.component.scss']
})
export class UsermanualComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
