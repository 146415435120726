import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import { ActivatedRoute, Route } from '@angular/router';
import AsyncResponse from '../../../app/dtos/AsyncResponse';
import { PricesService } from '../../services/prices.service';
import BrandResponse from '../../dtos/brandResponse';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {
    public brand: string;
    public brandLogo: string;
    public brandResponse: BrandResponse;
    private marketPlaceServiceSub: Subscription;
    constructor(
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private marketPlaceService: MarketPlaceService,
        private route: ActivatedRoute,
        private pricesService: PricesService
    ) { }

    public freeMonthPromotion: boolean = false;
    ngOnInit(): void {
        // this.http.get('https://hf.tranzila.com/assets/js/thostedf.js', { responseType: 'text' }).subscribe(file => {
        //     console.log(file);
        // });
        if (this.marketPlaceService.getBrand()) {
            this.brand = this.marketPlaceService.getBrand();

        } else if (this.route.snapshot.queryParams['brand']) {
            this.brand = this.route.snapshot.queryParams['brand'];
        } else {
            this.brand = PricesService.DEFAULT_BRAND;
            this.pricesService.isBrandPassedInUrl = false;
        }
        this.brandLogo = this.getLogoOfMentorBrand(this.brand);
        this.marketPlaceServiceSub = this.marketPlaceService.getPricingAccordingBrand(this.brand).subscribe(
            (response: AsyncResponse) => {
                this.brandResponse = response.data;
                this.pricesService.setPricingData(response.data);
                this.marketPlaceService.setBrandName(this.brand);
            },
            (error: any) => {
                console.log(`issue getting marketplace ${error.message}`);
            }
        );
    }

    protected getLogoOfMentorBrand(brandName: string): string {
        return environment.baseBrandUrl.replace('{{BRAND}}', brandName) + '/logo-invert.svg';
    }

    public ngOnDestroy(): void {
        this.marketPlaceServiceSub.unsubscribe();
        this.brand = PricesService.DEFAULT_BRAND;
    }
}
