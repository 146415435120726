import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AuthServiceService } from '../../../app/services/auth-service.service';

@Component({
    selector: 'bloooom-section',
    templateUrl: './bloooom-section.component.html',
    styleUrls: ['./bloooom-section.component.scss']
})
export class BloooomSectionComponent implements OnInit, AfterViewInit {
    public accountText: string = 'Create an Account';
    @Input() isSearchNeeded: boolean = true;
    @Input() description: string;
    @Input() title: string;
    @Input() mainImage?: string;

    constructor(private authService: AuthServiceService) { }

    ngOnInit(): void {
        this.getUsername().then((res: string) => {
            if (res) {
                this.accountText = 'Hi, ' + res;
            }
        });
    }
    public async logout(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
            await this.authService.logOut();
        }
    }
    public getUsername(): Promise<string> {
        return this.authService.getUsername();
    }
    ngAfterViewInit(): void {
        this.cloundsMoves();
    }
    cloundsMoves(): void {
        const allClouds: any = document.querySelectorAll('.cls-5');
        const allStamens: any = document.querySelectorAll('circle');

        allClouds.forEach((element: any) => {
            element.style.animation = `cloudMoves ${Math.random() * (30 - 20) + 20}s ${Math.random() * 3}s ease-in-out infinite`;
        });
        allStamens.forEach((element: any) => {
            element.style.animation = `stamenMoves ${Math.random() * (15 - 10) + 10}s ${Math.random() * 10}s ease-in-out infinite`;
        });
    }
}
