import { GetReportByAsinFailureAction, GetUserExceededMonthlyThresholdAction } from './../store/actions/report.actions';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { IMessageIdentifier, SendMessageGetReportByAsin } from '../events/events';
import { KeycloakService } from 'keycloak-angular';
import { ReportActionsEnum, GetReportByAsinSuccessAction, ReportIsInProgressPartialReportReturnedAction } from '../store/actions/report.actions';
import { Store } from '@ngrx/store';
import { IApplicationState } from '../store/state/application.state';
import { IReportState } from '../store/state/report.state';
import { GetProductData } from '../model/report.interface';
import * as qs from 'qs';
import { timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportService {
    constructor(private readonly http: HttpClient, private readonly keycloakService: KeycloakService, private readonly store: Store<IApplicationState>) { }

    public subject$: WebSocketSubject<any>;
    private readonly apiUsersEndpoint: string = environment.apiUsersEndpoint;

    public init(): void {
        // this.wsConnect();
    }

    private wsConnect(): void {
        // tslint:disable-next-line: no-this-assignment
        const self: ReportService = this;
        this.subject$ = webSocket({
            url: environment.wsUrl,
            deserializer: (msg: any): void => msg,
            openObserver: {
                next: (): void => {
                    console.log('ws connected succesfully.');
                }
            },
            closeObserver: {
                next(closeEvent: CloseEvent): void {
                    console.log(`ws closed, code: ${closeEvent.code}, reason: ${closeEvent.reason} was clean: ${closeEvent.wasClean}`);
                    setTimeout(() => {
                        self.wsConnect(), environment.wsRecconectTimeout;
                    });
                }
            }
        });

        this.subject$.subscribe(
            async (message: any) => {
                console.log(message.data);
                await self.handleIncomingMessages(message);
            },
            (error: any) => {
                console.error(`web socket error: ${JSON.stringify(error, ['message', 'arguments', 'type', 'name'])}`);
            },
            () => {
                console.warn('Completed!');
            }
        );
    }
    private async handleIncomingMessages(message: any): Promise<void> {
        const incomingMessage: any = JSON.parse(message.data);
        switch (incomingMessage.type) {
            case ReportActionsEnum.USER_EXCEEDED_MONTHLY_THRESHOLD:
                // clean store
                // display popup
                // alert('Maximum Thresholds for the month have reached. Please upgrade your membership.');
                // document.location.href = '/';

                this.store.dispatch(new GetUserExceededMonthlyThresholdAction());
                break;
            case ReportActionsEnum.GET_REPORT_FINISH_SUCCESSFULLY:
                this.store.dispatch(new GetReportByAsinSuccessAction(incomingMessage.report));
                break;
            case ReportActionsEnum.GET_REPORT_FINISH_SUCCESSFULLY_PUBLIC:
                this.store.dispatch(new GetReportByAsinSuccessAction(incomingMessage.report));
                // please display here a pupup? that needs to be loggedin
                break;
            case ReportActionsEnum.REPORT_IN_PROGRESS_RETURN_PARTIAL:
                this.store.dispatch(new ReportIsInProgressPartialReportReturnedAction(incomingMessage.report));
                break;
            case ReportActionsEnum.GET_REPORT_FINISH_CORRUPTED:
                this.store.dispatch(new GetReportByAsinFailureAction(incomingMessage.errorMessage, incomingMessage.report.reportAsin));
                break;
            default:
                break;
        }
    }

    public getUserReports(userId: string): Observable<any> {
        const url: string = `${this.apiUsersEndpoint.replace('{user_id}', userId)}/userReports.json`;
        return this.http.get<any[]>(url);
    }

    public generateReport(name: string): Observable<IReportState> {
        throw new Error('Method not implemented.');
    }
    async sendGetReportMessage(asin: string): Promise<void> {
        const messageIdentifier: IMessageIdentifier = await this.createPayloadWithUserCredentials();
        const message: SendMessageGetReportByAsin = new SendMessageGetReportByAsin(messageIdentifier, asin);
        // this.subject$.next(message);
    }

    private async createPayloadWithUserCredentials(): Promise<IMessageIdentifier> {
        const messageIdentifier: IMessageIdentifier = {} as IMessageIdentifier;
        if (await this.keycloakService.isLoggedIn()) {
            messageIdentifier.sender = await this.keycloakService.getUsername();
            messageIdentifier.token = await this.keycloakService.getToken();
        } else {
            // think about what to do here.
        }
        return messageIdentifier;
    }

    public async getProductData(asin: string): Promise<GetProductData> {
        const url: string = `${environment.productDataUrl}?${qs.stringify({ asin })}`;
        try {
            return await this.http
                .get<GetProductData>(url)
                .pipe(timeout(environment.timeout))
                .toPromise();
        } catch (error) {
            console.error(error);
        }
        return null;
    }
}
