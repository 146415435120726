import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { months } from '../model/strings';

@Directive({
    selector: '[appShowTooltip]'
})
export class ShowTooltipDirective implements OnInit {
    @Input() tooltipText: string;
    public tooltipTextArr: string[];
    public monthlyRevunue: string;
    private getMothFromArr: number = 1;
    public year: string;
    public month: string;
    private months: string[] = months;
    // public lastBar: boolean;

    constructor(private el: ElementRef) { }

    @HostListener('mouseenter', ['$event']) onMouseover($event: MouseEvent): void {
        if ($event.type === 'mouseenter') {
            this.el.nativeElement.innerHTML = `
            <div class="tooltip-on-bar" *ngIf="this.monthlyRevunue">
                <span><strong>${this.monthlyRevunue} Sales</strong> - ${this.month} ${this.year}</span>
                ${(<Element>event.target).className.indexOf('forcast-bar') > 0 ? '<span class="forcast-bar">forcast of next month</span>' : ''}
                ${(<Element>event.target).className.indexOf('current-bar') > 0 ? '<span class="current-bar">Current month</span>' : ''}
            </div>`;
        }
    }
    @HostListener('mouseleave', ['$event']) onMouseleave(): void {
        this.el.nativeElement.removeChild(document.querySelector('.tooltip-on-bar'));
    }

    ngOnInit(): void {
        if (this.tooltipText.indexOf(',') >= 0) {
            this.tooltipTextArr = this.tooltipText.split(',');
            this.monthlyRevunue = this.tooltipTextArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.year = this.tooltipTextArr[1]
                .split('/')
                .slice(2)
                .join();

            this.month = this.tooltipTextArr[this.getMothFromArr]
                .split('/')
                .slice(1, 2)
                .join();
            this.month = this.months[+this.month - this.getMothFromArr];
        }
    }
}
