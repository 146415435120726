import { trigger, transition, state, animate, animation, style, keyframes, useAnimation } from '@angular/animations';

export let inputInAnimation: any = trigger('inputInAnimation', [
    // 'From' Style
    state('collapsed', style({ top: '-30px', fontSize: '.8rem' })),
    state('extanded', style({ top: '-10px', fontSize: '1rem' })),

    transition('collapsed=>extanded', [animate('300ms ease-out')]),
    transition('extanded=>collapsed', [animate('300ms ease-in')])
]);
export let inputOutAnimation: any = animation([style({ top: '-10px', fontSize: '1rem' }), animate('{{ duration }} {{ easing }}')], {
    params: {
        duration: '.2s',
        easing: 'ease-out'
    }
});

// tslint:disable-next-line: typedef
// export let inputIn = trigger('inputIn', [transition(':enter', useAnimation(inputInAnimation))]);
export let inputOut: any = trigger('inputOut', [transition(':leave', useAnimation(inputOutAnimation))]);
