import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'variationTypes'
})
export class VariationTypesPipe implements PipeTransform {
    transform(value: string): string {
        const reg: RegExp = /_(.*?)_/;
        const variationType: string = reg.exec(value) ? reg.exec(value)[1] : '';
        return variationType;
    }
}
