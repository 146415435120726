import { Pipe, PipeTransform } from '@angular/core';
import { months } from '../model/strings';

@Pipe({
    name: 'getMonth'
})
export class GetMonthPipe implements PipeTransform {
    private getMothFromArr: number = 1;
    public months: string[] = months;
    transform(value: string): string {
        const month: number = parseInt(value.split('/')[this.getMothFromArr], 10);
        return this.months[month - this.getMothFromArr].slice(0, 3).toUpperCase();
    }
}
