import { Component, OnInit } from '@angular/core';
import { fade } from '../../../app/animations/fade';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-plugin-small-banner',
  templateUrl: './plugin-small-banner.component.html',
  styleUrls: ['./plugin-small-banner.component.scss'],
  animations: [fade]
})
export class PluginSmallBannerComponent implements OnInit {

  public ngOnInit(): void {

  }

  public getExtentionFromChromeWebstore(): void {
    window.open(
      environment.chromeExtentionURL,
      '_blank'
    )
  }

}
