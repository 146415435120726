import { Component, OnInit } from '@angular/core';
import KeywordResearchTaskResultsDto from '../../dtos/keywordResearchTaskResultsDto';
import PaginateResponse from '../../dtos/paginateResponse';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import { PollingService } from '../../services/polling.service';
import { select, Store } from '@ngrx/store';
import { KeywordAnalysisState } from '../../store/state/keywordAnalysis.state';
import { Subscription } from 'rxjs';
import { bestKewordSelector, keywordsAnalysisSelector, mainWordAndTopProductsAnalysisSelector } from '../../store/selectors/keywords.selector';
import GetKeywordResponse from '../../dtos/GetKeywordResponse';
import GetKeywordsFilter from '../../dtos/GetKeywordsFilter';
import GetKeywordHandler from '../../services/handler/GetKeywordHandler';
import { ToastrService } from '../../services/toastr.service';
import * as CONSTANTS_CLASS from '../../utils/constants';
import { HttpClient } from '@angular/common/http';
import TopProductResponse from '../../../app/dtos/TopProductResponse';
import { fadeOut, fade } from '../../animations/fade';
import GetAmpScoreFilter from '../../dtos/GetAmpScoreFilter';
import GetBestKeywordResponse from '../../../app/dtos/GetBestKeywordResponse';
import { NotificationsService } from '../../services/notifications.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { ActivatedRoute } from '@angular/router';
import PageType from '../../enums/PageType';
import { TaskType } from '../../../app/enums/TaskType';

@Component({
  selector: 'app-keywords-analysis',
  templateUrl: './keywords-analysis.component.html',
  styleUrls: ['./keywords-analysis.component.scss'],
  animations: [fade, fadeOut]
})
export class KeywordsAnalysisComponent implements OnInit {

  public searchText: string;
  public getKeywordsPaginateResponse: PaginateResponse;
  public keywordResearchTaskResultsDtos: KeywordResearchTaskResultsDto[] = [];

  public keywords$: Subscription;
  public bestkeyword$: Subscription;
  public getKeywordsFilter: GetKeywordsFilter = new GetKeywordsFilter();
  public CONSTANTS_CLASS = CONSTANTS_CLASS;
  //---Filter show hide variables---//
  public openSearchVolume: boolean = false;
  public openFinalScore: boolean = false;
  public openWordCount: boolean = false;
  public openCompetingProducts: boolean = false;
  public openByPhrase: boolean = false;
  public openExcludePhrase: boolean = false;
  public showKeywordsThatContain: string;
  public excludeKeywordsThatContain: string;
  //---END Filter show hide variables---//

  // yairs work
  public IsInitialed: boolean = true;
  public topProducts$: Subscription;
  public topProducts: TopProductResponse[] = []
  public taskId: number;
  public inputSearchValue: string;
  public choosenProduct: number = 0;

  public mainWordData: GetBestKeywordResponse;

  // end yairs work
  public notificationToast: string;
  public isVolumeAsc: boolean = true;
  //--For sorting functionality--// 
  public isDesc: boolean = true;
  public column: string = 'keyword';
  public direction: number = 1;
  //END--For sorting functionality--//
  isShowGetAmpScoreButton: boolean = false;
  paramIsThere: boolean = false;

  public filterEl: HTMLElement;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, private toastrService: ToastrService, private marketPlaceService: MarketPlaceService, public pollingService: PollingService,
    private store: Store<KeywordAnalysisState>, private notificationService: NotificationsService, public progressBarService: ProgressBarService) { }

  public getPhraseDataInput(emittedData: string[]): void {
    if (emittedData[1] === 'includePhrase')
      this.showKeywordsThatContain = emittedData[0];

    if (emittedData[1] === 'excludePhrase')
      this.excludeKeywordsThatContain = emittedData[0];
  }


  ngOnInit() {
    this.searchText = this.marketPlaceService.mainSearchWords ? this.marketPlaceService.mainSearchWords[0] : null;
    if (this.searchText) {
      this.IsInitialed = false;
    }

    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchText = this.route.snapshot.queryParamMap.get('search')
      this.getKeywordsFilter.taskId = Number(this.route.snapshot.queryParamMap.get('taskId'))
      this.progressBarService.setProgressBar();
      this.callGetKeywords(1);
    }

    this.keywords$ = this.store.pipe(select(keywordsAnalysisSelector)).subscribe((data: GetKeywordResponse) => {
      this.progressBarService.removeProgressBar();
      if (data) {
        this.getKeywordsPaginateResponse = data.paginateResponse;
        this.keywordResearchTaskResultsDtos = data.keywordResearchTaskResultsDtos;
      }
    }, (error) => {
      console.log("error: " + error);
      this.progressBarService.removeProgressBar();
    });

    this.bestkeyword$ = this.store.pipe(select(bestKewordSelector)).subscribe((data: GetBestKeywordResponse) => {
      if (data) {
        this.mainWordData = data;
      }
    })

    this.topProducts$ = this.store.pipe(select(mainWordAndTopProductsAnalysisSelector)).subscribe((data: TopProductResponse[]) => {
      if (data) {
        this.topProducts = data.slice(0, 5);
      }
    })
  }

  public generatePhrase(searchText: string): void {
    if (searchText) {
      this.IsInitialed = false;
      this.progressBarService.setProgressBar();
      this.marketPlaceService.generatePhrase(searchText).subscribe(
        (response) => {
          this.inputSearchValue = searchText;
          this.getKeywordsFilter.taskId = response.taskId;
          this.pollingService.getStatus(response.taskId, TaskType.KEYWORDS_FOR_PHRASES);
          this.taskId = response.taskId;
          this.notificationToast = TaskType.KEYWORDS_FOR_PHRASES;
          this.pollingService.setLatestWordMapperTaskId(response.taskId);
        }, err => {
          console.log(err);
          console.log('YAIR for you to handle error')
        })
    } else {
      this.toastrService.showError("Please enter search keyword then search");
    }
  }

  public productSlider(sideDirection: string): void {
    const topProductsLengthArr: number = this.topProducts.length - 1;

    if (sideDirection === 'right') {
      if (this.choosenProduct < topProductsLengthArr) {
        this.choosenProduct = this.choosenProduct + 1;
      } else {
        this.choosenProduct = 0;
      }
    } else {
      if (this.choosenProduct > 0) {
        this.choosenProduct = this.choosenProduct - 1;
      } else {
        this.choosenProduct = topProductsLengthArr;
      }
    }
  }

  public ngOnDestroy(): void {
    this.keywords$.unsubscribe();
  }


  //----Filters----//
  public toggleSearchVolume() {

    this.openSearchVolume = !this.openSearchVolume;
  }

  public toggleFinalScore(filterEl: HTMLElement) {
    this.filterEl = filterEl;
    this.openFinalScore = !this.openFinalScore;
  }

  public toggleWordCount(filterEl: HTMLElement) {
    this.filterEl = filterEl;
    this.openWordCount = !this.openWordCount;
  }

  public toggleCompetingProducts(filterEl: HTMLElement) {
    this.filterEl = filterEl;
    this.openCompetingProducts = !this.openCompetingProducts;
  }

  public toggleByPhrase(filterEl: HTMLElement) {
    this.filterEl = filterEl;
    this.openByPhrase = !this.openByPhrase;
  }

  public toggleExcludePhrase(filterEl: HTMLElement) {
    this.filterEl = filterEl;
    this.openExcludePhrase = !this.openExcludePhrase;
  }

  public clearFilter() {

    this.getKeywordsFilter.clear(this);
    this.callGetKeywords(1);
  }

  public applyFilter() {

    //Check is all filters have valid values
    let errorMessage = this.getKeywordsFilter.checkIsValid(this);
    if (errorMessage.length > 0) {
      this.toastrService.showError(errorMessage);
      return;
    }
    this.progressBarService.setProgressBar();
    this.callGetKeywords(1);

  }
  //----END Filters----//

  public callGetKeywords(pageNumber: number) {

    this.getKeywordsFilter.page = pageNumber;
    new GetKeywordHandler(PageType.WordMapper, this.marketPlaceService, this.store, this.getKeywordsFilter, this.httpClient).process();
  }

  public sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  };

  public sortByVolume() {

    this.isVolumeAsc = !this.isVolumeAsc;
    this.isDesc = this.isVolumeAsc;
    this.column = 'volume';
    this.direction = this.isDesc ? 1 : -1;
    this.getKeywordsFilter.sortVolume(this.isVolumeAsc);
    this.progressBarService.setProgressBar();
    this.callGetKeywords(1);
  }

  public onCheckKeyword() {

    for (let keywordResearchTaskResultsDto of this.keywordResearchTaskResultsDtos) {
      if (keywordResearchTaskResultsDto.isChecked) {
        this.isShowGetAmpScoreButton = true;
        return;
      }
    }

    this.isShowGetAmpScoreButton = false;
  }

  public getAmpScore() {

    this.progressBarService.setProgressBar();
    let getAmpScoreFilter: GetAmpScoreFilter = new GetAmpScoreFilter();
    getAmpScoreFilter.keywords = [];
    getAmpScoreFilter.taskId = this.getKeywordsFilter.taskId;
    for (let keywordResearchTaskResultsDto of this.keywordResearchTaskResultsDtos) {
      if (keywordResearchTaskResultsDto.isChecked) {
        getAmpScoreFilter.keywords.push(keywordResearchTaskResultsDto.keyword);
      }
    }

    this.pollingService.callGetAmpScore(getAmpScoreFilter, this.getKeywordsPaginateResponse, this.keywordResearchTaskResultsDtos)
  }
}
