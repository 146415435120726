import { inputInAnimation } from './../../../animations/inputAnimation';
import { fade } from './../../../animations/fade';
import { Component, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
    selector: 'pop-search-again',
    templateUrl: './pop-search-again.component.html',
    styleUrls: ['./pop-search-again.component.scss'],
    animations: [fade, inputInAnimation]
})
export class PopSearchAgainComponent {
    public watingPopDelay: boolean = false;
    public playAnimation: boolean = false;
    model: any = {};

    @Output() closePopNoData: EventEmitter<boolean> = new EventEmitter<boolean>();

    public closePopupWaiting(): void {
        this.closePopNoData.emit(false);
    }
    onSubmit(): void {
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model));
    }
}
