import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import PaginateResponse from '../../dtos/paginateResponse';
import AsyncResponse from '../../dtos/AsyncResponse';
import { StatusCodes } from 'http-status-codes';
import { isEmpty } from 'lodash';
import { ThemeService } from '../../../app/services/theme.service';
import { Theme } from '../../../assets/mocks/themes';

@Component({
    selector: 'app-mentors',
    templateUrl: './mentors.component.html',
    styleUrls: ['./mentors.component.scss']
})
export class MentorsComponent implements OnInit, AfterViewInit {
    private static readonly BG_PROPERTY: string = '--bg-header-report';
    private static readonly BG_DEFAULT: string = '#222';

    public paginationPage: number = 1;
    public paginationLimit: number = 10;
    public mentorsPaginateResponse: PaginateResponse;
    public searchText: string;
    public closePopTutorial: boolean = false;
    private videoLink: string = '';
    public isSearchedMentors: boolean = false;

    public showPrefix: boolean;
    public prefixList: string[] = ['Date', 'Reviews', 'Price'];
    public orderBy: string;
    constructor(private marketPlaceService: MarketPlaceService, private themeService: ThemeService) { }

    public ngOnInit(): void {
        this.getMentors(this.paginationPage, this.paginationLimit, this.searchText);
    }
    ngAfterViewInit(): void {
        this.cloundsMoves();
    }

    public getMentors(paginationPage: number, paginationLimit: number, searchText: string): void {

        this.marketPlaceService.getMentors(paginationPage, paginationLimit, searchText).subscribe(
            async (response: AsyncResponse) => {
                // response.httpStatus: need to handle : StatusCodes.OK, StatusCodes.NO_CONTENT, StatusCodes.INTERNAL_SERVER_ERROR
                // response.errorKey: for now please ignore
                // else this is the actual pagination data
                if (response.httpStatus === StatusCodes.NO_CONTENT) {
                } else {
                    await this.getBrandBackground(response.data);
                    if (searchText && searchText.length > 1) this.isSearchedMentors = true;
                }
            },
            (error: any) => {
            }
        );
    }

    private async getBrandBackground(mentorsPaginateResponse: PaginateResponse): Promise<void> {
        if (!isEmpty(mentorsPaginateResponse)) {
            const getThemeTasks: Promise<Theme>[] = mentorsPaginateResponse.data.map((mentor: any) => this.themeService.getBrandStyle(mentor.name));
            const themes: Theme[] = await Promise.all(getThemeTasks);
            const themesMap: Map<string, Theme> = new Map<string, Theme>();
            themes.filter(theme => theme).forEach(theme => themesMap.set(theme.name, theme));
            mentorsPaginateResponse.data.forEach((mentor: any) => {
                const theme = themesMap.get(mentor.name);
                mentor.brandBackground = MentorsComponent.BG_DEFAULT;
                if (theme && theme.properties) {
                    const brandBackground = theme.properties[MentorsComponent.BG_PROPERTY];
                    mentor.brandBackground = brandBackground ? brandBackground : MentorsComponent.BG_DEFAULT;
                }
            });
            this.mentorsPaginateResponse = mentorsPaginateResponse;
        }
    }

    public pageChangedEvent(pageNumber: number): void {
        this.paginationPage = pageNumber;
        this.getMentors(this.paginationPage, this.paginationLimit, this.searchText);
    }

    public search(): void {
        this.getMentors(this.paginationPage, this.paginationLimit, this.searchText);
    }

    public resetSearch(): void {
        this.getMentors(this.paginationPage, this.paginationLimit, '');
        this.isSearchedMentors = false;
        this.searchText = '';
    }
    public openPopTutBySection(value: string): void {
        this.closePopTutorial = !this.closePopTutorial;
        this.videoLink = value;
    }

    public closePopTutorialFun(value: boolean): void {
        this.closePopTutorial = value;
    }
    public cloundsMoves(): void {
        const allElements: any = document.querySelectorAll('.elementsAnim');

        allElements.forEach((element: any) => {
            element.style.animation = `elementsMoves ${Math.random() * (30 - 20) + 20}s ${Math.random() * 3}s ease-in-out infinite`;
        });
    }
    displayPrefixlist(): void {
        this.showPrefix = !this.showPrefix;
    }
    choosenPrefix(choosen: string): void {
        this.showPrefix = false;
        this.orderBy = choosen;
        // this.jobRegistrationForm.get('phoneForm').get('prefix').setValue(choosen);
    }
}
