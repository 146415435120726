import { Component, Input } from '@angular/core';

@Component({
    selector: 'call-to-action-section',
    templateUrl: './call-to-action-section.component.html',
    styleUrls: ['./call-to-action-section.component.scss']
})
export class CallToActionSectionComponent {
    @Input() isFormDisplay = false;
    @Input() title: string;
}
