import { Injectable } from '@angular/core';
import { IMessageIdentifier, MessageCheckout } from './events/events';
import { AuthServiceService } from './services/auth-service.service';
import { ReportService } from './services/report.service';

@Injectable({
    providedIn: 'root'
})
export class CheckoutServiceService {
    constructor(private readonly authServiceService: AuthServiceService, private readonly reportService: ReportService) {}

    public async sendCheckoutMessageToServer(checkoutPayload: any): Promise<void> {
        const messageIdentifier: IMessageIdentifier = await this.authServiceService.createPayloadWithUserCredentials();
        const message: MessageCheckout = new MessageCheckout(messageIdentifier, checkoutPayload);
        this.reportService.subject$.next(message);
    }
}
