import { Action } from '@ngrx/store';
import { IReportDTO, GetProductData } from './../../model/report.interface';

export enum ReportActionsEnum {
    GET_REPORT_BY_ASIN = '[Report] Get Report By Asin',
    GET_REPORT_FINISH_SUCCESSFULLY = '[Report] Get Report By Asin Finish Successfully',
    GET_REPORT_FINISH_SUCCESSFULLY_PUBLIC = '[Report] Get Report By Asin Finish Successfully Public',
    REPORT_IN_PROGRESS_RETURN_PARTIAL = '[Report] Report In Progress Return Partial',
    GET_REPORT_BY_ASIN_FAILURE = '[Report] Get Report By Asin Failure',
    GET_REPORT_FINISH_CORRUPTED = '[Report] Get Report By Asin Finish Corrupted',
    ACTION_IN_PROGRESS = 'ACTION_IN_PROGRESS',
    GET_PRODUCT_DATA_BY_ASIN_FINISHED_SUCCESSFULLY = '[Product] Get Product Data By Asin Finished Successfully',
    GET_PRODUCT_DATA_BY_ASIN_FAILURE = '[Product] Get Product Data By Asin Failure',
    CHECKOUT = '[Checkout] handle',
    UNEXPECTED_ERROR_ON_CHECKOUT_CALL_SUPPORT = '[Checkout] Unexpected Error On Checkout Call Suppor',
    CHECKOUT_INVALID_TOKEN = '[Checkout] Checkout Invalid Token',
    CHECKOUT_VALIDATION_AGAINST_PSP_FAILED = '[Checkout] Validation Against Psp Failed',
    CHECKOUT_VALIDATION_INDEX_AREADY_EXIST = '[Checkout] Validation Index Already Exist',
    CHECKOUT_COULD_NOT_ADD_ROLE = '[Checkout] Could Not Add Role',
    CHECKOUT_SUCCESS = '[Checkout] Success',
    USER_EXCEEDED_MONTHLY_THRESHOLD = '[Report] User Exceeded Monthly Threshold',
    GET_NICHE_BY_FILTERS = '[NicheFinder] Get Niche Finder Requests'
}
// tslint:disable: typedef
export class GetNicheByFiltersAction implements Action {
    public readonly type = ReportActionsEnum.GET_NICHE_BY_FILTERS;
    constructor(public readonly response: any) {}
}

// tslint:disable: typedef
export class GetUserExceededMonthlyThresholdAction implements Action {
    public readonly type = ReportActionsEnum.USER_EXCEEDED_MONTHLY_THRESHOLD;
}

// tslint:disable: typedef
export class GetProductDataByAsinFailureAction implements Action {
    public readonly type = ReportActionsEnum.GET_PRODUCT_DATA_BY_ASIN_FAILURE;
    constructor(public readonly errorMessage: string, public readonly asin: string) {}
}
export class GetProductDataByAsinSuccessAction implements Action {
    public readonly type = ReportActionsEnum.GET_PRODUCT_DATA_BY_ASIN_FINISHED_SUCCESSFULLY;
    constructor(public readonly product: GetProductData) {}
}

export class GetReportByAsinAction implements Action {
    public readonly type = ReportActionsEnum.GET_REPORT_BY_ASIN;
    constructor(public readonly asin: string) {}
}

export class GetReportByAsinSuccessAction implements Action {
    public readonly type = ReportActionsEnum.GET_REPORT_FINISH_SUCCESSFULLY;
    constructor(public readonly report: IReportDTO) {}
}

export class ReportIsInProgressPartialReportReturnedAction implements Action {
    public readonly type = ReportActionsEnum.REPORT_IN_PROGRESS_RETURN_PARTIAL;
    constructor(public readonly report: IReportDTO) {}
}

export class GetReportByAsinFailureAction implements Action {
    public readonly type = ReportActionsEnum.GET_REPORT_BY_ASIN_FAILURE;
    constructor(public readonly errorMessage: string, public readonly asin: string) {}
}

export class ActionInProgress implements Action {
    public readonly type = ReportActionsEnum.ACTION_IN_PROGRESS;
}

export type ReportActions =
    | ReportIsInProgressPartialReportReturnedAction
    | GetReportByAsinFailureAction
    | GetReportByAsinSuccessAction
    | GetReportByAsinAction
    | ActionInProgress
    | GetProductDataByAsinSuccessAction
    | GetProductDataByAsinFailureAction
    | GetUserExceededMonthlyThresholdAction
    | GetNicheByFiltersAction;
