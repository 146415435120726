import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-coupon-code',
    templateUrl: './coupon-code.component.html',
    styleUrls: ['./coupon-code.component.scss']
})
export class CouponCodeComponent implements OnInit {
    public couponCode: string;
    @Output() getDisccount: EventEmitter<string> = new EventEmitter<string>();
    constructor() {}

    ngOnInit(): void {}

    getDiscountFun(): void {
        this.getDisccount.emit(this.couponCode);
    }
}
