import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable, HostListener } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HandlePopupsService {
    public ctaMemberPopupSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public ctaFromSearchPopupSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

    public lazyLoadPopupSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public exendedPopupSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

    public createCampaignPopupSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);

    public editCampaignPopupSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public editCampaignPopup$: Observable<boolean> = this.editCampaignPopupSubject.asObservable();

    setCtaMemberPopupStatus(value: boolean): void {
        this.ctaMemberPopupSubject.next(value);
    }
    setCtaFromSearchPopupStatus(value: boolean): void {
        this.ctaFromSearchPopupSubject.next(value);
    }

    setLazyLoadPopupStatus(value: boolean): void {
        this.lazyLoadPopupSubject.next(value);
    }
    setExendedPopup(value: boolean): void {
        this.exendedPopupSubject.next(value);
    }

    setCreateCampaignPopupStatus(value: boolean): void {
        this.createCampaignPopupSubject.next(value);
    }
    setEditCampaignPopupStatus(value: boolean): void {
        this.editCampaignPopupSubject.next(value);
    }
}
