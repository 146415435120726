import { fadeOut, fade } from './../../animations/fade';
import { GetSearchedAsinService } from './../../services/get-searched-asin.service';
import { IListingOptimization, RelatedProductsServiceService, IAllProductDetails } from './../../services/related-products-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ListingResponse } from '../../../app/model/report.interface';

@Component({
    selector: 'app-listing-table',
    templateUrl: './listing-table.component.html',
    styleUrls: ['./listing-table.component.scss'],
    animations: [fade, fadeOut]
})
export class ListingTableComponent implements OnInit, OnDestroy {
    public listingOptimization$: Observable<ListingResponse>;
    private listingOptimizationSub$: Subscription;
    public listingRows: { current: string; optimal: string; isTrue: boolean }[];
    public currentAsin: string;
    public productDetails$: Observable<IAllProductDetails>;

    constructor(private translate: TranslateService, private ProductInformation: RelatedProductsServiceService, private searchedAsin: GetSearchedAsinService) {}

    ngOnInit(): void {
        this.productDetails$ = this.ProductInformation.detailsProductSubject;
        this.listingOptimization$ = this.ProductInformation.listingOptimizationsDataSubject;
        setTimeout(() => {
            {
                this.currentAsin = this.searchedAsin.getSearchedAsin();
            }
        });

        this.listingOptimizationSub$ = this.listingOptimization$.subscribe((data: ListingResponse) => {
            if (data) {
                this.listingRows = data.parameters;
            }
        });
    }

    ngOnDestroy(): void {
        this.listingOptimizationSub$.unsubscribe();
    }
}
