import { Component } from '@angular/core';
import { ILogosPartners } from '../../model/ui-interfaces';

@Component({
    selector: 'logos-section',
    templateUrl: './logos-section.component.html',
    styleUrls: ['./logos-section.component.scss']
})
export class LogosSectionComponent {
    logos: ILogosPartners[] = [{ image: 'bloooom-logo-black.svg', name: 'BuyBox' }];
}
