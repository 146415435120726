import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ThemeService } from '../../../services/theme.service';
import { Theme } from '../../../../assets/mocks/themes';
import { environment } from '../../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { AuthServiceService } from '../../../services/auth-service.service';
import { flyInOut } from '../../../../app/animations/menuOpenCloseAnimation';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  animations: [flyInOut],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {

  public headerLogoOfTheme: string = './assets/images/bloooom-logo.svg';
  public toggleMenuOfConfigurations: boolean;
  public getActiveTheme$: Observable<Theme>;
  protected getActiveThemeSub: Subscription;
  public roles: string[];
  public isAllowedForProductReport: boolean = false;
  public isAllowedForProductResearch: boolean = false;
  public isAllowedForProductLauncher: boolean = false;

  constructor(private themeService: ThemeService,
    private authServiceService: AuthServiceService,
    private el: ElementRef) { }


  @HostListener('click', ['$event']) handleClick(event: Event): void {
    const menu: any = this.el.nativeElement.querySelector('.methods-menu');
    const menuBtn: any = this.el.nativeElement.querySelector('.switch-configurations-btn');
    if (menu !== event.target && menuBtn !== event.target) {
      this.toggleMenuOfConfigurations = false;
    }
  }

  public ngOnInit(): void {
    this.roles = this.authServiceService.getRoles();

    if (this.roles.includes("PAID_LICENSE")) {
      this.isAllowedForProductResearch = true;
    }
    if (this.roles.includes("PRODUCT_LAUNCHER")) {
      this.isAllowedForProductLauncher = true;
    }
    if (this.roles.includes("PUBLIC") || this.roles.includes("PAID_LICENSE")) {
      this.isAllowedForProductReport = true;
    }
    this.getActiveTheme$ = this.themeService.getActiveTheme$;
    this.getActiveThemeSub = this.getActiveTheme$.subscribe((res: Theme) => {
      if (res) {
        this.headerLogoOfTheme = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/logo.svg';
      }
    });
  }
  public toggleConfigurationsSwitcher(): void {
    this.toggleMenuOfConfigurations = !this.toggleMenuOfConfigurations;
  }

  public ngOnDestroy() {
    this.getActiveThemeSub.unsubscribe();
  }

}
