import { AlibabaProductsDTO, AlibabaCategories } from './../../model/alibaba';
import { Observable, Subscription } from 'rxjs';
import { RelatedProductsServiceService, IMarketData } from './../../services/related-products-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fadeOut, fade } from '../../../app/animations/fade';
import { ProductsPriceRange } from '../../../app/model/report.interface';
import { Store, select } from '@ngrx/store';
import { IReportState } from '../../../app/store/state/report.state';
import { marketNamesSelector } from '../../../app/store/selectors/status.selector';

@Component({
    selector: 'app-alibaba',
    templateUrl: './alibaba.component.html',
    styleUrls: ['./alibaba.component.scss'],
    animations: [fade, fadeOut]
})
export class AlibabaComponent implements OnInit, OnDestroy {
    private marketNameSub: Subscription;
    public allAlibabaData$: Observable<any>;
    public allAlibabaDataSub: Subscription;

    public alibabaProductDetails: any[] = [];

    public alibabaCategories: AlibabaCategories[] = [];

    public productPriceRange: ProductsPriceRange = { max: 0, min: 0 };
    public productPriceAvg: number = 0;
    public relevantSuppliers: number = 0;
    public marketName: string = '';

    constructor(private AllAlibabaData: RelatedProductsServiceService, private store: Store<IReportState>) {
        this.allAlibabaData$ = this.AllAlibabaData.allAlibabaDataSubject;
        this.allAlibabaDataSub = this.AllAlibabaData.allAlibabaDataSubject.subscribe((data: AlibabaProductsDTO) => {
            if (data) {
                this.alibabaCategories = data.categories;
                this.alibabaProductDetails = data.productDetails;
                this.productPriceRange = data.productPriceRange;
                this.productPriceAvg = data.productPriceAvg;
                this.relevantSuppliers = data.relevantSuppliers;
            }
        });

        this.marketNameSub = this.store.pipe(select(marketNamesSelector)).subscribe((data: string) => {
            if (data) {
                this.marketName = data;
            }
        });
    }

    ngOnInit(): void {}
    ngOnDestroy(): void {
        this.allAlibabaDataSub.unsubscribe();
        this.marketNameSub.unsubscribe();
    }
}
