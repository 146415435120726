import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import AsyncResponse from '../dtos/AsyncResponse';
import { TaskType } from '../enums/TaskType';
import { ICampaign } from '../model/google/campaignForGoogle';
import { CampaignStatusInEngine, NewGoogleCampaignRequest } from '../model/google/newGoogleCampaignRequest';
import PublishGoogleCampaignRequest from '../model/google/PublishGoogleCampaignRequest';
import { PollingService } from './polling.service';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  private newCampaignRequest: NewGoogleCampaignRequest;

  constructor(private pollingService: PollingService, private httpClient: HttpClient) { }

  public createCampaign(newGoogleCampaignRequest: NewGoogleCampaignRequest): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = `${environment.createCampignURL}`;
    return this.httpClient.post(url, newGoogleCampaignRequest, { headers: headers }).pipe(timeout(environment.timeout));
  }

  public setCreateCampaign(newGoogleCampaignRequest: NewGoogleCampaignRequest): void {
    this.newCampaignRequest = newGoogleCampaignRequest;
  }

  public getCreateCampaign(): NewGoogleCampaignRequest {
    return this.newCampaignRequest;
  }

  public getCampaigns(campaignName: string = ''): Observable<any> {
    const url = `${environment.createCampignURL}?${qs.stringify({ campaignName, excludeStatuses: [CampaignStatusInEngine.Deleted] })}`;
    return this.httpClient.get(url).pipe(timeout(environment.timeout));
  }

  public uploadCampaigns(campaigId: number): void {
    if (campaigId) {
      this.httpClient.post(environment.uploadCampignURL, new PublishGoogleCampaignRequest(campaigId)).pipe(timeout(environment.timeout)).subscribe((response: AsyncResponse) => {
        if (response.errorKey) {
          console.log('upload campaign err on ui', response.errorKey)
        }
        this.pollingService.getStatus(response.taskId, TaskType.UPLOAD_CAMPAIGN);
      });
    }
  }


  public checkCampaginFinished(campaignData: ICampaign): boolean {
    const totalDays: number = Math.floor((new Date(campaignData.endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    if (totalDays < 0) {
      return true;
    } else {
      return false;
    }
  };
}

