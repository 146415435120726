import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterAction } from '@ngrx/router-store';
import { TaskType } from '../../../app/enums/TaskType';
import { CampaignService } from '../../../app/services/campaign.service';
import { PollingService } from '../../../app/services/polling.service';

@Component({
    selector: 'app-purchase-successful',
    templateUrl: './purchase-successful.component.html',
    styleUrls: ['./purchase-successful.component.scss']
})
export class PurchaseSuccessfulComponent implements OnInit {
    constructor(private router: ActivatedRoute, private campaignService: CampaignService, private pollingService: PollingService) { }

    ngOnInit(): void {
    }
}

