import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IReportState } from '../store/state/report.state';
import { Store, select } from '@ngrx/store';
import { marketAnalysisSelector } from '../store/selectors/market-analysis.selector';
import { IRelatedPoductsDTO, MarketAnalysisDto } from '../model/report.interface';
import { isEmpty } from 'lodash';
import { RelatedProductsSelector } from '../store/selectors/related-products.selector';
import { IMarketData, RelatedProductsServiceService } from './related-products-service.service';

export interface ICompetittorsHeader {
    numberOfCompetittors: number;
    priceData: { priceAvg: number; priceRange: { max: number; min: number } };
    salesData: { salesAvg: number; salesRange: { max: number; min: number } };
    revenueData: { revenueAvg: number; revenueRange: { max: number; min: number } };
    reviewsData: { reviewsAvg: number; reviewsRange: { max: number; min: number } };
    costsRange: number[];
    productProfit: number;
}

@Injectable({
    providedIn: 'root'
})
export class CompetittorsService {
    private compettitorsHeaderSubject$: BehaviorSubject<ICompetittorsHeader> = new BehaviorSubject<ICompetittorsHeader>(null);
    public compettitorsHeader$: Observable<ICompetittorsHeader> = this.compettitorsHeaderSubject$.asObservable();

    private productsDataSubject$: BehaviorSubject<IRelatedPoductsDTO[]> = new BehaviorSubject<IRelatedPoductsDTO[]>(null);
    public productsData$: Observable<IRelatedPoductsDTO[]> = this.productsDataSubject$.asObservable();

    constructor(private Store: Store<IReportState>, private marketData: RelatedProductsServiceService) { }

    getDataOfCompetittors(): void {
        this.Store.pipe(select(marketAnalysisSelector)).subscribe((marketAnalysisData: MarketAnalysisDto) => {
            if (marketAnalysisData) {
               
                const competittorsData: ICompetittorsHeader = {
                    numberOfCompetittors: marketAnalysisData.marketMetaData.numberOfCompatitors,
                    priceData: { priceAvg: marketAnalysisData.marketMetaData.productPriceAvg, priceRange: marketAnalysisData.marketMetaData.productPriceRange },
                    salesData: { salesAvg: marketAnalysisData.marketMetaData.salesVolumeAvg, salesRange: marketAnalysisData.marketMetaData.salesVolumeRange },
                    revenueData: {
                        revenueAvg: marketAnalysisData.marketMetaData.salesVolumeAvg * marketAnalysisData.marketMetaData.productPriceAvg,
                        revenueRange: {
                            max: marketAnalysisData.marketMetaData.salesVolumeRange.max * marketAnalysisData.marketMetaData.productPriceRange.max,
                            min: marketAnalysisData.marketMetaData.salesVolumeRange.min * marketAnalysisData.marketMetaData.productPriceRange.min
                        }
                    },
                    reviewsData: {
                        reviewsAvg: marketAnalysisData.marketMetaData.reviewAvg,
                        reviewsRange: { max: marketAnalysisData.marketMetaData.reviewRange.max, min: marketAnalysisData.marketMetaData.reviewRange.min }
                    },

                    costsRange: null,
                    productProfit: marketAnalysisData.marketMetaData.productProfit
                };
                if (this.marketData) {
                    this.marketData.marketDataSubject.subscribe((data: IMarketData) => {
                        if (data) {
                            competittorsData.costsRange = data.costRange;
                        }
                    });
                }
                this.compettitorsHeaderSubject$.next(competittorsData);
            }
        });
    }

    getDataOfProducts(): void {
        this.Store.pipe(select(RelatedProductsSelector)).subscribe((RelatedProducts: IRelatedPoductsDTO[]) => {
            this.productsDataSubject$.next(RelatedProducts);
        });
    }
    getDataOfSearchedProduct(): void { }
}
