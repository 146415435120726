import { createSelector } from '@ngrx/store';
import { IReportDTO, ListingResponse } from '../../../app/model/report.interface';
import { selectCampaignState } from '../reducers/campaign.reducers';
import { CampaignsState } from '../state/campaigns.state';

export const campaignSelector: any = createSelector(
    selectCampaignState,
    (state: CampaignsState): any => {
        if (state) {
            const allCampaigns: any = selectCampaignState;
            console.log(allCampaigns)
            if (allCampaigns) {
                return allCampaigns;
            }
        }
    }
);

campaignSelector.release();