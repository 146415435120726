import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Keycloak from 'keycloak-js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // tslint:disable-next-line: typedef
    .catch(err => console.error(err));

// let initOptions = {
//     url: 'https://127.0.0.1:8443/auth', realm: 'sample',  clientId:'sample-app'
// }

// let keycloak = Keycloak(initOptions);
// keycloak.init({ onLoad: "login-required" }).success((auth) => {
//    if (!auth) {
//       window.location.reload();
//    } else {
//      console.log("Authenticated");
//    }
//    platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err =>console.error(err));

//    localStorage.setItem("token", keycloak.token);
//    localStorage.setItem("refresh-token", keycloak.refreshToken);
//    setTimeout(() => {
//       keycloak.updateToken(60).success((refreshed) => {
//         if(refreshed) {
//           console.debug('Token refreshed' + refreshed);
//         }else{
//         }
//       }).error(() => {
//          console.error('Failed to refresh token');
//       });
//    }, 40000)
// }).error(() => {
//    console.error("Authenticated Failed");
// });
