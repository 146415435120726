import { trigger, transition, state, animate, animation, style, keyframes, useAnimation } from '@angular/animations';

// tslint:disable-next-line: typedef
export const fadeInAnimation = animation([style({ opacity: 0 }), animate('{{ duration }} {{ easing }}')], {
    params: {
        duration: '.2s',
        easing: 'ease-out'
    }
});

export const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
]);


// tslint:disable-next-line: typedef

export let fade = trigger('fade', [transition(':enter', useAnimation(fadeInAnimation)), transition(':leave', animate(200, style({ opacity: 0 })))]);
export let fadeOut: any = trigger('fadeOut', [leaveTrans]);
