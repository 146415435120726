import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IMarketData } from '../../../app/services/related-products-service.service';
import { fade, fadeOut } from '../../../app/animations/fade';
import { CompetittorsService } from '../../../app/services/competittors.service';
import { pluck } from 'rxjs/operators';
import { MarketMetaData } from '../../../app/model/report.interface';
@Component({
    selector: 'app-market-in-numbers',
    templateUrl: './market-in-numbers.component.html',
    styleUrls: ['./market-in-numbers.component.scss'],
    animations: [fade, fadeOut]
})
export class MarketInNumbersComponent implements OnInit {
    @Input() marketData$: Observable<IMarketData>;
    marketMetaData$: Observable<number>;

    constructor(private marketAnalisysService: CompetittorsService) {}

    ngOnInit(): void {
        this.marketMetaData$ = this.marketAnalisysService.compettitorsHeader$.pipe(pluck('productProfit'));
    }
}
