import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'dataToObservable'
})
export class DataToObservablePipe implements PipeTransform {

  private monthsString: string[] = ['JAN', 'FAB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  transform(rawData: any): Observable<any> {

    let fineData = rawData.map(item => {
      return { timestamp: item.year + ' ' + this.monthsString[item.month], searchVol: item.search_volume }
    });
    fineData = fineData.filter(item => item.timestamp.indexOf('undefined') < 0 && item.searchVol > 0);
    return of(fineData);
  }
}
