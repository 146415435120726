import { RelatedProductsServiceService, IMarketData } from './../../../services/related-products-service.service';
import { ProductValueOverTime, AggregatedDataOverTime } from './../../../model/report.interface';
import { fade, fadeOut } from './../../../animations/fade';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'market-chart',
    templateUrl: './market-chart.component.html',
    styleUrls: ['./market-chart.component.scss'],
    animations: [fade, fadeOut]
})
export class MarketChartComponent implements OnInit, OnDestroy {
    private chartBars$: Subscription;
    public marketData$: Observable<IMarketData>;
    public chartBars: AggregatedDataOverTime[] = [];
    public dataIsHere: boolean;
    public topBar: number;
    public numbersOfBars: number = 10;
    public displayTooltip: boolean = false;
    constructor(private marketData: RelatedProductsServiceService) {}
    ngOnInit(): void {
        this.marketData$ = this.marketData.marketDataSubject;
        this.getHistogramBSR();
    }
    handleTooltip(val: boolean): void {
        this.displayTooltip = val;
    }
    getHistogramBSR(): void {
        this.chartBars$ = this.marketData.marketDataSubject.subscribe((marketData: IMarketData) => {
            if (marketData) {
                if (marketData.productAvgVolumnOverTime) {
                    const barVolArray: number[] = marketData.productAvgVolumnOverTime.slice(-this.numbersOfBars).map((item: AggregatedDataOverTime) => item.avgSales);
                    this.chartBars = marketData.productAvgVolumnOverTime.slice(-this.numbersOfBars);
                    this.topBar = Math.max(...barVolArray);
                }
            } else {
                this.chartBars = [];
            }
        });
    }
    ngOnDestroy(): void {
        this.chartBars$.unsubscribe();
    }
}
