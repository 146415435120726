import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-product-and-optimizatoin',
    templateUrl: './product-and-optimizatoin.component.html',
    styleUrls: ['./product-and-optimizatoin.component.scss']
})
export class ProductAndOptimizatoinComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
