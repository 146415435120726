import { selectReportProductsPriceRangeSelector } from './../../store/selectors/status.selector';
import { IApplicationState } from './../../store/state/application.state';
import { ProductsPriceRange } from './../../model/report.interface';
import { HandlePopupsService } from './../../services/handle-popups.service';
import { RelatedProductsServiceService, IDimensionsAndIndexOfRelatedProductsArray, IAllProductDetails } from './../../services/related-products-service.service';
import { fade, fadeOut } from './../../animations/fade';

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AuthServiceService } from '../../../app/services/auth-service.service';

@Component({
    selector: 'product-highlight',
    templateUrl: './product-highlight.component.html',
    styleUrls: ['./product-highlight.component.scss'],
    animations: [fade, fadeOut],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductHighlightComponent implements OnInit, OnDestroy {
    public productDetails$: Observable<IAllProductDetails>;
    public relatedProductsArrLength: number;
    public relatedProductIndex: number;
    private limitProductLegth: number;
    private getProductsPriceRange$: Subscription;
    public ProductsPriceRange: ProductsPriceRange;
    private productDetailsSubscription: Subscription;
    public userIsLoggedIn: boolean;

    constructor(
        private authService: AuthServiceService,
        private store: Store<IApplicationState>,
        private productDetails: RelatedProductsServiceService,
        private handlePopCtaMember: HandlePopupsService
    ) {}

    getUsername(): Promise<boolean> {
        return this.authService.isUserLoggedIn();
    }

    ngOnInit(): void {
        this.getUsername().then((isLoggedIn: boolean) => {
            this.userIsLoggedIn = isLoggedIn;
        });
        this.productDetails$ = this.productDetails.detailsProductSubject;
        this.productDetailsSubscription = this.productDetails.indexAndArrLength.subscribe((data: IDimensionsAndIndexOfRelatedProductsArray) => {
            this.relatedProductsArrLength = data.allRelatedProductsLength;
            this.relatedProductIndex = this.productDetails.productIndex;
            this.limitProductLegth = this.productDetails.allRelatedProducts ? this.productDetails.allRelatedProducts.length - 1 : null;
        });
        this.getProductsPriceRange$ = this.store.pipe(select(selectReportProductsPriceRangeSelector)).subscribe((productsPriceRange: ProductsPriceRange) => {
            this.ProductsPriceRange = productsPriceRange;
        });
    }
    slideBetweenProducts(productIndex: number): void {
        this.productDetails.slideToNextProduct(productIndex);
        this.relatedProductIndex = this.productDetails.productIndex;
        if (this.relatedProductIndex === this.limitProductLegth && !this.userIsLoggedIn) {
            this.handlePopCtaMember.setCtaMemberPopupStatus(true);
        }
    }
    ngOnDestroy(): void {
        this.productDetailsSubscription.unsubscribe();
        this.getProductsPriceRange$.unsubscribe();
    }
    checkrelatedProductsArrLength(): boolean {
        return !this.relatedProductsArrLength;
    }
}
