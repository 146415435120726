import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';
import { IReportDTO, ListingResponse } from '../../../app/model/report.interface';

export const listingSelector: any = createSelector(
    selectReportsState,
    (state: IReportDTO): ListingResponse => {
        if (state) {
            const listingOfReport: ListingResponse = state.listing;
            if (listingOfReport) {
                return listingOfReport;
            }
        }
    }
);

listingSelector.release();
