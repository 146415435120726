
import { KeycloakConfig } from 'keycloak-angular';
import { IEnvironment } from './app.environment';

// Add here your keycloak setup infos
const keycloakConfig: KeycloakConfig = {
    url: 'https://keycloak.bloooom.co/auth',
    realm: 'bloooom-dev',
    clientId: 'bloooom-client',
    credentials: {
        secret: '4ac5b8f6-50c7-4601-a3e2-5225a2ac4c6e'
    }
};

export const environment: IEnvironment = {
    production: false,
    keycloak: keycloakConfig,
    apiUsersEndpoint: 'https://localhost:4200/assets/data',
    wsUrl: 'ws://localhost:4201',
    wsRecconectTimeout: 5000,
    defaultLang: 'en',
    videoSliderLink: 'https://www.youtube.com/embed/1Lmyq1yBpjs?&autoplay=1',
    bloooomUrl: 'https://localhost:4200',
    paymentServiceUrl: 'https://direct.tranzila.com/ttxbloooom/iframenew.php',
    ppcUrl: 'http://localhost:4201/ppc',
    nicheFinderUrl: 'http://localhost:4201/products/filter',
    userConfigurationUrl: 'http://localhost:4201/users/configuration',
    styleUrl: 'https://s3.us-east-2.amazonaws.com/www.assets.bloooom.co/{{BRAND}}',
    baseBrandUrl: 'https://s3.us-east-2.amazonaws.com/www.assets.bloooom.co/{{BRAND}}',
    productDataUrl: 'http://localhost:4201/products/get-product-data-by-asin',
    timeout: 60000,
    mentorsUrl: 'http://localhost:4201/marketplace/advisors',
    brandPricingUrl: 'http://localhost:4201/marketplace/payment-method',
    couponUrl: 'http://localhost:4201/check-coupon',
    generatePhraseUrl: 'http://localhost:4201/keywords/generate-for-phrases',
    generateAsinsPhraseUrl: 'http://localhost:4201/keywords/generate-for-asins',
    getTaskStatusUrl: 'http://localhost:4201/tasks',
    getKeywordUrl: 'http://localhost:4201/keywords',
    getWordFrequencyUrl: 'http://localhost:4201/keywords/frequency',
    getToProductsUrl: 'http://localhost:4201/keywords/top-products',
    getBestKeywordUrl: 'http://localhost:4201/keywords/best-keyword',
    getAmpScoreUrl: 'http://localhost:4201/keywords/amp-score',
    contactUsUrl: 'http://localhost:4201/users/contact-us',
    chromeExtentionURL: 'https://chrome.google.com/webstore/detail/bloooom/pmaigiakfbgeghbokbdmkkpclfnjjieo',
    getTaskStatusTimeout: 5000,
    getKeywordsTake: 10,
    getNotificationsUrl: 'http://localhost:4201/tasks/user',
    pollingServiceInterval: 10000,
    getProductReaserchUrl: 'http://localhost:4201/products/product-research-product',
    getProductResearchNicheUrl: 'http://localhost:4201/products/create-product-research-niche',
    createCampignURL: 'http://localhost:4201/google/campaign',
    customerActionUrl: 'http://localhost:4201/customer-actions/api/v1/{entityType}/apply',
    uploadCampignURL: 'http://localhost:4201/google/campaign/publish',
};


// import { KeycloakConfig } from 'keycloak-angular';
// import { IEnvironment } from './app.environment';

// // Add here your keycloak setup infos
// const keycloakConfig: KeycloakConfig = {
//     url: 'https://keycloak.bloooom.co/auth',
//     realm: 'bloooom',
//     clientId: 'bloooom-client',
//     credentials: {
//         secret: '444e6ed7-1f38-48bd-917d-e4f936e5a29a'
//     }
// };

// export const environment: IEnvironment = {
//     production: true,
//     keycloak: keycloakConfig,
//     apiUsersEndpoint: 'https://api.reports.bloooom.co/users/{user_id}/reports',
//     wsUrl: 'wss://report.bloooom.co/wss',
//     wsRecconectTimeout: 5000,
//     defaultLang: 'en',
//     videoSliderLink: 'https://www.youtube.com/embed/1Lmyq1yBpjs?&autoplay=1',
//     bloooomUrl: 'https://bloooom.co',
//     paymentServiceUrl: 'https://direct.tranzila.com/ttxbloooom/iframenew.php',
//     ppcUrl: 'https://report.bloooom.co/ppc',
//     nicheFinderUrl: 'https://report.bloooom.co/products/filter',
//     userConfigurationUrl: 'https://report.bloooom.co/users/configuration',
//     styleUrl: 'https://s3.us-east-2.amazonaws.com/www.assets.bloooom.co/{{BRAND}}',
//     baseBrandUrl: 'https://s3.us-east-2.amazonaws.com/www.assets.bloooom.co/{{BRAND}}',
//     productDataUrl: 'https://report.bloooom.co/products/get-product-data-by-asin',
//     timeout: 60000,
//     mentorsUrl: 'https://report.bloooom.co/marketplace/advisors',
//     brandPricingUrl: 'https://report.bloooom.co/marketplace/payment-method',
//     couponUrl: 'https://report.bloooom.co/check-coupon',
//     generatePhraseUrl: 'https://report.bloooom.co/keywords/generate-for-phrases',
//     generateAsinsPhraseUrl: 'https://report.bloooom.co/keywords/generate-for-asins',
//     getTaskStatusUrl: 'https://report.bloooom.co/tasks',
//     getKeywordUrl: 'https://report.bloooom.co/keywords',
//     getWordFrequencyUrl: 'https://report.bloooom.co/keywords/frequency',
//     getToProductsUrl: 'https://report.bloooom.co/keywords/top-products',
//     getBestKeywordUrl: 'https://report.bloooom.co/keywords/best-keyword',
//     getAmpScoreUrl: 'https://report.bloooom.co/keywords/amp-score',
//     contactUsUrl: 'https://report.bloooom.co/users/contact-us',
//     chromeExtentionURL: 'https://chrome.google.com/webstore/detail/bloooom/pmaigiakfbgeghbokbdmkkpclfnjjieo',
//     getTaskStatusTimeout: 5000,
//     getKeywordsTake: 10,
//     getNotificationsUrl: 'https://report.bloooom.co/tasks/user',
//     pollingServiceInterval: 10000,
//     getProductReaserchUrl: 'https://report.bloooom.co/products/product-research-product',
//     getProductResearchNicheUrl: 'https://report.bloooom.co/products/create-product-research-niche',
//     createCampignURL: 'https://report.bloooom.co/google/campaign',
//     customerActionUrl: 'https://report.bloooom.co/customer-actions/api/v1/{entityType}/apply',
//     uploadCampignURL: 'https://report.bloooom.co/google/campaign/publish'
// };
