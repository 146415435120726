import { ICardsData } from './../../model/ui-interfaces';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cards-section',
    templateUrl: './cards-section.component.html',
    styleUrls: ['./cards-section.component.scss']
})
export class CardsSectionComponent {
    cardsData: ICardsData[];
    constructor(private translate: TranslateService) {
        this.translate.get('bloooomComponent.cardsData').subscribe((res: ICardsData[]) => {
            this.cardsData = Object.keys(res).map((key: any) => res[key]);
        });
    }
}
