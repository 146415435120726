import { KeywordsSummery } from './../../../../model/report.interface';
import { RelatedProductsServiceService } from './../../../../services/related-products-service.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-summary-results-keywords',
    templateUrl: './summary-results-keywords.component.html',
    styleUrls: ['./summary-results-keywords.component.scss']
})
export class SummaryResultsKeywordsComponent implements OnInit {
    summaryData$: Observable<KeywordsSummery>;

    constructor(private keywordsSummaryData: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.summaryData$ = this.keywordsSummaryData.keywordsSummery$;
    }
}
