import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { GetNicheByFiltersAction } from '../../../app/store/actions/report.actions';
import { IReportState } from '../../../app/store/state/report.state';
import { NicheFinderService } from '../../../app/services/niche-finder.service';

@Component({
    selector: 'app-score-method-builder',
    templateUrl: './score-method-builder.component.html',
    styleUrls: ['./score-method-builder.component.scss']
})
export class ScoreMethodBuilderComponent implements OnInit {
    public nicheFinderFormFilter: FormGroup;

    constructor(private nicheFinderService: NicheFinderService, private fb: FormBuilder, private store: Store<IReportState>, private http: HttpClient) {}

    ngOnInit(): void {
        this.nicheFinderFormFilter = this.fb.group({
            keyword: [''],
            maxProductToAnalyze: 2,
            prouctFilters: this.fb.group({
                priceFilter: this.fb.group({
                    min: [''],
                    max: ['']
                }),
                reviewCountFilter: this.fb.group({
                    min: [''],
                    max: ['']
                })
            })
        });
    }
    submit(form: FormGroup): void {
        this.nicheFinderService.findNiche(form.value).then((response: any) => {
            this.store.dispatch(new GetNicheByFiltersAction(response));
        });
    }
}
