import { AlibabaProductsDTO } from './../../model/alibaba';
import { isNullOrUndefined } from 'util';
import { IReportDTO } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

export const alibabaAllDataSelector: any = createSelector(selectReportsState, (state: IReportDTO): AlibabaProductsDTO[] => {
    if (state) {
        const alibabaAllData: any = state.alibabaProducts;
        if (!isNullOrUndefined(alibabaAllData)) {
            return alibabaAllData;
        }
    }
});

export const alibabarelevantSuppliersSelector: any = createSelector(selectReportsState, (state: IReportDTO): number => {
    if (state) {
        const alibabaAllData: AlibabaProductsDTO = state.alibabaProducts;
        if (alibabaAllData) {
            return alibabaAllData.relevantSuppliers;
        }
        return 0;
    }
});

alibabaAllDataSelector.release();
alibabarelevantSuppliersSelector.release();
