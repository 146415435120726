import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { Roles } from '../model/Entities';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // tslint:disable-next-line: typedef
        return new Promise(async (resolve, reject) => {
            // in case of public api or no roles
            const requiredRoles: string[] = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0 || (requiredRoles.length === 1 && requiredRoles[0] === Roles.PUBLIC)) {
                resolve(true);
                return;
            }
            // in case need authentication and role managment
            if (!this.authenticated) {
                this.keycloakAngular.login();
                return;
            }
            // after authenticated check if user has roles.
            let granted: boolean = false;
            for (const requiredRole of requiredRoles) {
                if (this.roles.indexOf(requiredRole) > -1) {
                    granted = true;
                    break;
                }
            }
            // if user do not have roles redirect to access denied
            if (!granted) {
                this.router.navigate(['/access-denied']);
            }
            resolve(granted);
        });
    }
}
