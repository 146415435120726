import { GetSearchedAsinService } from './../../../services/get-searched-asin.service';
import { fade, fadeOut } from './../../../animations/fade';
import { BestVariation, FbaDto, Variation } from './../../../model/report.interface';
import { IProductInformation, RelatedProductsServiceService, IAllProductDetails } from './../../../services/related-products-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { GetFbaService } from '../../../../app/services/get-fba.service';
import { TheBestVariationService } from '../../../../app/services/the-best-variation.service';
import { ThemeService } from '../../../../app/services/theme.service';
import { Theme } from '../../../../assets/mocks/themes';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'best-variations',
    templateUrl: './best-variations.component.html',
    styleUrls: ['./best-variations.component.scss'],
    animations: [fade, fadeOut]
})
export class BestVariationsComponent implements OnInit, OnDestroy {
    public displayTooltip: boolean = false;
    public productInformation$: Observable<IProductInformation>;
    public variations$: Observable<Variation[]>;
    public productDetails$: Observable<IAllProductDetails>;
    public productFba$: Observable<FbaDto>;
    private variationsSubs$: Subscription;
    private productDetailsSubs$: Subscription;
    public variations: any[];
    public variationsLength: number = 0;
    public closePopVariations: boolean = false;
    public currentAsin: string;
    public searchedAsinQuery: string;
    public totalVariationsByType: string[] = [];
    public getDataForVariation: any[];
    public isGetDataProductRequested: string[] = [];
    public theBestVariation$: Observable<BestVariation>;
    public getActiveTheme$: Observable<Theme>;
    private getActiveThemeSub: Subscription;

    public measureIcon: string = './assets/images/product-info-icon.png';
    public bestVariation: string = './assets/images/the-best-variations-icon.svg';
    constructor(
        private theBestVariation: TheBestVariationService,
        private ProductInformation: RelatedProductsServiceService,
        private searchedAsin: GetSearchedAsinService,
        private fbaData: GetFbaService,
        private themeService: ThemeService
    ) {}

    ngOnInit(): void {
        this.getActiveTheme$ = this.themeService.getActiveTheme$;
        this.getActiveThemeSub = this.getActiveTheme$.subscribe((res: Theme) => {
            if (res) {
                this.measureIcon = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/product-info-icon.png';
                this.bestVariation = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/the-best-variations-icon.svg';
            }
        });
        this.theBestVariation.getTheBestVariation();
        this.theBestVariation$ = this.theBestVariation.theBestVariation$;

        this.productDetails$ = this.ProductInformation.detailsProductSubject;
        this.productInformation$ = this.ProductInformation.productInformationSubject;
        this.productFba$ = this.fbaData.productDataFoFba$;
        this.productDetailsSubs$ = this.productDetails$.subscribe((data: IAllProductDetails) => {
            if (data) {
                this.fbaData.getProductDataForFba(data.productAsin);
                this.currentAsin = data.productAsin;
            }
        });
        this.variationsSubs$ = this.productInformation$.subscribe((productVariations: IProductInformation) => {
            if (productVariations && !isEmpty(productVariations.variations)) {
                this.variations = productVariations.variations;
                for (const variationType of this.variations) {
                    this.variationsLength = this.variationsLength + variationType.values.length;
                    this.totalVariationsByType.push(variationType.values.length.toString() + ' ' + variationType.variationName.split('_')[1]);
                }
            }
        });
        setTimeout(() => {
            this.currentAsin = this.searchedAsin.getSearchedAsin();
            this.searchedAsinQuery = this.searchedAsin.getSearchedAsin();
        });
    }

    closePopVariationsFn(value: boolean): void {
        this.closePopVariations = value;
    }
    handleTooltip(val: boolean): void {
        this.displayTooltip = val;
    }

    ngOnDestroy(): void {
        this.variationsSubs$.unsubscribe();
        this.productDetailsSubs$.unsubscribe();
    }
}
