import { fade, fadeOut } from './../../animations/fade';
import { IMarketData, RelatedProductsServiceService } from './../../services/related-products-service.service';
import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import * as d3 from 'd3';
import { mean, min } from 'lodash';

@Component({
    selector: 'market-graph',
    templateUrl: './market-graph.component.html',
    styleUrls: ['./market-graph.component.scss'],
    animations: [fade, fadeOut]
})
export class MarketGraphComponent implements OnInit {
    public marketData$: Observable<IMarketData>;
    public displayTooltip: boolean = false;
    public dateRangeGraph: string;
    // d3 chart
    @ViewChild('chart') private chartContainer: ElementRef;
    public margin: { top: number; right: number; bottom: number; left: number } = { top: 20, right: 20, bottom: 30, left: 40 };
    data: any = [];

    public avgRevenue: number = 0;
    public avgSales: number = 0;
    public avgPrice: number = 0;
    public avgBsr: number = 0;
    public shownDataOnMonthsAmounts: number = 15;
    public predictionMonths: number = 3;

    constructor(private marketData: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.marketData$ = this.marketData.marketDataSubject;
        this.marketData$.subscribe((data: IMarketData) => {
            if (data) {
                this.data = data.productAvgVolumnOverTime;
                setTimeout(() => {
                    if (this.data.length > this.shownDataOnMonthsAmounts) {
                        this.data = data.productAvgVolumnOverTime.slice(this.data.length - this.shownDataOnMonthsAmounts, this.data.length);
                    }
                    this.avgSales = mean(this.data.map((item: any) => item.avgSales));
                    this.avgPrice = mean(this.data.map((item: any) => item.avgPrice));
                    this.avgBsr = mean(this.data.map((item: any) => item.avgBsr));
                    this.avgRevenue = mean(this.data.map((item: any) => item.avgRevenue));
                    if (this.data.length > 0) {
                        this.createChart();
                    }
                }, 100);
                this.dateRangeGraph = data.productAvgVolumnOverTime[0]
                    ? data.productAvgVolumnOverTime[0].timestamp + ' - ' + data.productAvgVolumnOverTime[data.productAvgVolumnOverTime.length - 1].timestamp
                    : 'no data';
            }
        });
    }
    handleTooltip(val: boolean): void {
        this.displayTooltip = val;
    }

    // d3 chart logic

    private createChart(): void {
        d3.select('svg#chartOfASIN').remove();

        const element: any = this.chartContainer.nativeElement;
        const data: any = this.data;

        const svg: any = d3
            .select(element)
            .append('svg')
            .attr('id', 'chartOfASIN')
            .attr('width', element.offsetWidth)
            .attr('height', element.offsetHeight);

        const contentWidth: any = element.offsetWidth - this.margin.left - this.margin.right;
        const contentHeight: any = element.offsetHeight - this.margin.top - this.margin.bottom;

        const x: typeof d3 = d3
            .scaleBand()
            .range([0, contentWidth])
            .padding(1)
            .domain(data.map((d: any) => d.timestamp));

        const y: typeof d3 = d3
            .scaleLinear()
            .range([contentHeight, 0])
            .domain([0, d3.max(data, (d: any) => d.avgRevenue)]);

        const g: typeof d3 = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', 'translate(0,' + contentHeight + ')')
            .call(d3.axisBottom(x));

        g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3.axisLeft(y).ticks(10))
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '0.71em')
            .attr('text-anchor', 'end')
            .text('Frequency');

        g.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', 'rgba(255,255,255,.5)')
            .attr('stroke-width', 2.5)
            .attr(
                'd',
                d3
                    .line()

                    .x(function(d: any): any {
                        return x(d.timestamp);
                    })
                    .y(function(d: any): any {
                        return y(d.avgRevenue);
                    })
            );
        const Tooltip: typeof d3 = d3
            .select('#chart')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            // .style('background-color', '#39214f')
            .style('border-radius', '20px')
            .style('height', '350px')
            .style('position', 'absolute')
            .style('top', '0')
            .style('padding', '10px');

        const mouseover: typeof d3 = function(d: any): any {
            d3.select(this)
                .attr('stroke', 'rgba(255, 255, 255, 0.3)')
                .attr('stroke-width', 20);

            Tooltip.style('opacity', 1);
            Tooltip.style('display', 'block');

            Tooltip.html(
                `<div class="tooltip-data">
                <span class="chart-tooltip-date">Product information on  ${d.timestamp}</span>
                <div class="chart-tooltip-revenue">
                <span class="title">Average Monthly Revenue</span>
                <span class="data">$${Number(d.avgRevenue)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                <div class="chart-tooltip-avg-price">
                <span class="title">Average Monthly Price</span>
                <span class="data">$${Number(d.avgPrice).toFixed(2)}</span>
                </div>
                <div class="chart-tooltip-bsr">
                <span class="title">Average Best Sellers Rank</span>
                <span class="data">#${Number(d.avgBsr)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - BSR</span>
                </div>
                <div class="chart-tooltip-sales">
                <span class="title">Average Monthly Sales</span>
                <span class="data">${Number(d.avgSales)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} -  Units</span>
                </div>
                </div>
            `
            )
                .style('left', d3.mouse(this)[0] - 80 + 'px')
                .style('top', d3.mouse(this)[1] - 360 + 'px');
        };

        const mouseleave: typeof d3 = function(): any {
            d3.select(this)
                .attr('stroke', '')
                .attr('stroke-width', 0);
            Tooltip.style('opacity', 0);
            Tooltip.style('display', 'none');
        };

        g.append('g')
            .selectAll('dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('class', 'circle-dot')
            .attr('cx', function(d: any): any {
                return x(d.timestamp);
            })
            .attr('cy', function(d: any): any {
                return y(d.avgRevenue);
            })
            .attr('r', 10)
            .attr('fill', 'rgba(255,255,255,1)')
            .on('mouseover', mouseover)
            // .on('mousemove', mousemove)
            .on('mouseleave', mouseleave);
    }

    onResize(): void {
        this.createChart();
    }
}
