import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-mentor-landing-page',
  templateUrl: './mentor-landing-page.component.html',
  styleUrls: ['./mentor-landing-page.component.scss']
})
export class MentorLandingPageComponent implements OnInit {

  private mentorName: string;
  public logoOfBrand: string;
  public logoOfBrandInvert: string;
  public background: string;
  public properties: string;
  public contentPageUrl: string;
  public contentPage: string;

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.mentorName = param.name;
      this.logoOfBrand = environment.baseBrandUrl.replace('{{BRAND}}', this.mentorName) + '/logo.svg';
      this.logoOfBrandInvert = environment.baseBrandUrl.replace('{{BRAND}}', this.mentorName) + '/logo-invert.svg';
      this.properties = environment.baseBrandUrl.replace('{{BRAND}}', this.mentorName) + '/style.brand.json';
      this.contentPageUrl = environment.baseBrandUrl.replace('{{BRAND}}', this.mentorName) + '/landingpage.brand.json';

      this.httpClient.get(this.contentPageUrl).subscribe((data: any) => {
        if (data)
          this.contentPage = data;
      });

      this.httpClient.get(this.properties).subscribe((data: any) => {
        this.background = data.properties['--bg-header-report'];
      });
    });
  };
};
