export interface Theme {
    name: string;
    properties: any;
    logoUrl: string;
}

export const mercorE: Theme = {
    name: 'mercorE',
    logoUrl: './assets/themes/mercoreE/logo-mercoreicons.png',
    properties: {
        '--bg-header-report': '#01003c',
        '--bg-main': '#282856',
        '--main-color-bright': '#ffd169',
        '--main-color-regular': '#3fe6ff',
        '--conf-dropdown-color': '#323162'
    }
};

export const bloooom: Theme = {
    name: 'bloooom',
    logoUrl: './assets/images/bloooom-logo.svg',
    properties: {
        '--bg-header-report': '#32054f',
        '--bg-main': 'linear-gradient(to left top, #720094 0%, #720094 50%) 0% 0% no-repeat padding-box',
        '--main-color-bright': '#ff00cf',
        '--main-color-regular': '#ff00cf',
        '--conf-dropdown-color': '#3d0d63'
    }
};
