import { IApplicationState } from './../../store/state/application.state';
import { GetSearchedAsinService } from './../../services/get-searched-asin.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthServiceService } from '../../../app/services/auth-service.service';
import { HandlePopupsService } from '../../../app/services/handle-popups.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    displayTooltip: boolean = false;
    countSubmit: number = 0;
    CHECK_ASIN_REX: RegExp = /^[0-9A-Z]{10}$/;

    protected getReportStatus$: Subscription;
    public status: string = '';
    public userLoggedIn: boolean;
    public demoAsin: string = 'B07VTM2MYQ';
    public isHomegape: boolean;

    public asin: string;

    constructor(
        private store: Store<IApplicationState>,
        private router: Router,
        private asinService: GetSearchedAsinService,
        private authService: AuthServiceService,
        private popHandlerService: HandlePopupsService
    ) { }

    ngOnInit(): void {
        this.asin = this.asinService.getSearchedAsin();
        if (!(this.router.url.indexOf('dashboard') > 0)) {
            this.isHomegape = true;
        } else {
            this.isHomegape = false;
        }

        if (this.CHECK_ASIN_REX.exec(this.asin) && !this.isHomegape) {
            this.router.navigate(['/dashboard/report'], { queryParams: { asin: this.asin } });
        }
        this.authService.isUserLoggedIn().then((res: boolean) => {
            this.userLoggedIn = res;
        });
    }

    handleTooltip(state: boolean): void {
        this.displayTooltip = state;
    }
    hanblePopupSignup(): void {
        this.popHandlerService.setCtaFromSearchPopupStatus(true);
    }

    onSubmit(searchForm: NgForm): void {
        if (this.userLoggedIn) {
            if (this.CHECK_ASIN_REX.exec(searchForm.value.searchProduct)) {
                this.asinService.setSearchedAsin(searchForm.value.searchProduct);
                this.asin = searchForm.value.searchProduct;
                this.router.navigate(['/dashboard/report'], { queryParams: { asin: searchForm.value.searchProduct } });
            } else {
                alert('Please use an valid ASIN');
            }
        } else {
            this.asinService.setSearchedAsin(this.demoAsin);
            this.asin = this.demoAsin;

            this.router.navigate(['/dashboard/report'], { queryParams: { asin: this.demoAsin } });
        }
    }
}
