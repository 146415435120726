import { Action } from '@ngrx/store';
import GetWordFrequencyResponse from '../../dtos/GetWordFrequencyResponse';
import GetKeywordResponse from '../../dtos/getKeywordResponse';
import TopProductResponse from '../../dtos/TopProductResponse';
import GetBestKeywordResponse from '../../dtos/GetBestKeywordResponse';
import GetProductResearchResponse from '../../dtos/GetProductResearchResponse';


export enum TaskActionsEnum {
    GET_KEYWORDS_ANALYSIS = '[KeywordAnalysis] Get Keyword Analysis',
    GET_KEYWORDS_ANALYSIS_BY_ASINS = '[KeywordAnalysisByAsins] Get Keyword Analysis By Asins',
    GET_WORD_FREQUENCY = '[WordFrequency] Get Word Frequency',
    GET_TOP_PRODUCT = "[TopProducts] Get Top Products",
    GET_BEST_KEYWORD = "[BestKeyword] Get Keyword",
    GET_PRODUCT_RESEARCH = "[ProductResearch] Get Product Research",
    GET_PRODUCT_RESEARCH_NICHE = "[ProductResearchNiche] Get Product Research Niche"
}
// tslint:disable: typedef
export class GetKeywordAnalysisAction implements Action {
    public readonly type = TaskActionsEnum.GET_KEYWORDS_ANALYSIS;
    constructor(public readonly keywords: GetKeywordResponse) { }
}

export class GetKeywordAnalysisByAsinsAction implements Action {
    public readonly type = TaskActionsEnum.GET_KEYWORDS_ANALYSIS_BY_ASINS;
    constructor(public readonly keywordsByAsins: GetKeywordResponse) { }
}

export class GetWordFrequencyAction implements Action {
    public readonly type = TaskActionsEnum.GET_WORD_FREQUENCY;
    constructor(public readonly wordFrequency: GetWordFrequencyResponse[]) { }
}

export class GetTopProductsAction implements Action {
    public readonly type = TaskActionsEnum.GET_TOP_PRODUCT;
    constructor(public readonly topProducts: TopProductResponse[]) { }
}

export class GetBestKeywordAction implements Action {
    public readonly type = TaskActionsEnum.GET_BEST_KEYWORD;
    constructor(public readonly bestKeyWord: GetBestKeywordResponse) { }
}

export class GetProductResearchAction implements Action {
    public readonly type = TaskActionsEnum.GET_PRODUCT_RESEARCH;
    constructor(public readonly productResearch: GetProductResearchResponse) { }
}

export class GetProductResearchNicheAction implements Action {
    public readonly type = TaskActionsEnum.GET_PRODUCT_RESEARCH_NICHE;
    constructor(public readonly productResearchNiche: GetProductResearchResponse) { }
}


export type TaskActions =
    | GetKeywordAnalysisAction
    | GetKeywordAnalysisByAsinsAction
    | GetWordFrequencyAction
    | GetTopProductsAction
    | GetBestKeywordAction
    | GetProductResearchAction
    | GetProductResearchNicheAction