import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {
    @Input() product: any;

    isPriceTableOpen: boolean;
    constructor() {}

    ngOnInit(): void {}
    isPriceTableOpenFun(): void {
        this.isPriceTableOpen = !this.isPriceTableOpen;
    }
}
