import { Component, OnInit } from '@angular/core';
import { GetSearchedAsinService } from '../../../app/services/get-searched-asin.service';

@Component({
    selector: 'app-download-report',
    templateUrl: './download-report.component.html',
    styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
    public searchedAsin: string;
    constructor(private SearchedAsin: GetSearchedAsinService) {}

    ngOnInit(): void {
        this.searchedAsin = this.SearchedAsin.getSearchedAsin();
    }

    downLoadToPdf(): void {
        const doc: any = window;
        doc.print();
    }
}
