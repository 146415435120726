import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthServiceService } from './auth-service.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NicheFinderService {
    constructor(private httpClient: HttpClient, private authServiceService: AuthServiceService) {}
    public async findNiche(nicheFinderRequest: any): Promise<any> {
        try {
            const token: string = await this.authServiceService.getToken();

            const httpOptions: any = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: token
                })
            };
            // putting timeout to request
            const response: any = await this.httpClient.post(environment.nicheFinderUrl, { nicheFinderRequest }, httpOptions).toPromise();
            return response;
        } catch (error) {
            console.log('Display user friendly message');
        }
        return null;
    }
}
