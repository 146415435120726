import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IReportState } from '../../../../app/store/state/report.state';
import { fade } from '../../../../app/animations/fade';
import { HandlePopupsService } from '../../../../app/services/handle-popups.service';
import { selectReportStatus } from '../../../../app/store/selectors/status.selector';

@Component({
    selector: 'app-pop-lazy-loading',
    templateUrl: './pop-lazy-loading.component.html',
    styleUrls: ['./pop-lazy-loading.component.scss'],
    animations: [fade]
})
export class PopLazyLoadingComponent implements OnInit {
    public watingPopDelay: boolean = false;
    public handlePopup$: Observable<boolean>;
    getReportSections$: Observable<any[]>;

    constructor(private handlePopup: HandlePopupsService, private cd: ChangeDetectorRef, private store: Store<IReportState>) { }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.handlePopup.setLazyLoadPopupStatus(false);
        }
    }

    ngOnInit(): void {
        this.handlePopup$ = this.handlePopup.lazyLoadPopupSubject;
        // this.sectionsStatusLazyLoad = this.lazyLoadData;
        this.getReportSections$ = this.store.pipe(select(selectReportStatus));
    }

    public closePopupWaiting(): void {
        this.handlePopup.setLazyLoadPopupStatus(false);
    }
}
