import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-alibaba-product-slider',
    templateUrl: './alibaba-product-slider.component.html',
    styleUrls: ['./alibaba-product-slider.component.scss']
})
export class AlibabaProductSliderComponent implements OnInit {
    @Input() imageUrlList: string[];

    public imageDisplay: number = 0;

    constructor() {}

    ngOnInit(): void {}

    imageDisplaySlider(add: number): void {
        this.imageDisplay = this.imageDisplay + add;
        if (this.imageDisplay > this.imageUrlList.length - 1) {
            this.imageDisplay = 0;
        } else if (this.imageDisplay < 0) {
            this.imageDisplay = this.imageUrlList.length - 1;
        }
    }
}
