import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IReportState } from '../store/state/report.state';
import { RelatedProductsSelectorByAsin } from '../store/selectors/related-products.selector';

@Injectable({
    providedIn: 'root'
})
export class GetVariationDataService {
    public productDataForVaiationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public productDataForVaiation$: Observable<any> = this.productDataForVaiationSubject.asObservable();
    getDataFromStore: any;
    constructor(private Store: Store<IReportState>) {}

    getproductDataForVaiation(asin: string): void {
        this.getDataFromStore = this.Store.pipe(select(RelatedProductsSelectorByAsin, { asin: asin }));
        this.getDataFromStore.subscribe((data: any) => {
            this.productDataForVaiationSubject.next(data);
        });
    }
}
