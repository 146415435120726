import CampaignForType from "../campaignForType";
import EngineType from "../engineType";

export class NewGoogleCampaignRequest {
  public campaignName: string;
  public asin: string;
  public dailyBudget: number;
  public startDate: Date;
  public endDate: Date;
  public engine: EngineType;
  public campaignForType: CampaignForType;
}



export enum SyncStatus {
  // google
  APPROVED = 'APPROVED',
  HANDLED = 'HANDLED',
  REVIEW = 'REVIEW',
  FAILED = 'FAILED',
  TO_DELETE = 'TO_DELETE',
  DELETE_PERMANENTLY = 'DELETE_PERMANENTLY',
  FAILED_TO_DELETE = 'FAILED_TO_DELETE',
  TO_PAUSE = 'TO_PAUSE',
  FAILED_TO_PAUSE = 'FAILED_TO_PAUSE',
  EXPORTED = 'EXPORTED',
  COMPETITOR = 'COMPETITOR',
}

export enum CampaignStatusInEngine {
  // google....
  Active = 'Active',
  Deleted = 'Deleted',
  Paused = 'Paused',
  Scheduled = 'Scheduled',
  Expired = 'Expired',
  Suspended = 'Suspended',
  PausedBudgetDepleted = 'Paused - Budget Depleted',
  PausedDailyBudgetDepleted = 'Paused - Daily Budget Depleted',
  Unknown = 'Unknown',
  DoNotSync = 'DoNotSync',
  Pending = 'Pending',
  Disapproved = 'Disapproved',
}


export enum EntityType {
  CAMPAIGN = 'campaign'
}