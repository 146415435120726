import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPlans, PricesService } from '../../../app/services/prices.service';
import { fade, fadeOut } from '../../../app/animations/fade';
import { BrandPaymentMethod } from '../../dtos/brandResponse';

@Component({
    selector: 'app-plans-of-payment',
    templateUrl: './plans-of-payment.component.html',
    styleUrls: ['./plans-of-payment.component.scss'],
    animations: [fade, fadeOut]
})
export class PlansOfPaymentComponent implements OnInit {
    @Input() choosenPlan: any = 0;
    @Input() choosenSubscription: any = 0;
    @Output() getChoosenPlan: EventEmitter<number> = new EventEmitter<number>();
    @Output() getChoosenSubscription: EventEmitter<number> = new EventEmitter<number>();
    @Input() prices: IPlans[];
    @Input() subscriptions: BrandPaymentMethod[];

    constructor(private pricesService: PricesService) {}

    ngOnInit(): void {
        this.pricesService.choosenPlan(this.choosenPlan);
        this.pricesService.choosenSubscription(this.choosenSubscription);
    }

    getDiscountFun(): void {
        this.pricesService.choosenPlan(this.choosenPlan);
        this.pricesService.choosenSubscription(this.choosenSubscription);
    }
}
