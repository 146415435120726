import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ProductBoosterService } from '../../../../app/services/product-booster.service';
import { fade } from '../../../../app/animations/fade';
import { HandlePopupsService } from '../../../../app/services/handle-popups.service';

import { CustomerActionsService } from '../../../../app/services/customer-actions.service';
import { ActionType } from '../../../../app/model/google/ActionType';
import { EntityType, SyncStatus } from '../../../../app/model/google/newGoogleCampaignRequest';
import { IKeywords } from '../../../../app/model/google/campaignForGoogle';

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss'],
  animations: [fade]
})
export class EditCampaignComponent implements OnInit, OnDestroy {

  public handlePopup$: Observable<boolean>;
  public editCampaignZoneForm: FormGroup = new FormGroup({});
  public campaignDataSub$: Subscription;
  public actionStatus: any = ActionType;
  public campaignForType: any = EntityType;
  public campaignStatuses: any = SyncStatus;
  public editZoneId: string = '';
  public isFormReady: boolean;
  public statusChangeInProcess: boolean = false;

  public data: any;

  constructor(private customerActionsService: CustomerActionsService, private fb: FormBuilder, public handlePopup: HandlePopupsService, private productBoosterService: ProductBoosterService) { }

  ngOnInit() {

    this.campaignDataSub$ = this.productBoosterService.campaignByEditZone$.subscribe((campaignData: any) => {
      if (campaignData) {
        this.data = campaignData;
        this.createEditCampaignZoneForm(campaignData);
        this.isFormReady = true;
      }
    })
  }

  public createEditCampaignZoneForm(editZoneData: any): void {
    this.editZoneId = editZoneData.id;
    switch (editZoneData.editZone) {
      case 'adGroups':
        this.editCampaignZoneForm = this.createEditAdgroupForm(editZoneData);
        console.log(this.editCampaignZoneForm);
        break;
      default:
        this.editCampaignZoneForm = this.fb.group({
          [editZoneData.editZone]: [editZoneData.data, Validators.required]
        })
    }

  };

  get groupAds(): FormArray {
    return this.editCampaignZoneForm.get('groupAds') as FormArray;
  }

  get keywords(): FormArray {
    return this.editCampaignZoneForm.get('keywords') as FormArray;
  }

  public addKeyword(): void {
    const keywordForm = this.fb.control(null);
    this.keywords.push(keywordForm);
  }

  public deleteLesson(keywordIndex: number): void {
    this.keywords.removeAt(keywordIndex);
  }

  public getChoosenSyncStatus(value: { key: string, value: string }): void {
    this.editCampaignZoneForm.controls['syncStatus'].setValue(value.key);
  };

  public onSubmit(form: FormGroup, e: Event): void {
    e.preventDefault();
    if (form.get('syncStatus').valid) {
      const entityIds: number[] = [+this.editZoneId];
      this.sendRequestOfCampaignsValueChanged(entityIds, this.campaignForType.CAMPAIGN, form.get('syncStatus').value);
      this.statusChangeInProcess = true;
      setTimeout(() => {
        window.location.reload();
        this.statusChangeInProcess = false;
      }, 5000);
    }
  }

  private sendRequestOfCampaignsValueChanged(entityIds: number[], entityName: EntityType, syncStatus: SyncStatus): void {
    this.customerActionsService.setStatusAction(entityIds, entityName, syncStatus);
  }


  private createEditAdgroupForm(editZoneData: any): FormGroup {
    return this.fb.group({
      groupAds: this.fb.array(
        editZoneData.data.ads.map((ad: IKeywords | string) => {
          return this.fb.control(ad)
        })
      ),
      keywords: this.fb.array(
        editZoneData.data.keywords.map((keyword: IKeywords | string) => {
          return this.fb.control(keyword)
        })
      ),
      name: [editZoneData.data.name, Validators.required]
    })
  }

  public closePopupWaiting(): void {
    this.handlePopup.setEditCampaignPopupStatus(false);

  };



  ngOnDestroy(): void {
    this.campaignDataSub$.unsubscribe();
  }
}






// $$$$$$$$$$$$$$$$$$ EXAMPLE OF BUILDING FORM FROM CAMPAIGN OBJECT $$$$$$$$$$$$$$$ //



  // get adGroup(): FormArray {
  //   return this.editCampaignForm.controls["adGroup"] as FormArray;
  // }

  // public getAds(form: any): AbstractControl {
  //   return form.controls.ads.controls;
  // }

  // public createEditCampaignForm(campaignData: ICampaign): void {
  //   this.editCampaignForm = this.fb.group({
  //     campaignName: [campaignData.campaignName, Validators.required],
  //     syncStatus: [campaignData.syncStatus, Validators.required],
  //     adGroup: this.fb.array(
  //       campaignData.adGroups.map((adGroup: IAdGroups) => {
  //         return this.fb.group({
  //           ads: this.fb.array(this.adsFormArray(adGroup)),
  //           adGroupId: [adGroup.adGroupId],
  //           keywords: this.fb.array(
  //             adGroup.keywords.map((keyword: IKeywords) => {
  //               return this.fb.group({
  //                 keywordType: [keyword.keywordType],
  //                 keywords: [keyword.keywords]
  //               })
  //             })
  //           )
  //         })
  //       })
  //     ),

  //     datePicker: this.fb.group({
  //       startDate: [campaignData.startDate, Validators.required],
  //       endDate: [campaignData.endDate, Validators.required],
  //     }, { validators: [DateValidators.fromToDate('startDate', 'endDate')] }),
  //     totalDays: [campaignData.totalDays, Validators.required],
  //     dailyBudget: [campaignData.dailyBudget, Validators.required],
  //     totalBudget: [campaignData.totalBudget, Validators.required]
  //   });
  // }



   // private adsFormArray(adGroup): any[] {
  //   const arr: any = adGroup.ads.map((ad: IAd) => {
  //     return this.fb.group({
  //       adType: ad.adType,
  //       description1: ad.description1,
  //       description2: ad.description2,
  //       headline1: ad.headline1,
  //       headline2: ad.headline2,
  //       headline3: ad.headline3,
  //       headline4: ad.headline4,
  //       headline5: ad.headline5,
  //       path1: ad.path1,
  //       path2: ad.path2
  //     })
  //   })
  //   console.log(arr)
  //   return arr;
  // }
