import { ReviewSummaryService } from './../../services/review-summary.service';
import { RelatedProductsServiceService } from './../../services/related-products-service.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReviewsSummary } from '../../model/report.interface';
import { map } from 'rxjs/operators';
import { fade, fadeOut } from '../../../app/animations/fade';
@Component({
    selector: 'app-rating-and-reviews',
    templateUrl: './rating-and-reviews.component.html',
    styleUrls: ['./rating-and-reviews.component.scss'],
    animations: [fade, fadeOut]
})
export class RatingAndReviewsComponent implements OnInit {
    public getSalesVolumeByReviewsLabels: string[] = ['Sales Volume in units', 'Total Number of Reviews per product'];
    public getProductsByReviewsLabels: string[] = ['Percentage of products found', 'Total Number of Reviews per product'];
    public getSalesVolumeByPrice$: Observable<any>;
    public reviewSummary$: Observable<ReviewsSummary>;
    public reviewWodson$: Observable<any>;
    public legendsChart: any[] = [
        { title: 'Your searched ASIN', color: 'rgb(244,255,85)' },
        { title: 'Related products', color: 'rgba(255,255,255,.3)' }
    ];
    public headerChartScatter: any = {
        title: 'Sales Volume by Product’s Number of Reviews',
        content:
            'This chart shows the related products sales volume by their number of reviews. Click on a dot to get more informations or to generate a new report for the specific related product.'
    };
    public headerChartBars: any = {
        title: 'Number of Reviews of the Related Products',
        content:
            'This chart shows the how much reviews the related products have. The highest bar shows how much reviews most of the related products have. Touch on a bars to get more informations..'
    };

    constructor(private relatedProductsServiceService: RelatedProductsServiceService, private reviewSummary: ReviewSummaryService) {}

    ngOnInit(): void {
        this.getSalesVolumeByPrice$ = this.relatedProductsServiceService.allRelatedProductsObs$;
        this.reviewSummary$ = this.reviewSummary.getReviewSummary();
        this.reviewWodson$ = this.reviewSummary.getReviewSummary().pipe(
            map((reviewSummary: any) => {
                if (reviewSummary) {
                    return { percentageOfNegativeReviews: reviewSummary.percentageOfNegativeReviews, percentageOfPositiveReviews: reviewSummary.percentageOfPositiveReviews };
                }
            })
        );
    }
}
