export default class GetAmpScoreFilter {
    public taskId: number;
    public keywords: string[];

    constructor(params?: Partial<GetAmpScoreFilter>) {
        if (params) {
            this.taskId = params.taskId;
            this.keywords = params.keywords;
        }
    }
}