
import { ActionType } from './ActionType';

export class CustomerActionRequest {
    actionType: ActionType;
    parameters: ParameterDto[];

    constructor(actionType: ActionType, parameters: ParameterDto[]) {
        this.actionType = actionType;
        this.parameters = parameters;
    }
}


export class ParameterDto {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

