import { inputInAnimation } from './../../../animations/inputAnimation';
import { fade } from './../../../animations/fade';
import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';

@Component({
    selector: 'variations-pop',
    templateUrl: './variations-pop.component.html',
    styleUrls: ['./variations-pop.component.scss'],
    animations: [fade, inputInAnimation]
})
export class VariationsPopComponent implements OnInit {
    @Input() allVariations: any[];
    @Output() closePopupWaitingClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.closePopupWaiting();
        }
    }

    ngOnInit(): void {}
    public closePopupWaiting(): void {
        this.closePopupWaitingClosed.emit(false);
    }
}
