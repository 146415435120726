import { Component, Input, OnInit } from '@angular/core';
import { CampaignService } from '../../../app/services/campaign.service';
import { IAdGroups, ICampaign } from '../../../app/model/google/campaignForGoogle';

@Component({
  selector: 'app-adgroup',
  templateUrl: './adgroup.component.html',
  styleUrls: ['./adgroup.component.scss']
})
export class AdgroupComponent implements OnInit {

  @Input() index: number;
  @Input() adgroup: IAdGroups;
  @Input() campaignData: ICampaign;
  public isCampaignFinished: boolean;


  constructor(private campaignService: CampaignService) { }

  ngOnInit() {
    this.isCampaignFinished = this.campaignService.checkCampaginFinished(this.campaignData);
  }

}
