import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-message-create-campaign',
  templateUrl: './validation-message-create-campaign.component.html',
  styleUrls: ['./validation-message-create-campaign.component.scss']
})
export class ValidationMessageCreateCampaignComponent implements OnInit {

  @Input() isValidate: boolean;
  @Input() invalidMessage: string;

  constructor() { }

  ngOnInit(): void {
  }

}
