import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, fromEvent, empty } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { share, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ScreenResizeService {
    public scroll$: Observable<number>;

    constructor(@Inject(DOCUMENT) private document: any, @Inject(PLATFORM_ID) private platformId: object) {
        if (isPlatformBrowser(this.platformId)) {
            this.scroll$ = fromEvent(window, 'scroll').pipe(
                map((event: any) => {
                    return window.scrollY || this.document.documentElement.scrollTop;
                }),
                share()
            );
        } else {
            this.scroll$ = empty(); // tslint:disable-line: deprecation
        }
    }

    public autoScroll(toWhere: string, topPosition: number): void {
        switch (toWhere) {
            case 'bottom':
                window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    }
}
