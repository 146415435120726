import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as qs from 'qs';
import { timeout } from 'rxjs/operators';
import KeywordsFromPhrasesRequest from '../dtos/generateKeyword';
import GetKeywordsFilter from '../dtos/GetKeywordsFilter';
import GetAmpScoreFilter from '../dtos/GetAmpScoreFilter';
import GetProductResearchProductFilter from '../dtos/GetProductResearchProductFilter';
import KeywordsFormAsinListRequest from '../dtos/KeywordsFormAsinListRequest';
import { CampaignStatusInEngine } from '../model/google/newGoogleCampaignRequest';

@Injectable({
    providedIn: 'root'
})
export class MarketPlaceService {
    public brand: string;
    public token: string;
    public mainSearchWords: string[];

    constructor(private httpClient: HttpClient) { }

    public getMentors(page: number, take: number, text: string): Observable<any> {
        const url = `${environment.mentorsUrl}?${qs.stringify({ page, take, text })}`;
        return this.httpClient.get(url).pipe(timeout(environment.timeout));
    }

    public getCoupon(coupon: string): Observable<any> {
        const brand = this.brand;
        const url = `${environment.couponUrl}?${qs.stringify({ coupon, brand })}`;
        return this.httpClient.get(url).pipe(timeout(environment.timeout));
    }

    public setBrandName(brand: string): void {
        this.brand = brand;
    }

    public getBrand(): string {
        return this.brand;
    }

    public setToken(token: string): void {
        this.token = token;
    }

    public getPricingAccordingBrand(brand: string): Observable<any> {
        const url = `${environment.brandPricingUrl}?${qs.stringify({ brand })}`;
        return this.httpClient.get(url).pipe(timeout(environment.timeout));
    }

    public generatePhrase(keyword: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let keywordsFromPhrasesRequest: KeywordsFromPhrasesRequest = new KeywordsFromPhrasesRequest();
        keywordsFromPhrasesRequest.keywords = [keyword];
        this.mainSearchWords = [keyword];

        let url = `${environment.generatePhraseUrl}`;
        return this.httpClient.post(url, keywordsFromPhrasesRequest, { headers: headers }).pipe(timeout(environment.timeout));
    }

    public generatePhraseAsins(asins: string[]): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const keywordsFormAsinListRequest: KeywordsFormAsinListRequest = new KeywordsFormAsinListRequest(asins);
        let url = `${environment.generateAsinsPhraseUrl}`;
        return this.httpClient.post(url, keywordsFormAsinListRequest, { headers: headers }).pipe(timeout(environment.timeout));
    }

    public getTaskStatus(taskId: number): Observable<any> {
        const url = `${environment.getTaskStatusUrl}?${qs.stringify({ taskId })}`;
        return this.httpClient.get(url).pipe(timeout(environment.timeout));
    }

    public getKeyword(getKeywordsFilter: GetKeywordsFilter): Observable<any> {

        const url = `${environment.getKeywordUrl}?${qs.stringify(getKeywordsFilter)}`;
        return this.httpClient.get(url, { responseType: 'text' }).pipe(timeout(environment.timeout));
    }

    public getAmpScore(getAmpScoreFilter: GetAmpScoreFilter): Observable<any> {
        const url = `${environment.getAmpScoreUrl}`;
        return this.httpClient.put(url, getAmpScoreFilter, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json' }).pipe(timeout(environment.timeout));
    }

    public getProductResearch(getProductResearchProductFilter: GetProductResearchProductFilter): Observable<any> {
        const url = `${environment.getProductReaserchUrl}?${qs.stringify(getProductResearchProductFilter)}`;
        return this.httpClient.get(url, { responseType: 'text' }).pipe(timeout(environment.timeout));
    }
    public getCampaignByName(campaignName: string): Observable<any> {
        const url = `${environment.createCampignURL}?${qs.stringify({ campaignName, excludeStatuses: [CampaignStatusInEngine.Deleted] })}`;
        return this.httpClient.get(url).pipe(timeout(environment.timeout));
    }
}
