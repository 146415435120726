import { Action } from '@ngrx/store';
import UserTask from '../../model/usertasks/UserTask';

export enum UserTaskActionsEnum {
    GET_USER_TASKS = '[Task] Get User Tasks',
    GET_TASK_BY_ID = '[Task] Get Task By ID'
}
// tslint:disable: typedef
export class GetUserTasksAction implements Action {
    public readonly type = UserTaskActionsEnum.GET_USER_TASKS;
    constructor(public readonly userTasks: UserTask[]) {}
}

// tslint:disable: typedef
export class GetUserTaskBuIdAction implements Action {
    public readonly type = UserTaskActionsEnum.GET_TASK_BY_ID;
    constructor(public readonly userTasks: UserTask) {}
}

export type UserTaskActions = GetUserTasksAction | GetUserTaskBuIdAction;
