import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENotificationsStatus, INotifications } from '../../assets/data/notification.mock';
import { HttpClient } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import AsyncResponse from '../dtos/AsyncResponse';
import { MarketPlaceService } from '../services/MarketPlaceService';
import { PollingService } from './polling.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private notificationsSubject: BehaviorSubject<INotifications[]> = new BehaviorSubject<INotifications[]>(null);
  public notifications$: Observable<INotifications[]> = this.notificationsSubject.asObservable();
  public responseData: AsyncResponse;

  constructor(private httpClient: HttpClient, private marketPlaceService: MarketPlaceService, private pollingService: PollingService) {
    pollingService.setNotificationService(this);
  }

  public getNotifications(): void {
    this.httpClient.get(environment.getNotificationsUrl).pipe(timeout(environment.timeout)).subscribe(
      async (response: AsyncResponse) => {
        if (response != null) {
          this.notificationsSubject.next(response.data);
          this.responseData = response;
        }
        else {
          this.notificationsSubject.next([]);
        }
      });

  }
  public notificationsReaded(): void {
    // notificationsMock.map((data: INotifications) => data.isRead = true);
    // this.notificationsSubject.next(notificationsMock);
  }

  public deleteNotification(notification: INotifications) {
    let requestJson = {};
    requestJson['taskId'] = notification.taskId;
    requestJson['isRead'] = true;
    this.httpClient.post(environment.getNotificationsUrl, requestJson).subscribe(
      (response) => {
        //console.log(response);
        // this.getNotifications();
      }
    );
  }

  public updateStatus(status: String) {
    this.responseData.data.forEach((data: any) => {
      if (data.status !== ENotificationsStatus.READY) {
        if (data.status === ENotificationsStatus.IN_PROGRESS) {
          data.status = status;
        }
      }
    });
    this.notificationsSubject.next(this.responseData.data);
  }

}


