import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[loadingData]'
})
export class LoadingDataDirective implements OnInit {
    @Input() dataElement: any;
    constructor(private element: ElementRef) {}
    ngOnInit(): void {
        this.element.nativeElement.innerHTML = `<div class="loading-animating"></div>`;
    }
}
