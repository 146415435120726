import { IApplicationState } from './../state/application.state';
import { initialUserTaskState, UserTaskState } from '../state/userTask.state';
import UserTask from '../../model/usertasks/UserTask';
import { findIndex } from 'lodash';
import { GetUserTaskBuIdAction, GetUserTasksAction, UserTaskActions, UserTaskActionsEnum } from '../actions/userTask.actions';

export function userTaskReducers(state: UserTaskState = initialUserTaskState, action: UserTaskActions): UserTaskState {
    switch (action.type) {
        case UserTaskActionsEnum.GET_USER_TASKS: {
            return handleGetUserTasksAction(action, state);
        }
        case UserTaskActionsEnum.GET_TASK_BY_ID: {
            return handleGetUserTaskByIdAction(action, state);
        }
        default:
            return state;
    }
}
function handleGetUserTasksAction(action: GetUserTasksAction, state: UserTaskState): UserTaskState {
    const userTasks: UserTask[] = { ...state.userTasks };
    return {
        userTasks
    };
}

function handleGetUserTaskByIdAction(action: GetUserTaskBuIdAction, state: UserTaskState): UserTaskState {
    const userTasks: UserTask[] = { ...state.userTasks };
    const index: number = findIndex(userTasks, { taskId: action.userTasks.taskId });
    if (index !== -1) {
        userTasks[index] = action.userTasks;
    } else {
        userTasks.push(action.userTasks);
    }
    return {
        userTasks
    };
}

export const selectUserTasks: any = (state: IApplicationState): UserTask[] => state.userTaskState.userTasks;
