import { isNullOrUndefined } from 'util';
import { IReportDTO, IKewordsDTO, ReviewsSummary } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

export const reviewsSelector: any = createSelector(selectReportsState, (state: IReportDTO): IKewordsDTO[] => {
    if (state) {
        const reviewSummary: any = state.reviewsSummary;
        if (!isNullOrUndefined(reviewSummary)) {
            return reviewSummary;
        }
    }
});

export const reviewFactorSelector: any = createSelector(selectReportsState, (state: IReportDTO): string => {
    if (state) {
        const reviewSummary: ReviewsSummary = state.reviewsSummary;
        if (!isNullOrUndefined(reviewSummary)) {
            return reviewSummary.averageSalesReviewFactor;
        }
    }
});

reviewsSelector.release();
reviewFactorSelector.release();
