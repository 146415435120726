import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '../../../app/services/auth-service.service';
import { MarketPlaceService } from '../../../app/services/MarketPlaceService';
import BasePayment from '../shared/BasePayment';

@Component({
  selector: 'app-payment-landing-page',
  templateUrl: './payment-landing-page.component.html',
  styleUrls: ['./payment-landing-page.component.scss']
})
export class PaymentLandingPageComponent extends BasePayment implements OnInit {

  @Input() logoOfBrand: string;
  public price: number;
  @Input() brandContent: string[];
  public isLoggin: boolean;

  constructor(authService: AuthServiceService, marketPlaceService: MarketPlaceService, router: Router, private route: ActivatedRoute) {
    super(authService, marketPlaceService, router);
  }

  public async ngOnInit(): Promise<void> {
    this.route.params.subscribe(({ name }) => {
      this.getPricingAccordingBrand(name);
    })
    this.isLoggin = await this.authService.isUserLoggedIn();
  }
  public async login(): Promise<void> {
    if (!this.isLoggin) {
      await this.authService.login();
    }
  }

  private getPricingAccordingBrand(brandName: string): void {
    this.marketPlaceService.getPricingAccordingBrand(brandName).subscribe(brand => {
      this.price = brand.data.brandPaymentMethod ? brand.data.brandPaymentMethod.price : null;
    });
  }

}
