import { Component, Input, OnInit } from '@angular/core';

export interface IBreadCrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-bread-crumbs',
    templateUrl: './bread-crumbs.component.html',
    styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
    @Input() crumbs: any[];
    public breadcrumbs: IBreadCrumb[];
    constructor() {}

    ngOnInit(): void {}
}
