import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../../../../app/services/auth-service.service';
import { HandlePopupsService } from '../../../../app/services/handle-popups.service';
import { fade } from '../../../../app/animations/fade';
import { Router } from '@angular/router';

@Component({
    selector: 'pop-extended-thrashold',
    templateUrl: './pop-extended-thrashold.component.html',
    styleUrls: ['./pop-extended-thrashold.component.scss'],
    animations: [fade]
})
export class PopExtendedThrasholdComponent implements OnInit {
    public watingPopDelay: boolean = false;
    public handlePopup$: Observable<boolean>;
    constructor(private handlePopup: HandlePopupsService, private authService: AuthServiceService, private route: Router) {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.handlePopup.setExendedPopup(false);
        }
    }
    // public async login(): Promise<void> {
    //     const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
    //     if (isLoggedIn) {
    //     } else {
    //         await this.authService.login();
    //     }
    // }

    // public async signup(): Promise<void> {
    //     const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
    //     if (isLoggedIn) {
    //     } else {
    //         await this.authService.signUp();
    //     }
    // }

    ngOnInit(): void {
        this.handlePopup$ = this.handlePopup.exendedPopupSubject;
    }

    public buyMembership(): void {
        this.handlePopup.setExendedPopup(false);
        this.route.navigate(['./pricing']);
    }
    public closePop(): void {
        this.handlePopup.setExendedPopup(false);
    }
}
