import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MessagesService } from '../../../app/services/messages.service';

@Component({
    selector: 'app-small-message',
    templateUrl: './small-message.component.html',
    styleUrls: ['./small-message.component.scss']
})
export class SmallMessageComponent {
    constructor(private messagesService: MessagesService) {}

    closeMessage(): void {
        this.messagesService.closeMessage();
    }
}
