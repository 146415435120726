import { fade, fadeOut } from './../../animations/fade';
import { BloooomScoreService } from './../../services/bloooom-score.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'bloooom-score',
    templateUrl: './bloooom-score.component.html',
    styleUrls: ['./bloooom-score.component.scss'],
    animations: [fadeOut, fade]
})
export class BloooomScoreComponent implements OnInit {
    public bloooomScore$: Observable<number>;

    constructor(private bloooomScore: BloooomScoreService) {}
    ngOnInit(): void {
        this.bloooomScore$ = this.bloooomScore.bloooomScoreData$;
    }
}
