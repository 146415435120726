import { RelatedProductsServiceService } from './../../../services/related-products-service.service';
import { fade, fadeOut } from './../../../animations/fade';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportReviewDto } from './../../../model/report.interface';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.scss'],
    animations: [fade, fadeOut],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewsComponent implements OnInit {
    stars: string[] = [];
    starsRate$: Observable<any>;
    searchedAsin: string;
    reviewDisplayed: number = 0;
    countRate$: Observable<number>;
    productReviews: any;
    reviewsInsights: any[] = ['sdf', 'sdf', 'sdf', 'sdf'];

    @Input() reviews: ReportReviewDto[];

    constructor() { }

    ngOnInit(): void {
    }

    reviewSliderControl(indexClicked: number, length?: number): void {
        this.reviewDisplayed = indexClicked;
        if (this.reviewDisplayed < 0) {
            this.reviewDisplayed = 0;
        }
        if (this.reviewDisplayed > length) {
            this.reviewDisplayed = length;
        }
    }
}
