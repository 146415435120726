import { fade, fadeOut } from './../../../animations/fade';
import { IKewordsDTO } from './../../../model/report.interface';
import { RelatedProductsServiceService } from './../../../services/related-products-service.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-keywords',
    templateUrl: './keywords.component.html',
    styleUrls: ['./keywords.component.scss'],
    animations: [fade, fadeOut]
})
export class KeywordsComponent implements OnInit {
    keywords$: Observable<IKewordsDTO>;
    displayTooltipPosition: boolean = false;
    displayTooltipCompitition: boolean = false;
    categories$: Observable<string[]>;
    constructor(private keywordsService: RelatedProductsServiceService, private categories: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.keywords$ = this.keywordsService.keywordsData$;
        this.categories$ = this.categories.categoriesDataSubject;
    }
    cleanParameter(value: number): string {
        if (!isNullOrUndefined(value)) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '---';
    }
    handleTooltip(val: boolean, col: string): void {
        switch (col) {
            case 'competition':
                this.displayTooltipCompitition = val;
                break;
            case 'position':
                this.displayTooltipPosition = val;
                break;

            default:
        }
    }

    sortTable(n: number): void {
        let table: any,
            rows: any,
            switching: any,
            i: any,
            x: any,
            y: any,
            shouldSwitch: any,
            dir: any,
            switchcount: number = 0;

        table = document.getElementById('myTable2');
        switching = true;
        dir = 'asc';
        while (switching) {
            switching = false;
            rows = table.getElementsByTagName('TR');
            for (i = 1; i < rows.length - 1; i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName('TD')[n];
                y = rows[i + 1].getElementsByTagName('TD')[n];
                let cmpX: any = isNaN(parseInt(x.innerHTML.split(',').join(''), 10))
                    ? x.innerHTML
                          .split(',')
                          .join('')
                          .toLowerCase()
                    : parseInt(x.innerHTML.split(',').join(''), 10);
                let cmpY: any = isNaN(parseInt(y.innerHTML.split(',').join(''), 10))
                    ? y.innerHTML
                          .split(',')
                          .join('')
                          .toLowerCase()
                    : parseInt(y.innerHTML.split(',').join(''), 10);
                cmpX = cmpX === '-' ? 0 : cmpX;
                cmpY = cmpY === '-' ? 0 : cmpY;
                cmpX = cmpX === 'not found' ? 999 : cmpX;
                cmpY = cmpY === 'not found' ? 999 : cmpY;
                if (dir === 'asc') {
                    if (cmpX > cmpY) {
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir === 'desc') {
                    if (cmpX < cmpY) {
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                switchcount++;
            } else {
                if (switchcount === 0 && dir === 'asc') {
                    dir = 'desc';
                    switching = true;
                }
            }
        }
    }
}
