import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RelatedProductsServiceService } from './related-products-service.service';

@Injectable({
    providedIn: 'root'
})
export class BloooomScoreService {
    bloooomScore: number;
    bloooomScoreData$: Observable<number>;
    constructor(private bloooomScoreFromService: RelatedProductsServiceService) {
        this.bloooomScoreData$ = this.bloooomScoreFromService.bloooomScoreSubject;
    }
    // getBloooomScore(): Observable<number> {
    //     return this.bloooomScoreData$;
    // }
}
