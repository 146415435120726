import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthServiceService } from './auth-service.service';

@Injectable({
    providedIn: 'root'
})
export class PccServiceService {
    constructor(private httpClient: HttpClient, private authServiceService: AuthServiceService) {}

    public async sendPpcRequest(): Promise<boolean> {
        try {
            const token: string = await this.authServiceService.getToken();
            await this.httpClient.post(environment.ppcUrl, { token: token }).toPromise();
            return true;
        } catch (error) {}
        return false;
    }
}
