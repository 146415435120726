import { Action } from "@ngrx/store";

export enum CampaignActionsEnum {
    GET_CAMPAIGN = "[CreateCampaign] Get Create Campaign"
}

export class GetCreateCampaignAction implements Action {
    public readonly type = CampaignActionsEnum.GET_CAMPAIGN;
    constructor(public readonly campaignManager: any) { }
}

export type CampaignsActions =
    | GetCreateCampaignAction