import { SubscriptionComponent } from './layouts/subscription/subscription.component';
import { SignupComponent } from './layouts/signup/signup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppAuthGuard } from './auth/AppAuthGuard';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { AccessDeniedComponent } from './core/access-denied/access-denied.component';
import { Roles } from './model/Entities';
import { BloooomComponent } from './layouts/bloooom/bloooom.component';
import { ReportComponent } from './layouts/report/report.component';
import { PurchaseSuccessfulComponent } from './layouts/purchase-successful/purchase-successful.component';
import { ContactUsComponent } from './layouts/contact-us/contact-us.component';
import { AboutComponent } from './layouts/about/about.component';
import { FaqComponent } from './layouts/faq/faq.component';
import { UsermanualComponent } from './layouts/usermanual/usermanual.component';
import { TermsComponent } from './layouts/terms/terms.component';
import { PolicyComponent } from './layouts/policy/policy.component';
import { ScoreMethodBuilderComponent } from './layouts/score-method-builder/score-method-builder.component';
import { LoginCheckComponent } from './core/login-check/login-check.component';
import { MentorsComponent } from './layouts/mentors/mentors.component';
import { KeywordsAnalysisComponent } from './layouts/keywords-analysis/keywords-analysis.component';
import { MentorLandingPageComponent } from './layouts/mentor-landing-page/mentor-landing-page.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { MainOverviewComponent } from './layouts/dashboard/main-overview/main-overview.component';
import { ProductLauncherComponent } from './core/product-launcher/product-launcher.component';
import { ProductResearchComponent } from './layouts/product-research/product-research.component';
import { ProductResearchNicheComponent } from './layouts/product-research-niche/product-research-niche.component';
import { GuardRedirectGuard } from './auth/guard-redirect.guard';
import { KeywordAnalysisAsinsComponent } from './layouts/keyword-analysis-asins/keyword-analysis-asins.component';
import { PartnersComponent } from './layouts/partners/partners.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'bloooom'
            },

            {
                path: 'bloooom',
                component: BloooomComponent,
                canActivate: [AppAuthGuard, GuardRedirectGuard],
                data: { roles: [Roles.PUBLIC] }
            },
            {
                path: 'contact-us',
                data: { breadcrumb: 'Contact us' },
                component: ContactUsComponent
            },
            // {
            //     path: 'partners',
            //     data: { breadcrumb: 'Partners' },
            //     component: PartnersComponent
            // },
            {
                path: 'access-denied',
                component: AccessDeniedComponent
            },
            {
                path: 'scoring-builder',
                component: ScoreMethodBuilderComponent
            },
            {
                path: 'pricing',
                component: SubscriptionComponent
            },
            {
                path: 'about',
                component: AboutComponent
            },
            {
                path: 'faq',
                component: FaqComponent
            },
            {
                path: 'user-manual',
                component: UsermanualComponent
            },
            {
                path: 'terms',
                component: TermsComponent
            },
            {
                path: 'mentors',
                component: MentorsComponent,
            },
            {
                path: 'mentors/:name',
                component: MentorLandingPageComponent
            },
            {
                path: 'policy',
                component: PolicyComponent
            },
            {
                path: 'report',
                component: ReportComponent
            },
            {
                path: 'thank-you',
                component: PurchaseSuccessfulComponent
            },
            {
                path: 'signup',
                component: SignupComponent
            },
            {
                path: 'login-check',
                component: LoginCheckComponent
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'overview'
                    },
                    {
                        path: 'overview',
                        component: MainOverviewComponent,
                    },
                    {
                        path: 'report',
                        component: ReportComponent
                    },
                    {
                        path: 'terms-calculator',
                        component: KeywordsAnalysisComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PAID_LICENSE] }
                    },
                    {
                        path: 'terms-calculator?search=:search&taskId=:taskId',
                        component: KeywordsAnalysisComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PAID_LICENSE] }
                    },
                    {
                        path: 'terms-calculator-asins',
                        component: KeywordAnalysisAsinsComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PAID_LICENSE] }
                    },
                    {
                        path: 'product-research',
                        component: ProductResearchComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PAID_LICENSE] }
                    },
                    {
                        path: 'product-research-niche',
                        component: ProductResearchNicheComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PAID_LICENSE] }
                    },
                    {
                        path: 'product-launcher',
                        component: ProductLauncherComponent,
                        canActivate: [AppAuthGuard],
                        data: { roles: [Roles.PRODUCT_LAUNCHER] }
                    }
                ]
            },
            {
                path: '**',
                component: PageNotFoundComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [AppAuthGuard]
})
export class AppRoutingModule { }
