import { Component, OnInit, Output, HostListener, EventEmitter } from '@angular/core';
import { fade } from '../../../../app/animations/fade';

@Component({
    selector: 'app-pop-under-constructions',
    templateUrl: './pop-under-constructions.component.html',
    styleUrls: ['./pop-under-constructions.component.scss'],
    animations: [fade]
})
export class PopUnderConstructionsComponent implements OnInit {
    @Output() closePopupWaitingClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.closePopupWaiting();
        }
    }

    ngOnInit(): void {}

    public closePopupWaiting(): void {
        this.closePopupWaitingClosed.emit(false);
    }
}
