import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';



@Component({
  host: {
    '(document:click)': 'onClick($event)'
  },
  selector: 'app-generic-dropdown',
  templateUrl: './generic-dropdown.component.html',
  styleUrls: ['./generic-dropdown.component.scss']
})
export class GenericDropdownComponent implements OnInit {

  @Input() dropdownData: { key: string, value: any }[];
  @Input() imgIcon: string;
  @Input() isValidate?: boolean;

  @Input() emptyInput: boolean;
  @Input() itemSelected: { key: string, value: any };

  @Output() choosenItemEmit: EventEmitter<{ key: string, value: any }> = new EventEmitter<{ key: string, value: any }>();
  public choosenItem: { key: string, value: any };
  public isOpen: boolean;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.choosenItem = this.itemSelected ? this.itemSelected : this.emptyInput ? null : this.dropdownData[0];
  }

  public getChoosenData(choosenItem: { key: string, value: string }): void {
    this.choosenItem = choosenItem;
    this.choosenItemEmit.emit(this.choosenItem);
    this.isOpen = false;
  }


  public onClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) // or some similar check
      this.isOpen = false;
  }
}
