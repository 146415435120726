import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-simple-bar-chart',
  templateUrl: './simple-bar-chart.component.html',
  styleUrls: ['./simple-bar-chart.component.scss']
})
export class SimpleBarChartComponent implements OnInit {

  public maxVol: number;
  @Input() dataSimpleBarChart$: Observable<{ timestamp: string, searchVol: number }[]>;
  constructor() { }

  ngOnInit() {
    this.dataSimpleBarChart$.subscribe((data) => {
      if (data) {
        const searchVolArr: number[] = data.map(item => item.searchVol);
        this.maxVol = Math.max(...searchVolArr);
      }
    })
  }

}
