import KeywordResearchTaskResultsDto from "../../dtos/keywordResearchTaskResultsDto";
import PaginateResponse from "../../dtos/paginateResponse";
import GetKeywordResponse from "../../dtos/getKeywordResponse";
import GetWordFrequencyResponse from "../../dtos/GetWordFrequencyResponse";
import TopProductResponse from "../../dtos/TopProductResponse";
import GetBestKeywordResponse from "../../dtos/GetBestKeywordResponse";
import GetProductResearchResponse from "../../dtos/GetProductResearchResponse";

export interface KeywordAnalysisState {
    keywords: GetKeywordResponse;
    keywordsByAsins: GetKeywordResponse;
    paginateResponse: PaginateResponse;
    keywordResearchTaskResultsDtos: KeywordResearchTaskResultsDto[];
    wordFrequency: GetWordFrequencyResponse[];
    topProducts: TopProductResponse[];
    bestKeyWord: GetBestKeywordResponse;
    productResearch: GetProductResearchResponse;
    productResearchNiche: GetProductResearchResponse;
}

export const initialKeywordAnalysisState: KeywordAnalysisState = {
    paginateResponse: undefined,
    keywordResearchTaskResultsDtos: undefined,
    keywords: undefined,
    keywordsByAsins: undefined,
    wordFrequency: undefined,
    topProducts: undefined,
    bestKeyWord: undefined,
    productResearch: undefined,
    productResearchNiche: undefined
};
