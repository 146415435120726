import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-wodson-data-reviews',
    templateUrl: './wodson-data-reviews.component.html',
    styleUrls: ['./wodson-data-reviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WodsonDataReviewsComponent implements OnInit {
    public isPositive: boolean = true;
    @Input() reviewWodson$: Observable<any>;
    constructor() {}

    ngOnInit(): void {}
}
