import { Store } from "@ngrx/store";
import * as qs from 'qs';
import { timeout } from 'rxjs/operators';
import { GetBestKeywordAction, GetKeywordAnalysisAction, GetKeywordAnalysisByAsinsAction, GetTopProductsAction, GetWordFrequencyAction } from "../../store/actions/task.actions";
import { KeywordAnalysisState } from "../../store/state/keywordAnalysis.state";
import AsyncResponse from "../../dtos/AsyncResponse";
import { MarketPlaceService } from "../MarketPlaceService";
import KeywordResearchTaskResultsDto from "../../dtos/keywordResearchTaskResultsDto";
import PaginateResponse from "../../dtos/paginateResponse";
import TaskHandler from "./TaskHandler";
import GetKeywordResponse from "../../dtos/getKeywordResponse";
import GetKeywordsFilter from "../../dtos/GetKeywordsFilter";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import GetWordFrequencyRequest from "../../dtos/GetWordFrequencyRequest";
import { StatusCodes } from "http-status-codes";
import GetTopProductsRequest from "../../dtos/GetTopProductsRequest";
import GetBestKeywordRequest from "../../dtos/GetBestKeywordRequest";
import PageType from "../../enums/PageType";

export default class GetKeywordHandler implements TaskHandler {

    private taskId: number;
    private keywords: string[] = [];

    public constructor(private pageType: PageType, private marketPlaceService: MarketPlaceService, private store: Store<KeywordAnalysisState>,
        private getKeywordsFilter: GetKeywordsFilter, private httpClient: HttpClient) {
        this.taskId = getKeywordsFilter.taskId;
        this.keywords = getKeywordsFilter.keyword;
    }

    public async process(): Promise<void> {

        //call get keyword
        await this.getKeywords();
        this.getWordFrequency();
        this.getTopProducts();
        this.getBestKeyword();
    }

    private async getKeywords(): Promise<void> {
        const getKeywordResponse: GetKeywordResponse = await this.getKeyword(this.marketPlaceService);
        //After it need to update the store
        if (this.pageType === PageType.WordMapper)
            this.store.dispatch(new GetKeywordAnalysisAction(getKeywordResponse));

        if (this.pageType === PageType.WordMapperByAsins)
            this.store.dispatch(new GetKeywordAnalysisByAsinsAction(getKeywordResponse));
    }

    public getTopProducts(): void {
        const getTopProductsRequest: GetTopProductsRequest = new GetTopProductsRequest({ taskId: this.taskId });
        const url = `${environment.getToProductsUrl}?${qs.stringify(getTopProductsRequest)}`;
        this.httpClient.get(url).pipe(timeout(environment.timeout)).subscribe((response: AsyncResponse) => {
            if (response.httpStatus == StatusCodes.OK) {
                this.store.dispatch(new GetTopProductsAction(response.data));
            } else {
                console.log('Yair to handle');
            }
        }, error => {
            console.log('Yair to handle');
        });
    }

    public getWordFrequency(): void {
        const getWordFrequencyRequest: GetWordFrequencyRequest = new GetWordFrequencyRequest({ taskId: this.taskId });
        const url = `${environment.getWordFrequencyUrl}?${qs.stringify(getWordFrequencyRequest)}`;
        this.httpClient.get(url).pipe(timeout(environment.timeout)).subscribe((response: AsyncResponse) => {
            if (response.httpStatus == StatusCodes.OK) {
                this.store.dispatch(new GetWordFrequencyAction(response.data));
            } else {
                console.log('Yair to handle');
            }
        }, error => {
            console.log('Yair to handle');
        });
    }

    public getBestKeyword() {
        const getBestKeywordRequest: GetBestKeywordRequest = new GetBestKeywordRequest({ taskId: this.taskId });
        const url = `${environment.getBestKeywordUrl}?${qs.stringify(getBestKeywordRequest)}`;
        this.httpClient.get(url).pipe(timeout(environment.timeout)).subscribe((response: AsyncResponse) => {
            if (response.httpStatus == StatusCodes.OK) {
                this.store.dispatch(new GetBestKeywordAction(response.data));
            } else {
                console.log('Yair to handle');
            }
        }, error => {
            console.log('Yair to handle');
        });
    }

    public async getKeyword(marketPlaceService: MarketPlaceService): Promise<GetKeywordResponse> {
        let response = await marketPlaceService.getKeyword(this.getKeywordsFilter).toPromise();
        let getKeywordResponse: GetKeywordResponse = new GetKeywordResponse();

        //Split response by newline
        response = response.split(/\r?\n/)

        if (response && response.length > 0) {

            let keywordResearchTaskResultsDtos: KeywordResearchTaskResultsDto[] = [];

            let getKeywordsPaginateResponse: PaginateResponse = JSON.parse(response[0]);
            getKeywordResponse.paginateResponse = getKeywordsPaginateResponse.data;

            for (let index = 1; index < ((response.length) - 1); index++) {
                let keywordResearchTaskResultsDto: KeywordResearchTaskResultsDto = JSON.parse(response[index]);
                keywordResearchTaskResultsDtos.push(keywordResearchTaskResultsDto);
            }

            getKeywordResponse.keywordResearchTaskResultsDtos = keywordResearchTaskResultsDtos;
        }

        return getKeywordResponse;
    }

}