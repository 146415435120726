import { Observable, Subscription } from 'rxjs';
import { HandlePopupsService } from './../../../services/handle-popups.service';
import { fade } from './../../../animations/fade';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';

@Component({
    selector: 'cta-member-popup',
    templateUrl: './cta-member-popup.component.html',
    styleUrls: ['./cta-member-popup.component.scss'],
    animations: [fade]
})
export class CtaMemberPopupComponent implements OnInit {
    public watingPopDelay: boolean = false;
    public handlePopup$: Observable<boolean>;
    constructor(private handlePopup: HandlePopupsService) {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.handlePopup.setCtaMemberPopupStatus(false);
        }
    }

    ngOnInit(): void {
        this.handlePopup$ = this.handlePopup.ctaMemberPopupSubject;
    }

    public closePopupWaiting(): void {
        this.handlePopup.setCtaMemberPopupStatus(false);
    }
}
