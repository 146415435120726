import { Component, OnInit } from '@angular/core';
import { ProductResearchNicheService } from '../../services/product-research-niche.service';
import { environment } from '../../../environments/environment';
import GetProductResearchProductFilter from '../../dtos/GetProductResearchProductFilter';
import GetProductResearchHandler from '../../services/handler/GetProductResearchHandler';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import { HttpClient } from '@angular/common/http';
import { KeywordAnalysisState } from '../../store/state/keywordAnalysis.state';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { productResearchNicheSelector, productResearchSelector } from '../../store/selectors/keywords.selector';
import GetProductResearchResponse from '../../dtos/GetProductResearchResponse';
import ProductResearchResponseDto from '../../dtos/ProductResearchResponseDto';
import PaginateResponse from '../../dtos/paginateResponse';
import { ToastrService } from '../../services/toastr.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { PollingService } from '../../services/polling.service';
import { PageType } from '../../enums/PageType';
import { fade, fadeOut } from '../../../app/animations/fade';
import { slideUpDown } from '../../../app/animations/slides';
import { TaskType } from '../../enums/TaskType';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-research-niche',
  templateUrl: './product-research-niche.component.html',
  styleUrls: ['./product-research-niche.component.scss'],
  animations: [fade, fadeOut, slideUpDown]

})
export class ProductResearchNicheComponent implements OnInit {
  private currentTaskId: number;
  private static readonly FIRST_PAGE: number = 1;
  public getProductResearchProductFilter: GetProductResearchProductFilter = new GetProductResearchProductFilter();
  public productResearchNiche$: Subscription;
  public productResearchResponseDtos: ProductResearchResponseDto[] = [];
  public productResearchPaginateResponse: PaginateResponse;
  public openMonthlyRevenue: boolean = false;
  public openPrice: boolean = false;
  public openReviewCount: boolean = false;
  public openReviewRating: boolean = false;
  public openTimeOnAmazonMonths: boolean = false;
  public openBsr: boolean = false;
  public openAsins: boolean = false;
  public openCategories: boolean = false;
  public openTierSize: boolean = false;
  public openTerms: boolean = false;
  public asins: string;
  public isVolumeAsc: boolean = true;
  public isDesc: boolean = true;
  public column: string = 'asin';
  public direction: number = 1;
  public searchText: string;

  public categoriesList: string[];

  public IsInitialed: boolean = true;

  // more filters toggle
  public isMoreFilters: boolean = false;

  // mockdata
  public mainDetails: any = [{ value: 45, description: 'Last Mo' }, { value: -10, description: 'Last Yr' }, { value: 102, description: 'Reviews Last Mo' }]

  // stars for rating
  public starGood: string = 'assets/images/star-good.svg';
  public starBad: string = 'assets/images/star-bad.svg';
  public starSemi: string = 'assets/images/star-semi.svg';
  public stars: string[];


  constructor(private toastrService: ToastrService, private pollingService: PollingService, private marketPlaceService: MarketPlaceService, private httpClient: HttpClient, private store: Store<KeywordAnalysisState>, public progressBarService: ProgressBarService, private productResearchNicheService: ProductResearchNicheService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.categoriesList = ['video games', 'toys & games', 'tools & home improvement', 'sports & outdoors', 'software', 'pet supplies', 'patio lawn & garden', 'patio, lawn & garden', 'office products',
      'kitchen & dining',
      'industrial & scientific',
      'home & kitchen',
      'health & household',
      'grocery & gourmet food',
      'electronics',
      'clothing shoes & jewelry',
      'clothing, shoes & jewelry',
      'cell phones & accessories',
      'cell phones',
      'books',
      'beauty & personal care',
      'baby',
      'automotive',
      'arts crafts & sewing',
      'arts, crafts & sewing',
      'musical instruments',
      'computers & accessories',
      'camera & photo',
    ];
    this.productResearchNiche$ = this.store.pipe(select(productResearchNicheSelector)).subscribe((data: GetProductResearchResponse) => {
      if (data) {
        this.progressBarService.removeProgressBar();
        this.productResearchPaginateResponse = data.paginateResponse;
        this.productResearchResponseDtos = data.productResearchResponseDtos;
      }
    });

    const searchText = this.route.snapshot.queryParamMap.get('search');
    if (searchText) {
      this.searchText = searchText;
      this.searchKeyword(this.searchText);
    }
  }

  public makeStartsByStarsRate(stars: string): string[] {
    if (stars) {
      stars = stars.toString();
      this.stars = [];
      if (!/^(\.)$/.test(stars)) {
        stars = stars + '.0';
      }
      const [int, dec] = stars.split('.');
      for (let i: number = 0; i < 5; i++) {
        if (i < parseInt(int, 10)) {
          this.stars.push('good');
        } else if (parseInt(dec, 10) > 0 && i === parseInt(int, 10)) {
          this.stars.push('semi');
        } else {
          this.stars.push('bad');
        }
      }
      return this.stars;
    }
  }

  public searchKeyword(keyword: string): void {
    if (keyword) {
      this.IsInitialed = false;
      this.progressBarService.setProgressBar();
      this.productResearchNicheService.searchKeyword(keyword).subscribe(
        (response) => {
          this.currentTaskId = response.taskId;
          this.pollingService.setLatestProductResearchTaskId(response.taskId);
          this.pollingService.getStatus(response.taskId, TaskType.GET_PRODUCT_RESEARCH_NICHE);
        }, err => {
          console.log(err);
          console.log('YAIR for you to handle error')
        })
    }
  }

  private callProductResearchApi(taskId: number, page: number) {
    this.getProductResearchProductFilter.page = page;
    this.getProductResearchProductFilter.taskId = taskId;
    new GetProductResearchHandler(PageType.ProductResearchNichePage, this.marketPlaceService, this.getProductResearchProductFilter, this.httpClient, this.store, this.pollingService).process();
  }

  public callProductResearchApiWithPage(page: number) {
    this.callProductResearchApi(this.currentTaskId, page);
  }


  public ngOnDestroy(): void {
    this.productResearchNiche$.unsubscribe();
  }

  public sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  };

  public sortByVolume() {
    this.isVolumeAsc = !this.isVolumeAsc;
    this.isDesc = this.isVolumeAsc;
    this.column = 'volume';
    this.direction = this.isDesc ? 1 : -1;
    this.getProductResearchProductFilter.sortVolume(this.isVolumeAsc);
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(this.currentTaskId, ProductResearchNicheComponent.FIRST_PAGE);
  }
  public toggleMoreFilters(): void {
    this.isMoreFilters = !this.isMoreFilters;
  }
  public applyFilter() {
    let errorMessage = this.getProductResearchProductFilter.checkIsValid1(this);
    if (errorMessage.length > 0) {
      this.toastrService.showError(errorMessage);
      return;
    }
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(this.currentTaskId, ProductResearchNicheComponent.FIRST_PAGE);
  }

  public clearFilter() {
    this.getProductResearchProductFilter.clear1(this);
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(this.currentTaskId, ProductResearchNicheComponent.FIRST_PAGE);
  }

  public getReportFromCompetittorProduct(asin: string): void {
    const url: string = `${environment.bloooomUrl}/#/dashboard/report?asin=${asin}`;
    window.open(url, '_blank');
  }
}
