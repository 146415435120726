import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import TopProductResponse from '../../dtos/TopProductResponse';

@Component({
  selector: 'app-small-product-box',
  templateUrl: './small-product-box.component.html',
  styleUrls: ['./small-product-box.component.scss']
})
export class SmallProductBoxComponent {

  @Input() data: TopProductResponse = null;

  getReportFromCompetittorProduct(asin: string): void {
    const url: string = `${environment.bloooomUrl}/#/dashboard/report?asin=${asin}`;
    window.open(url, '_blank');
  }
}
