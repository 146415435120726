import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { fade, fadeOut } from '../../../app/animations/fade';
import { IMarketData, RelatedProductsServiceService } from '../../../app/services/related-products-service.service';

@Component({
    selector: 'app-sales-profits',
    templateUrl: './sales-profits.component.html',
    styleUrls: ['./sales-profits.component.scss'],
    animations: [fade, fadeOut]
})
export class SalesProfitsComponent implements OnInit {
    public headerChartBars: any = {
        title: 'Number of Reviews of the Related Products',
        content:
            'This chart shows the how much reviews the related products have. The highest bar shows how much reviews most of the related products have. Touch on a bars to get more informations..'
    };
    public getBarChartLabels: string[] = ['Sales Volume', 'Last Months(12 max)'];

    public marketData$: Observable<IMarketData>;
    constructor(private marketData: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.marketData$ = this.marketData.marketDataSubject;
    }
}
