export interface ILogosPartners {
    image: string;
    name: string;
}

export interface IVideoSlider {
    image: string;
    title: string;
    video: string;
}

export interface IBenefitsSlider {
    title: string;
    content: string;
    image: string;
}
export interface ICardsData {
    title: string;
    content: string;
    image: string;
}
export interface IOverviewReviws {
    asin: string;
    reviewrName: string;
    reviewDate: string;
    ratingValue: number;
    reviewText: string;
    url: string;
    reviewTitle: string;
    verifiedOrder: boolean;
    languageCode: string;
}

export enum keyOfGetAsin {
    asin = 'asin'
}
