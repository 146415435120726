import Direction from "./Direction";

export default class OrderBy {
    public field: string;
    public direction: Direction;

    constructor(params?: Partial<OrderBy>) {
        if (params) {
            this.field = params.field;
            this.direction = params.direction;
        }
    }
}