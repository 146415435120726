import { Component, OnInit } from '@angular/core';
import { JsScript } from './jsScript';

@Component({
    selector: 'app-login-check',
    templateUrl: './login-check.component.html',
    styleUrls: ['./login-check.component.scss']
})
export class LoginCheckComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        JsScript.init();
    }
}
