import { trigger, transition, animate, animation, style, useAnimation } from '@angular/animations';

// tslint:disable-next-line: typedef
export const slideUpDwonAnimation = animation([style({ height: 0, opacity: 0 }), animate('{{ duration }} {{ easing }}')], {
    params: {
        duration: '.2s',
        easing: 'ease-out'
    }
});


export const slideRightAnimation = animation([style({ right: '-200px', opacity: 0 }), animate('{{ duration }} {{ easing }}')], {
    params: {

        duration: '2s',
        easing: 'ease-out'
    }
});

export const leaveTrans = transition(':leave', animate('.2s ease-out', style({ height: 0, opacity: 0 })));


export const leaveTransSlideRight = transition(':leave', animate('.2s ease-out', style({ right: 100, opacity: 0 })));
// tslint:disable-next-line: typedef

export let slideUpDown = trigger('slideUpDown', [transition(':enter', useAnimation(slideUpDwonAnimation)), transition(':leave', useAnimation(leaveTrans))]);
export let slideRight = trigger('slideRight', [transition(':enter', useAnimation(slideRightAnimation)), transition(':leave', useAnimation(leaveTransSlideRight))]);
