import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthServiceService } from '../../../app/services/auth-service.service';
import { MarketPlaceService } from '../../../app/services/MarketPlaceService';
import BasePayment from '../shared/BasePayment';
@Component({
    selector: 'app-marketplace-card',
    templateUrl: './marketplace-card.component.html',
    styleUrls: ['./marketplace-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceCardComponent extends BasePayment implements OnInit {
    @Input() mentor: any;
    public starGood: string = 'assets/images/star-good.svg';
    public starBad: string = 'assets/images/star-bad.svg';
    public starSemi: string = 'assets/images/star-semi.svg';
    public logoOfBrand: string;
    public stars: string[];

    constructor(marketPlaceService: MarketPlaceService, authService: AuthServiceService, router: Router) {
        super(authService, marketPlaceService, router);
    }

    public ngOnInit(): void { }

    public getImageLogo(name: string): string {
        return name;
    }

    public makeStartsByStarsRate(stars: string): string[] {
        if (stars) {
            stars = stars.toString();
            this.stars = [];
            if (!/^(\.)$/.test(stars)) {
                stars = stars + '.0';
            }
            const [int, dec] = stars.split('.');
            for (let i: number = 0; i < 5; i++) {
                if (i < parseInt(int, 10)) {
                    this.stars.push('good');
                } else if (parseInt(dec, 10) > 0 && i === parseInt(int, 10)) {
                    this.stars.push('semi');
                } else {
                    this.stars.push('bad');
                }
            }
            return this.stars;
        }
    }

    public nevigateToMoreInfo(name: string): void {
        this.router.navigate(['/mentors', name])
    };

    public getLogoFromBrandName(brand: string): string {
        this.logoOfBrand = environment.baseBrandUrl.replace('{{BRAND}}', brand) + '/logo.svg';
        return this.logoOfBrand;
    }
}
