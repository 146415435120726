import { Store } from "@ngrx/store";
import * as qs from 'qs';
import { timeout } from 'rxjs/operators';
import { GetBestKeywordAction, GetKeywordAnalysisAction, GetProductResearchAction, GetProductResearchNicheAction, GetTopProductsAction, GetWordFrequencyAction } from "../../store/actions/task.actions";
import { KeywordAnalysisState } from "../../store/state/keywordAnalysis.state";
import AsyncResponse from "../../dtos/AsyncResponse";
import { MarketPlaceService } from "../MarketPlaceService";
import KeywordResearchTaskResultsDto from "../../dtos/keywordResearchTaskResultsDto";
import PaginateResponse from "../../dtos/paginateResponse";
import TaskHandler from "./TaskHandler";
import GetKeywordResponse from "../../dtos/getKeywordResponse";
import GetKeywordsFilter from "../../dtos/GetKeywordsFilter";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import GetWordFrequencyRequest from "../../dtos/GetWordFrequencyRequest";
import { StatusCodes } from "http-status-codes";
import GetTopProductsRequest from "../../dtos/GetTopProductsRequest";
import GetBestKeywordRequest from "../../dtos/GetBestKeywordRequest";
import GetProductResearchProductFilter from "../../dtos/GetProductResearchProductFilter";
import GetProductResearchResponse from "../../dtos/GetProductResearchResponse";
import ProductResearchResponseDto from "../../dtos/ProductResearchResponseDto";
import { PollingService } from "../polling.service";
import { PageType } from "../../enums/PageType";

export default class GetProductResearchHandler implements TaskHandler {

    private taskId: number;
    private keywords: string[] = [];

    public constructor(private pageType: PageType, private marketPlaceService: MarketPlaceService,
        private getProductResearchProductFilter: GetProductResearchProductFilter, private httpClient: HttpClient, private store: Store<KeywordAnalysisState>, private pollingService: PollingService) {
    }

    public async process(): Promise<void> {
        await this.getProductResearchData();
    }

    private async getProductResearchData(): Promise<void> {
        const getProductResearchResponse: GetProductResearchResponse = await this.getProductResearch(this.marketPlaceService);
        switch (this.pageType) {
            case PageType.ProductResearchPage:
                this.store.dispatch(new GetProductResearchAction(getProductResearchResponse));
                break;
            case PageType.ProductResearchNichePage:
                if (this.isTaskIdMatchLatest(getProductResearchResponse)) {
                    this.store.dispatch(new GetProductResearchNicheAction(getProductResearchResponse));
                }
                break;
        }
    }

    private isTaskIdMatchLatest(getProductResearchResponse: GetProductResearchResponse): boolean {
        return this.pollingService.isProductResearchTaskIdMissing() || this.pollingService.getLatestProductResearchTaskId() === getProductResearchResponse.paginateResponse.taskId;
    }

    public async getProductResearch(marketPlaceService: MarketPlaceService): Promise<GetProductResearchResponse> {
        let response = await marketPlaceService.getProductResearch(this.getProductResearchProductFilter).toPromise();
        let getProductResearchResponse: GetProductResearchResponse = new GetProductResearchResponse();
        response = response.split(/\r?\n/)

        if (response && response.length > 0) {

            let productResearchResponseDtos: ProductResearchResponseDto[] = [];

            let getproductPaginateResponse: PaginateResponse = JSON.parse(response[0]);
            getProductResearchResponse.paginateResponse = getproductPaginateResponse.data;

            for (let index = 1; index < ((response.length) - 1); index++) {
                let ProductResearchResponseDto: ProductResearchResponseDto = JSON.parse(response[index]);
                productResearchResponseDtos.push(ProductResearchResponseDto);
            }

            getProductResearchResponse.productResearchResponseDtos = productResearchResponseDtos;

        }

        return getProductResearchResponse;
    }

}