export enum Roles {
    PUBLIC = 'PUBLIC',
    PAID_LICENSE = 'PAID_LICENSE',
    PRODUCT_LAUNCHER = 'PRODUCT_LAUNCHER'
}

// export enum Months{
// JANUARY = 'January',
// FABRUARY = 'February',
// MATCH = 'March',
// APRIL = 'April',
// MAY = 'May',
// JUNE = 'June',
// JULY = 'July',
// AUGUST = 'August',
// SEPTEMBER = 'September',
// OCTOBER = 'October',
// NOVEMBER = 'November',
// DECEMBER = 'December'
// };
