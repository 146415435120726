import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { keyOfGetAsin } from '../model/ui-interfaces';

@Injectable({
    providedIn: 'root'
})
export class GetSearchedAsinService {
    private searchedAsin: string;

    constructor(private route: ActivatedRoute) { }

    public getSearchedAsin(): string {
        return this.searchedAsin;
    }
    public setSearchedAsin(asin: string): void {
        this.searchedAsin = asin;
    }
}
