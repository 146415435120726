import { AuthServiceService } from './../../services/auth-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-checkout-login-user',
    templateUrl: './checkout-login-user.component.html',
    styleUrls: ['./checkout-login-user.component.scss']
})
export class CheckoutLoginUserComponent implements OnInit {
    constructor(private authService: AuthServiceService) {}

    ngOnInit(): void {}

    public async login(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (!isLoggedIn) {
            await this.authService.login();
        }
    }

    public async signup(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (!isLoggedIn) {
            await this.authService.signUp();
        }
    }
}
