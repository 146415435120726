import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, catchError } from 'rxjs/operators';

import { ReportService } from '../../services/report.service';
import { ReportActionsEnum, GetReportByAsinAction, GetReportByAsinFailureAction, ActionInProgress } from '../actions/report.actions';
import { of, Observable } from 'rxjs';
import { IApplicationState } from '../state/application.state';
import { CheckoutServiceService } from '../../checkout-service.service';

@Injectable()
export class ReportEffects {
    private readonly ONE_REPORT: number = 1;

    @Effect()
    public getReportByAsin$: Observable<any> = this.actions$.pipe(
        ofType<GetReportByAsinAction>(ReportActionsEnum.GET_REPORT_BY_ASIN),
        map((action: GetReportByAsinAction) => {
            try {
                this.reportService.sendGetReportMessage(action.asin);
                return new ActionInProgress();
            } catch (error) {
                console.error(`error sending get report message: ${error}`);
                return of(new GetReportByAsinFailureAction(error, action.asin));
            }
        }),
        catchError((error: any) => {
            console.error(`unexpected error sending get report message: ${error}`);
            return of(new GetReportByAsinFailureAction(error, ''));
        })
    );

    constructor(
        private reportService: ReportService,
        private actions$: Actions,
        private store: Store<IApplicationState>,
        private readonly checkoutService: CheckoutServiceService
    ) {}
}
