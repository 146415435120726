import { trigger, transition, state, animate, animation, style, keyframes, useAnimation } from '@angular/animations';


export const flyInOut = trigger('flyInOut', [
    state('in', style({ opacity: 1, top: '-30px' })),
    transition('void => *', [
        style({ opacity: 0, top: '-20px' }),
        animate(300)
    ]),
    transition('* => void', [
        animate(300, style({ opacity: 0, top: '-20px' }))
    ])
])
