import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
    public bgImageTermsPage: string = 'term-bg-image.png';
    constructor() {}

    ngOnInit(): void {}
}
