import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'maxPipe'
})
export class MaxPipePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            return Math.max(...value);
        }
    }
}
