import { GetBestKeywordAction, GetKeywordAnalysisAction, GetKeywordAnalysisByAsinsAction, GetProductResearchAction, GetProductResearchNicheAction, GetTopProductsAction, GetWordFrequencyAction, TaskActions, TaskActionsEnum } from '../actions/task.actions';
import { IApplicationState } from '../state/application.state';
import { initialKeywordAnalysisState, KeywordAnalysisState } from '../state/keywordAnalysis.state';

export function taskReducers(state: KeywordAnalysisState = initialKeywordAnalysisState, action: TaskActions): KeywordAnalysisState {
    switch (action.type) {
        case TaskActionsEnum.GET_KEYWORDS_ANALYSIS: {
            return handleGetTaskAnalysisAction(action, state);
        }
        case TaskActionsEnum.GET_KEYWORDS_ANALYSIS_BY_ASINS: {
            return handleGetTaskAnalysisByAsinsAction(action, state);
        }
        case TaskActionsEnum.GET_WORD_FREQUENCY: {
            return handleGetWordFrequencyAction(action, state);
        }
        case TaskActionsEnum.GET_TOP_PRODUCT: {
            return handleGetToProductsAction(action, state);
        }
        case TaskActionsEnum.GET_BEST_KEYWORD: {
            return handleBestKeywordAction(action, state);
        }
        case TaskActionsEnum.GET_PRODUCT_RESEARCH: {
            return handleProductResearchdAction(action, state);
        }
        case TaskActionsEnum.GET_PRODUCT_RESEARCH_NICHE: {

            return handleProductResearchNicheAction(action, state);
        }

        default:
            return state;
    }
}

function handleGetToProductsAction(action: GetTopProductsAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        topProducts: action.topProducts
    };
}

function handleGetTaskAnalysisAction(action: GetKeywordAnalysisAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        keywords: action.keywords
    };
}

function handleGetTaskAnalysisByAsinsAction(action: GetKeywordAnalysisByAsinsAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        keywordsByAsins: action.keywordsByAsins
    };
}

function handleGetWordFrequencyAction(action: GetWordFrequencyAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        wordFrequency: action.wordFrequency
    };
}

function handleBestKeywordAction(action: GetBestKeywordAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        bestKeyWord: action.bestKeyWord
    };
}

function handleProductResearchdAction(action: GetProductResearchAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        productResearch: action.productResearch
    };
}



function handleProductResearchNicheAction(action: GetProductResearchNicheAction, state: KeywordAnalysisState): KeywordAnalysisState {
    return {
        ...state,
        productResearchNiche: action.productResearchNiche
    };
}

export const selectKeywordsFromAnalysisState: any = (state: IApplicationState): any => state.keywordAnalysisState.keywords;
export const selectKeywordsByAsinsFromAnalysisState: any = (state: IApplicationState): any => state.keywordAnalysisState.keywordsByAsins;
export const selectMainWordAndTopProductsAnalysisSelector: any = (state: IApplicationState): any => state.keywordAnalysisState.topProducts;
export const selectBestKewordSelector: any = (state: IApplicationState): any => state.keywordAnalysisState.bestKeyWord;
export const selectProductResearchFromAnalysisState: any = (state: IApplicationState): any => state.keywordAnalysisState.productResearch;
export const selectProductResearchNicheFromAnalysisState: any = (state: IApplicationState): any => state.keywordAnalysisState.productResearchNiche;
