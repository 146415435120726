import { Component, Input, OnInit } from '@angular/core';
import { fade } from '../../../app/animations/fade';

@Component({
    selector: 'app-icon-tooltip',
    templateUrl: './icon-tooltip.component.html',
    styleUrls: ['./icon-tooltip.component.scss'],
    animations: [fade]
})
export class IconTooltipComponent implements OnInit {
    @Input() iconForTooltip: { src: string; alt: string };
    public displayTooltip: boolean = false;
    constructor() {}

    ngOnInit(): void {}
    handleTooltip(val: boolean): void {
        this.displayTooltip = val;
    }
}
