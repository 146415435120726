import { fade } from './../../../animations/fade';
import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
    selector: 'pop-waiting-report',
    templateUrl: './pop-waiting-report.component.html',
    styleUrls: ['./pop-waiting-report.component.scss'],
    animations: [fade]
})
export class PopWaitingReportComponent implements OnInit {
    public popDelay: number = 2000;
    @Output() closePopupWaitingClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
    public watingPopDelay: boolean = false;

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.closePopupWaiting();
        }
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.watingPopDelay = true;
        }, this.popDelay);
    }

    public closePopupWaiting(): void {
        this.closePopupWaitingClosed.emit(true);
    }
}
