import { Component, Input, OnInit } from '@angular/core';
import { IVideoSlider } from './../../model/ui-interfaces';

@Component({
    selector: 'video-slider',
    templateUrl: './video-slider.component.html',
    styleUrls: ['./video-slider.component.scss']
})
export class VideoSliderComponent implements OnInit {
    isPlaying: boolean = false;
    @Input() videoSlides: IVideoSlider[];
    isOnlyOneVideoSlide: boolean;

    ngOnInit(): void {
        this.checkVideoSlideArrayLength();
    }

    checkVideoSlideArrayLength(): void {
        if (this.videoSlides.length !== 1) {
            this.isOnlyOneVideoSlide = true;
        } else {
            this.isOnlyOneVideoSlide = false;
        }
    }

    playVideo(): void {
        this.isPlaying = true;
    }
}
