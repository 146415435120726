import { marketAnalysisSelector } from './../store/selectors/market-analysis.selector';
import { isEmpty } from 'lodash';
import { AlibabaProductsDTO, AlibabaProductDetails, PriceRange } from './../model/alibaba';
import { alibabaAllDataSelector } from './../store/selectors/alibaba.selector';
import { keyOfGetAsin } from './../model/ui-interfaces';
import {
    IProductDetails,
    IProductReviewsDTO,
    MarketAnalysisDto,
    ProductParameters,
    IKewordsDTO,
    CustomVariations,
    AggregatedDataOverTime,
    BulletsData,
    ProductDescriptionData,
    TitleIncludesMainKeywords,
    TitleData,
    ImagesData,
    KeywordsSummery,
    ListingResponse
} from './../model/report.interface';
import { RelatedProductsSelector } from './../store/selectors/related-products.selector';
import { IReportState } from './../store/state/report.state';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IRelatedPoductsDTO } from '../model/report.interface';
import { Subscription, Subject, BehaviorSubject, Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ActivatedRoute } from '@angular/router';
import { lengthOfRelatedProductsSelector } from '../store/selectors/status.selector';
import { keywordsSelector, keywordsSummery } from '../store/selectors/keywords.selector';
import { map } from 'rxjs/operators';
import { listingSelector } from '../store/selectors/listing.selector';

export interface IListingOptimization {
    charCountTitle: TitleData;
    imagesCount: ImagesData;
    productDescriptionData?: ProductDescriptionData;
    keywordsInUseCount?: TitleIncludesMainKeywords;
    bulletsCount?: BulletsData;
    imageResolutions?: string;
    asin?: string;
}

export interface IPoductDetailsService {
    productAsin: string;
    selectSeniority: string;
    productTitle: string;
    productPriceRange: string[];
    productPrice: string;
    mainProductImage: string;
    selectProductUrl: string;
}

export class SalesVolumeByPrice {
    constructor(public price: number, public salesVolume: number) {}
}
export interface IDimensionsAndIndexOfRelatedProductsArray {
    allRelatedProductsLength?: number;
    productIndex?: number;
}

export interface IReviewsDetails {
    countReview: string;
    reviews: IProductReviewsDTO[];
    productRating: string;
}
export interface IMarketData {
    productAvgVolumnOverTime?: AggregatedDataOverTime[];
    costRange?: number[];
    avarageVolumn?: number;
    avarageRevenue?: number;
    monthlyRevenue?: number;
    monthlyVolumn?: number;
    avaragePrice?: number;
    priceRange?: number[];
    productPrice?: number;
    productPriceAverage?: number;
}

export interface IAllProductDetails {
    productAsin?: string;
    selectSeniority?: string;
    productTitle?: string;
    productPrice?: number;
    mainProductImage?: string;
    selectProductUrl?: string;
    hasBattery: boolean;
    isSeasonality: boolean;
    isFragile: boolean;
    productDetails?: IProductDetails[];
}
export interface IProductInformation {
    productDimensions: IProductDetails;
    fulfilledBy: string;
    priceShippingInformation: string;
    prime: boolean;
    productMaterial: IProductDetails;
    productHasBattery: IProductDetails;
    itemWeight: string;
    productShippingWeight: IProductDetails;
    productModel: IProductDetails;
    variations: CustomVariations[];
    shippingWeight?: string;
}

@Injectable({
    providedIn: 'root'
})
export class RelatedProductsServiceService {
    allRelatedProducts$: Subscription;
    allAlibabaData$: Subscription;
    allAlibabaData: AlibabaProductsDTO;
    keywordsData$: Observable<IKewordsDTO>;
    marketAnalysis: MarketAnalysisDto;
    marketAnalysis$: Subscription;

    // keywordsSummery: KeywordsSummery;
    keywordsSummery$: Observable<KeywordsSummery>;

    allRelatedProducts: IRelatedPoductsDTO[];
    currentSearchedAsin: string;
    searchedAsinProduct: IRelatedPoductsDTO;
    productIndex: number = 0;
    productPriceRangeGlobal: number[];
    productPrice: number;
    amountOfRelatedPoducts: number;
    amountOfRelatedPoducts$: Subscription;

    listingOptimizationsDataSubject: Subject<ListingResponse> = new BehaviorSubject<ListingResponse>(null);
    listingOptimizationsSub$: Subscription;

    categoriesDataSubject: Subject<string[]> = new BehaviorSubject<string[]>(null);

    private allRelatedProductSubject: Subject<IRelatedPoductsDTO[]> = new BehaviorSubject<IRelatedPoductsDTO[]>([]);
    allRelatedProductsObs$: Observable<any> = this.allRelatedProductSubject.asObservable().pipe(
        map((products: any) => {
            if (products) {
                return products.map((product: any) => {
                    return { price: product.currentPrice, salesVolume: product.currentVolumn };
                });
            }
        })
    );

    reviewsSubject: Subject<IReviewsDetails> = new BehaviorSubject<IReviewsDetails>(null);

    marketAnalysisSubject: Subject<ProductParameters> = new BehaviorSubject<ProductParameters>(null);

    keywordsSummerySubject: Subject<KeywordsSummery> = new BehaviorSubject<KeywordsSummery>(null);

    allAlibabaDataSubject: Subject<AlibabaProductsDTO> = new BehaviorSubject<AlibabaProductsDTO>(null);

    productInformationSubject: Subject<IProductInformation> = new BehaviorSubject<IProductInformation>(null);

    marketDataSubject: Subject<IMarketData> = new BehaviorSubject<IMarketData>(null);

    detailsProductSubject: Subject<IAllProductDetails> = new BehaviorSubject<IAllProductDetails>(null);

    indexAndArrLength: Subject<IDimensionsAndIndexOfRelatedProductsArray> = new BehaviorSubject<IDimensionsAndIndexOfRelatedProductsArray>({});

    bloooomScoreSubject: Subject<number> = new BehaviorSubject<number>(null);
    getSalesVolumeByPriceSubject: Subject<SalesVolumeByPrice> = new BehaviorSubject<SalesVolumeByPrice>(null);

    constructor(private route: ActivatedRoute, private Store: Store<IReportState>) {
        this.route.queryParamMap.subscribe((params: any) => {
            this.currentSearchedAsin = params.get(keyOfGetAsin.asin);
        });

        this.listingOptimizationsSub$ = this.Store.pipe(select(listingSelector)).subscribe((listingData: ListingResponse) => {
            if (listingData) {
                this.listingOptimizationsDataSubject.next(listingData);
            } else {
                this.listingOptimizationsDataSubject.next(null);
            }
        });

        this.marketAnalysis$ = this.Store.pipe(select(marketAnalysisSelector)).subscribe((marketAnalysisData: MarketAnalysisDto) => {
            if (marketAnalysisData) {
                this.marketAnalysis = marketAnalysisData;
                this.marketAnalysisSubject.next(this.marketAnalysis);
            } else {
                this.marketAnalysisSubject.next(null);
            }
        });

        this.keywordsData$ = this.Store.pipe(select(keywordsSelector));

        this.keywordsSummery$ = this.Store.pipe(select(keywordsSummery));

        this.allAlibabaData$ = this.Store.pipe(select(alibabaAllDataSelector)).subscribe((alibabaData: AlibabaProductsDTO) => {
            if (alibabaData) {
                this.allAlibabaData = alibabaData;
                this.allAlibabaDataSubject.next(alibabaData);
            } else {
                this.allAlibabaDataSubject.next(null);
            }
        });

        this.amountOfRelatedPoducts$ = this.Store.pipe(select(lengthOfRelatedProductsSelector)).subscribe((numberOfRelatedProduct: number) => {
            if (numberOfRelatedProduct) {
                this.amountOfRelatedPoducts = numberOfRelatedProduct;
            }
        });

        // get all related products
        this.allRelatedProducts$ = this.Store.pipe(select(RelatedProductsSelector)).subscribe((RelatedProducts: IRelatedPoductsDTO[]) => {
            // if (RelatedProducts) {

            this.allRelatedProducts = RelatedProducts;
            // }
            // get the searched product and put it at the begining of the array

            if (!isEmpty(this.allRelatedProducts)) {
                this.allRelatedProducts = this.findSearchedProductByAsinAndAddToStartOfArray(this.allRelatedProducts);

                this.getDataToAllComponents(this.productIndex);

                this.indexAndArrLength.next({ allRelatedProductsLength: this.amountOfRelatedPoducts, productIndex: this.productIndex });
            } else {
                this.getDataToAllComponents(this.productIndex);

                this.indexAndArrLength.next({ allRelatedProductsLength: 0, productIndex: 0 });

                this.productIndex = 0;
            }

            this.allRelatedProductSubject.next(this.allRelatedProducts);
        });
    }

    getDataToAllComponents(productIndex: number): void {
        this.allRelatedProducts.shift();
        this.getReviews(productIndex);
        this.getProductDetails(productIndex);
        this.getMarketData(productIndex);
        this.getProductInformation(productIndex);
        this.getBoooomScoreByProduct();
        this.getCategories(productIndex);
        // this.getListingOptimizations();
    }

    findSearchedProductByAsinAndAddToStartOfArray(allRelatedProducts: IRelatedPoductsDTO[]): IRelatedPoductsDTO[] {
        this.searchedAsinProduct = allRelatedProducts.find((product: IRelatedPoductsDTO) => product.asin === this.currentSearchedAsin);
        allRelatedProducts = allRelatedProducts.filter((product: IRelatedPoductsDTO) => product.asin !== this.currentSearchedAsin);
        allRelatedProducts.unshift(this.searchedAsinProduct);
        return allRelatedProducts;
    }

    checkingProductSpecs(productIndex: number, stringOptions: string[]): IProductDetails {
        if (this.allRelatedProducts[productIndex]) {
            const productDimensions: IProductDetails = this.allRelatedProducts[productIndex].productDetails.find((info: IProductDetails) => {
                for (const stringOption of stringOptions) {
                    return info.name.toLocaleLowerCase().indexOf(stringOption) >= 0;
                }
            });
    
            if (productDimensions) {
                return productDimensions;
            } else {
                return { name: '', value: '----' };
            }
        }
    }

    // register to data for reviews by index
    getCategories(productIndex: number): void {
        if (!isEmpty(this.allRelatedProducts)) {
            const categorie: string[] = this.allRelatedProducts[productIndex].categories;
            this.categoriesDataSubject.next(categorie);
        } else {
            this.categoriesDataSubject.next(null);
        }
    }

    // getListingOptimizations(): void {

    // if (!isEmpty(this.allRelatedProducts)) {
    //     const charCountTitle: TitleData = this.allRelatedProducts[productIndex].titleData;
    //     const imagesCount: ImagesData = this.allRelatedProducts[productIndex].imagesData;
    //     const charCountDescription: ProductDescriptionData = this.allRelatedProducts[productIndex].productDescriptionData;
    //     const keywordsInUseCount: TitleIncludesMainKeywords = this.allRelatedProducts[productIndex].isTitleIncludesMainKeywords;
    //     const bulletsCount: BulletsData = this.allRelatedProducts[productIndex].bulletsData;
    //     const productAsin: string = this.allRelatedProducts[productIndex].asin;

    //     const listingOptimization: IListingOptimization = {
    //         charCountTitle: charCountTitle,
    //         imagesCount: imagesCount,
    //         productDescriptionData: charCountDescription,
    //         keywordsInUseCount: keywordsInUseCount,
    //         bulletsCount: bulletsCount,
    //         asin: productAsin
    //     };
    //     this.listingOptimizationsDataSubject.next(listingOptimization);
    // } else {
    //     this.listingOptimizationsDataSubject.next(null);
    // }
    // }

    getProductInformation(productIndex: number): void {
        if (!isEmpty(this.allRelatedProducts) && this.allRelatedProducts[productIndex]) {
            const stringOptionsOfproductDimensions: string[] = ['dimensions', 'sizes', 'size', 'dimension'];
            const stringOptionsOfproductShippingWeight: string[] = ['shipping weight'];
            const stringOptionsOfproductModel: string[] = ['model'];
            const stringOptionsOfproductHasBattery: string[] = ['Batteries', 'battery'];
            const stringOptionsOfproductMaterial: string[] = ['material', 'materials'];

            const productDimensions: IProductDetails = this.checkingProductSpecs(productIndex, stringOptionsOfproductDimensions);
            const productShippingWeight: IProductDetails = this.checkingProductSpecs(productIndex, stringOptionsOfproductShippingWeight);
            const productModel: IProductDetails = this.checkingProductSpecs(productIndex, stringOptionsOfproductModel);
            const productHasBattery: IProductDetails = this.checkingProductSpecs(productIndex, stringOptionsOfproductHasBattery);
            const productMaterial: IProductDetails = this.checkingProductSpecs(productIndex, stringOptionsOfproductMaterial);
            const fulfilledBy: string = this.allRelatedProducts[productIndex].fulfilledBy;
            const priceShippingInformation: string = this.allRelatedProducts[productIndex].priceShippingInformation;
            const prime: boolean = this.allRelatedProducts[productIndex].prime;
            const itemWeight: string = this.allRelatedProducts[productIndex].itemWeight;
            const variations: CustomVariations[] = this.allRelatedProducts[productIndex].variations;
            const shippingWeight: string = this.allRelatedProducts[productIndex].shippingWeight
                ? this.allRelatedProducts[productIndex].shippingWeight.slice(0, this.allRelatedProducts[productIndex].shippingWeight.indexOf('('))
                : this.allRelatedProducts[productIndex].itemWeight;
            const productInformation: IProductInformation = {
                shippingWeight,
                productMaterial,
                productHasBattery,
                productModel,
                productDimensions,
                fulfilledBy,
                priceShippingInformation,
                prime,
                itemWeight,
                variations,
                productShippingWeight
            };
            this.productInformationSubject.next(productInformation);
        } else {
            this.productInformationSubject.next(null);
        }
    }

    getMarketData(productIndex: number): void {
        if (!isEmpty(this.allRelatedProducts) && this.allRelatedProducts[productIndex]) {
            const productAvgVolumnOverTime: AggregatedDataOverTime[] = this.allRelatedProducts[productIndex].aggregatedDataOverTime;

            const monthlyRevenue: number = this.allRelatedProducts[productIndex].currentRevenue;
            const monthlyVolumn: number = this.allRelatedProducts[productIndex].currentVolumn;
            const avaragePrice: number = this.allRelatedProducts[productIndex].avaragePrice;
            const avarageVolumn: number = this.allRelatedProducts[productIndex].avarageVolumn;
            const avarageRevenue: number = this.allRelatedProducts[productIndex].avarageRevenue;

            const costsRange: any = (): number[] => {
                if (this.allAlibabaData && !isEmpty(this.allAlibabaData.productDetails)) {
                    const allPricesAndPieces: any[][] = this.allAlibabaData.productDetails.map((item: AlibabaProductDetails) => {
                        return item.productInfo.prices.map((item: any) => item.price);
                    });
                    const allPricesAndPiecesArr: string[][] = [];
                    allPricesAndPieces.forEach((price: any[]) => (price[0] ? allPricesAndPiecesArr.push(...price) : null));
                    const handleDashMap: number[][] = allPricesAndPiecesArr.map((price: any) => {
                        const handleDash: number[] = [];
                        if (price.indexOf('-') < 0) {
                            handleDash.push(parseFloat(price.slice(1)));
                        } else {
                            price.split('- ').forEach((price: string) => {
                                handleDash.push(parseFloat(price.slice(1)));
                            });
                        }
                        return handleDash;
                    });

                    const arrOfPricesInNumbers: number[] = [];
                    handleDashMap.forEach((price: number[]) => arrOfPricesInNumbers.push(...price));
                    return arrOfPricesInNumbers;
                }
            };
            const marketData: IMarketData = {
                productAvgVolumnOverTime: productAvgVolumnOverTime,
                monthlyRevenue,
                monthlyVolumn,
                avaragePrice,
                avarageVolumn,
                avarageRevenue,
                costRange: costsRange(),
                priceRange: this.productPriceRangeGlobal,
                productPrice: this.productPrice
                // productPriceAverage: 0
            };
            this.marketDataSubject.next(marketData);
        } else {
            this.marketDataSubject.next(null);
        }
    }

    // register to data for reviews by index
    getReviews(productIndex: number): void {
        if (!isEmpty(this.allRelatedProducts) && this.allRelatedProducts[productIndex]) {
            const reviews: IProductReviewsDTO[] = this.allRelatedProducts[productIndex].reviews;
            const countReview: string = this.allRelatedProducts[productIndex].countReview.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
            const productRating: string = this.allRelatedProducts[productIndex].productRating;
            const reviewsDetails: IReviewsDetails = { countReview, reviews, productRating };
            this.reviewsSubject.next(reviewsDetails);
        } else {
            this.reviewsSubject.next(null);
        }
    }
    // register to data for product details by index
    getProductDetails(productIndex: number): void {
        if (!isEmpty(this.allRelatedProducts) && this.allRelatedProducts[productIndex]) {
            const theProduct: IRelatedPoductsDTO = this.allRelatedProducts[productIndex];
            const productAsin: string = theProduct.asin;
            const selectSeniority: string = theProduct.seniority;
            const productTitle: string = theProduct.productTitle;
            const productPrice: number = theProduct.currentPrice;
            const hasBattery: boolean = theProduct.hasBattery;
            const isFragile: boolean = theProduct.isFragile;
            const isSeasonality: boolean = theProduct.isSeasonality;
            const productDetails: IProductDetails[] = theProduct.productDetails;
            // add to global var for serving other components
            this.productPrice = productPrice;

            // add to global var for serving other components

            const mainProductImage: string = theProduct.mainImage ? theProduct.mainImage.imageUrl : theProduct.imageUrlList[0];
            const selectProductUrl: string = theProduct.productDetails.find((detail: any) => detail.name === 'producturl').value;
            const allProductDetails: IAllProductDetails = {
                productAsin,
                selectSeniority,
                productTitle,
                productPrice,
                mainProductImage,
                selectProductUrl,
                hasBattery,
                isSeasonality,
                isFragile,
                productDetails
            };

            // products array legth and range prices

            this.detailsProductSubject.next(allProductDetails);
        } else {
            this.detailsProductSubject.next(null);
        }
    }

    getBoooomScoreByProduct(): void {
        this.Store.pipe(select(marketAnalysisSelector)).subscribe((marketAnalysisData: MarketAnalysisDto) => {
            if (marketAnalysisData) {
                this.marketAnalysis = marketAnalysisData;
                this.bloooomScoreSubject.next(this.marketAnalysis.score);
            } else {
                this.bloooomScoreSubject.next(null);
            }
        });
    }

    slideToNextProduct(productIndex: number): void {
        this.productIndex = this.productIndex + productIndex;
        const checkLengthOfRelatedProduct: any = (): boolean => {
            return this.productIndex >= 0 && this.productIndex < this.allRelatedProducts.length;
        };

        if (checkLengthOfRelatedProduct()) {
            this.getReviews(this.productIndex);
            this.getProductDetails(this.productIndex);
            this.getMarketData(this.productIndex);
            this.getProductInformation(this.productIndex);
            // this.getListingOptimizations(this.productIndex);
            this.getBoooomScoreByProduct();
        } else {
            if (this.productIndex === this.allRelatedProducts.length) {
                this.productIndex = this.allRelatedProducts.length - 1;
            } else {
                this.productIndex = 0;
            }
        }
    }

    // utils

    isNumberIsMissingOrZero(value: number): boolean {
        return isNullOrUndefined(value) || isNaN(value) || value === 0;
    }

    isProductPriceValid(price: number): boolean {
        return !this.isNumberIsMissingOrZero(price);
    }
}
