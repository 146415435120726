import { Router } from "@angular/router";
import * as qs from "qs";
import { AuthServiceService } from "../../../app/services/auth-service.service";
import { MarketPlaceService } from "../../../app/services/MarketPlaceService";

export default class BasePayment {
    constructor(protected authService: AuthServiceService, protected marketPlaceService: MarketPlaceService, protected router: Router) { }

    public async toPricingPage(brand: string): Promise<void> {
        const isUserLoggedIn = await this.authService.isUserLoggedIn();
        if (isUserLoggedIn) {
            this.authService.getToken().then((token: string) => {
                this.marketPlaceService.setToken(token);
            });
        }
        this.marketPlaceService.setBrandName(brand);
        this.router.navigateByUrl(`/pricing?${qs.stringify({ brand })}`);
    }
}