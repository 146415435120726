import { isNullOrUndefined } from 'util';
import { FbaDto, IRelatedPoductsDTO, IReportDTO } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

export const fbaSelector: any = createSelector(
    selectReportsState,
    (state: IReportDTO, props: any): FbaDto => {
        if (state) {
            if (!isNullOrUndefined(state.relatedProducts)) {
                const currentRelatedProduct: IRelatedPoductsDTO = state.relatedProducts.find((relatedProduct: IRelatedPoductsDTO) => relatedProduct.asin === props.asin);
                if (currentRelatedProduct) {
                    const fba: FbaDto = currentRelatedProduct.fba;
                    return fba;
                }
            }
            return null;
        }
    }
);

fbaSelector.release();
