import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ReportService } from '../../../app/services/report.service';
import { fade, fadeOut } from '../../../app/animations/fade';
import { GetProductData } from '../../../app/model/report.interface';

@Component({
  selector: 'app-preview-box',
  templateUrl: './preview-box.component.html',
  styleUrls: ['./preview-box.component.scss'],
  animations: [fade, fadeOut]
})
export class PreviewBoxComponent implements OnInit, OnChanges {


  @Input() campaignData: any;
  @Input() asin: string;
  public isStartToFetchData: boolean;
  public productData: GetProductData;

  constructor(private reportService: ReportService) { }

  ngOnInit(): void {
  }
  ngOnChanges() {
    this.getDataByAsin(this.asin);
  }

  public async getDataByAsin(asin: string): Promise<void> {
    if (asin) {
      this.isStartToFetchData = true;
      try {
        this.productData = await this.reportService.getProductData(asin);
      } catch (err) {
        console.log(err)
      }
    }
  }



}
