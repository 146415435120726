import { isNullOrUndefined } from 'util';
import { IReportDTO, MarketAnalysisDto } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

export const marketAnalysisSelector: any = createSelector(selectReportsState, (state: IReportDTO): MarketAnalysisDto[] => {
    if (state) {
        console.log(state);
        const marketAnalysis: any = state.marketAnalysis;
        return marketAnalysis;
    }
});

marketAnalysisSelector.release();
