import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'product-optimization',
    templateUrl: './product-optimization.component.html',
    styleUrls: ['./product-optimization.component.scss']
})
export class ProductOptimizationComponent implements OnInit {
    searchRows: Array<any> = [];
    listingRows: Array<any> = [];
    categories: Array<any> = [];
    numberOfTerms: number = 102;
    categoriesString: string;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.categories = ['Electronics', 'Watchs', 'Smart Watch'];
        this.categoriesString = this.categories.join(' > ');
        this.searchRows = [
            ['Sport Smart Watch', '44,201'],
            ['Sport Smart Watch', '44,201'],
            ['Sport Smart Watch', '44,201'],
            ['Sport Smart Watch', '44,201']
        ];

        this.listingRows = [
            ['5/9 Images', '360/550 words'],
            ['3/11 Bullets', '360/550 words']
        ];
    }
}
