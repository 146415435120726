import { AuthServiceService } from './../../services/auth-service.service';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as qs from 'qs';
import { IPlans, PricesService } from '../../../app/services/prices.service';
import { Observable, Subscription } from 'rxjs';
import { BrandPaymentMethod } from '../../dtos/brandResponse';
import { MarketPlaceService } from '../../../app/services/MarketPlaceService';
import AsyncResponse from '../../dtos/AsyncResponse';

@Component({
    selector: 'app-checkout-logged-user',
    templateUrl: './checkout-logged-user.component.html',
    styleUrls: ['./checkout-logged-user.component.scss']
})
export class CheckoutLoggedUserComponent implements OnInit, OnDestroy {
    public userName: string = '';
    public urlIframe: string;
    public couponUrl: string;
    public choosenPlan$: Observable<IPlans>;

    @Output() priceEmit: EventEmitter<number> = new EventEmitter<number>();

    public isCouponActivate: boolean;
    private coupons: string[] = ['***buyboxvip***', 'cayzone10'];
    public dataFromCouponInput: string;
    public couponInUrl: string;
    public planValue: number;
    public prices$: Observable<IPlans[]>;
    public subscriptions$: Observable<BrandPaymentMethod[]>;

    public currentPlan: IPlans;
    public currentSubscription: BrandPaymentMethod;

    @ViewChild('paymentTranzila') paymentTranzila: ElementRef;

    public glazerCoupon: number = 0.9;
    public generalCoupon: number = 0.95;
    public nolCoupon: number = 1;
    public currentPlanIndex: number = 0;
    public currentSubscriptionIndex: number = 0;

    public choosenPlanSubscribe: Subscription;

    constructor(private authService: AuthServiceService, private prices: PricesService, private marketplaceService: MarketPlaceService) { }

    public getToken(): Promise<string> {
        return this.authService.getToken();
    }

    getUsername(): Promise<string> {
        return this.authService.getUsername();
    }

    ngOnInit(): void {
        this.prices.getPrices();
        this.prices.getSubscriptions();
        this.isCouponActivate = this.prices.isCouponActive;
        this.prices$ = this.prices.prices$;
        this.subscriptions$ = this.prices.subscriptions$;
        this.choosenPlan$ = this.prices.choosenPlan$;

        this.choosenPlanSubscribe = this.choosenPlan$.subscribe((plan: IPlans) => {
            this.currentPlan = plan;
            this.priceEmit.emit(this.currentPlan.monthlyPrice);

            this.getUsername().then((res: string) => {
                this.userName = res;
                this.getToken().then((token: string) => {
                    const url: string = environment.paymentServiceUrl;
                    if (this.paymentTranzila) {
                        this.paymentTranzila.nativeElement.src += '';
                    }
                    this.urlIframe = `${url}?${qs.stringify({
                        sum: this.currentPlan.totalPayment,
                        currency: 2,
                        email: this.userName,
                        brand: this.marketplaceService.brand,
                        nologo: 1,
                        cred_type: 1,
                        trButtonColor: 'ff00cf',
                        ppnewwin: 2,
                        recur_transaction: 4,
                        token
                    })}`;
                });
            });
        });
    }

    planHasChoosen(value: number): void {
        this.currentPlanIndex = value;
    }

    subscriptionHasChoosen(value: number): void {
        this.currentSubscriptionIndex = value;
    }

    handleCoupons(coupon: number): void {
        this.prices.couponDisscount = coupon;
        this.prices.isCouponActive = true;
        this.prices.makeDisscount();
        this.isCouponActivate = this.prices.isCouponActive;
    }

    insertCoupon(coupon: string): void {
        if (!coupon) {
            return;
        }
        this.dataFromCouponInput = coupon.toLowerCase();
        const cupon: string = this.coupons.find((data: string) => data === this.dataFromCouponInput);
        if (cupon) {
            this.marketplaceService.getCoupon(coupon).subscribe((response: AsyncResponse) => {
                if (response.data.isValid) {
                    this.handleCoupons(this.glazerCoupon);
                }
            });

            this.couponInUrl = cupon;
            this.coupons = this.coupons.filter((data: string) => data.toLowerCase() !== this.dataFromCouponInput);
            this.priceEmit.emit(this.currentPlan.monthlyPrice);
        }
    }

    ngOnDestroy(): void {
        this.choosenPlanSubscribe.unsubscribe();
    }
}
