import { environment } from './../../../environments/environment';
import { IVideoSlider } from './../../model/ui-interfaces';
import { Component } from '@angular/core';

@Component({
    selector: 'video-section',
    templateUrl: './video-section.component.html',
    styleUrls: ['./video-section.component.scss']
})
export class VideoSectionComponent {
    videosSlider: IVideoSlider[] = [{ image: 'image-video-one.png', title: 'About Bloooom', video: environment.videoSliderLink }];
}
