import { fade, fadeOut } from './../../../../animations/fade';
import { GetSearchedAsinService } from './../../../../services/get-searched-asin.service';
import { GetProductDataByAsinFailureAction, GetProductDataByAsinSuccessAction } from './../../../../store/actions/report.actions';
import { GetVariationDataService } from './../../../../services/get-variation-data.service';
import { IApplicationState } from './../../../../store/state/application.state';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetProductData } from '../../../../model/report.interface';
import { ReportService } from '../../../../services/report.service';

@Component({
    selector: 'app-single-variation',
    templateUrl: './single-variation.component.html',
    styleUrls: ['./single-variation.component.scss'],
    animations: [fade, fadeOut]
})
export class SingleVariationComponent implements OnInit {
    @Input() variant: any;
    public getProductDataVariation$: Observable<GetProductData[]>;
    public productDataVariation: GetProductData;
    public currentAsin: string;
    public variantSpinner: boolean;

    constructor(
        private store: Store<IApplicationState>,
        private getProductDataVariation: GetVariationDataService,
        private searchedAsin: GetSearchedAsinService,
        private reportService: ReportService
    ) {}

    ngOnInit(): void {
        this.currentAsin = this.searchedAsin.getSearchedAsin();
        this.getProductDataVariation.getproductDataForVaiation(this.currentAsin);
    }

    async onSubmit(asin: string, buttonVariation: any): Promise<void> {
        this.variantSpinner = true;
        if (asin) {
            event.preventDefault();
            this.getProductDataVariation.productDataForVaiation$.subscribe((variationData: GetProductData[]) => {
                if (variationData) {
                    this.productDataVariation = variationData.find((item: GetProductData) => item.asin === asin);
                    setTimeout(() => {
                        if (this.productDataVariation) {
                            buttonVariation.classList.add('data-delivered');
                            this.variantSpinner = false;
                        }
                    });
                }
            });
            const productData: GetProductData = await this.reportService.getProductData(asin);
            if (productData) {
                this.store.dispatch(new GetProductDataByAsinSuccessAction(productData));
            } else {
                this.store.dispatch(new GetProductDataByAsinFailureAction('could not gt product data', asin));
            }
        }
    }
}
