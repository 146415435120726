import { Component, Input, HostListener, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { flyInOut } from '../../../app/animations/menuOpenCloseAnimation';
import { IsMobileService } from '../../../app/services/is-mobile.service';
import { ToggleMenuService } from '../../../app/services/toggle-menu.service';
import { fade } from '../../animations/fade';
import { NotificationsService } from '../../../app/services/notifications.service';
import { ENotificationsStatus, INotifications } from '../../../assets/data/notification.mock';

@Component({
    host: {
        '(document:click)': 'onClick($event)'
    },
    selector: 'login-user-header',
    templateUrl: './login-user-header.component.html',
    styleUrls: ['./login-user-header.component.scss'],
    animations: [fade, flyInOut]
})


export class LoginUserHeaderComponent implements OnInit {
    @Input() isInitialUser: string;
    @Input() isUserLoggedIn: boolean;
    dropdownUserIsOpen: boolean;
    public toggleNotifications: boolean;
    delayHover: any;
    public isMenuOpen$: Observable<boolean>;
    public isMobile: boolean;
    public isHomepage: boolean;
    public notifications$: Observable<INotifications[]>;
    public notificationsNotRead: number;

    @HostListener('window:scroll', ['$event']) closeWhenScroll(): void {
        if (window.scrollY > 200) {
            this.dropdownUserIsOpen = false;
        }
    }

    constructor(private toggleMenu: ToggleMenuService, private el: ElementRef, private isMobileDevice: IsMobileService, private router: Router, private notificationService: NotificationsService) { }

    public ngOnInit(): void {
        this.isMenuOpen$ = this.toggleMenu.toggleMenu$;
        this.isMobile = this.isMobileDevice.isMobileDevice();
        this.isHomepage = this.isHomePage();

        this.initNotifications();

    }
    private initNotifications(): void {
        this.notifications$ = this.notificationService.notifications$;
        this.notifications$.subscribe((data: INotifications[]): void => {
            if (data) {
                this.notificationsNotRead = data.filter(notification => !notification.isRead).length;
            }
        });
    }
    public deleteNotification(notification: INotifications): void {
        notification.isRead = true;
        this.notificationsNotRead = this.notificationsNotRead - 1;
        this.notificationService.deleteNotification(notification);
    }

    private isHomePage(): boolean {
        return this.router.url === '/bloooom';
    }

    public getNotificationsStatusIcon(status: ENotificationsStatus): string {
        switch (status) {
            case ENotificationsStatus.READY:
                return 'not-success-icon';

            case ENotificationsStatus.FINISHED_WITH_ERRORS:
                return 'not-success-icon';

            case ENotificationsStatus.IN_PROGRESS:
                return 'not-inpregress-icon';

            case ENotificationsStatus.FAILURE:
                return 'not-faild-icon';
        }
    }

    public toggleMenuHandle(): void {
        this.toggleMenu.toggleMenuHandle();
    }

    public toggleNotificationsHandle(): void {
        this.toggleNotifications = !this.toggleNotifications;
        if (this.toggleNotifications) {
            this.notificationService.notificationsReaded();
        }
    }
    public onClick(event): void {
        if (!this.el.nativeElement.contains(event.target)) // or some similar check
            this.toggleNotifications = false;
    }

    dropdownUserIsOpenEmit(event: boolean): void {
        this.dropdownUserIsOpen = event;
    }

    stopDropdwonDelay(): void {
        this.dropdownUserIsOpen = false;
        clearTimeout(this.delayHover);
    }
    openDropdownMet(event: MouseEvent): void {
        if (event.type === 'mouseenter') {
            this.delayHover = setTimeout(() => {
                this.dropdownUserIsOpen = true;
            }, 200);
        } else {
            this.stopDropdwonDelay();
        }
    }
}
