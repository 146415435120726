import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

	transform(records: Array<any>, args?: any): any {

		if (args.property == 'volume')
			return records;

		return records.sort(function (a, b) {
			let stringA = (a[args.property] !== undefined && a[args.property] !== null) ? a[args.property] : '';
			let stringB = (b[args.property] !== undefined && b[args.property] !== null) ? b[args.property] : '';

			if (stringA < stringB) {
				return -1 * args.direction;
			} else if (stringA > stringB) {
				return 1 * args.direction;
			} else {
				return 0;
			}
		});
	};
}