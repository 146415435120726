import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { Observable, Subscription } from 'rxjs';
import { GetMonthPipe } from '../../../app/pipes/get-month.pipe';
import { AggregatedDataOverTime } from '../../../app/model/report.interface';
import { IMarketData } from '../../../app/services/related-products-service.service';
import { fade, fadeOut } from '../../../app/animations/fade';
import { stringify } from 'querystring';
@Component({
    selector: 'app-bar-chart-with-prediction',
    templateUrl: './bar-chart-with-prediction.component.html',
    styleUrls: ['./bar-chart-with-prediction.component.scss'],
    providers: [GetMonthPipe],
    animations: [fade, fadeOut]
})
export class BarChartWithPredictionComponent implements OnInit, OnDestroy {
    @Input() marketData$: Observable<IMarketData>;
    public marketDataSub$: Subscription;
    @Input() getSalesVolumeByPriceLabels: string[] = [];
    @Input() svgId: string;
    @Input() isDataNormelized: boolean;
    @Input() chartDescription: string;
    public isDataFetched: boolean;
    @Input() headerChart: { title: string; content: string };
    @ViewChild('chart') private chartContainer: ElementRef;

    constructor(private monthPipe: GetMonthPipe) { }

    ngOnInit(): void {
        this.marketDataSub$ = this.marketData$.subscribe((data: IMarketData) => {
            if (data && data.productAvgVolumnOverTime && data.productAvgVolumnOverTime.length > 0) {
                const onlyLastTenMonths: AggregatedDataOverTime[] = (data.productAvgVolumnOverTime.length > 10
                    ? data.productAvgVolumnOverTime.slice(-10)
                    : data.productAvgVolumnOverTime
                ).map((item: AggregatedDataOverTime) => {
                    const temp: any = Object.assign({}, item);
                    temp.timestamp = this.monthPipe.transform(temp.timestamp) + ' ' + temp.timestamp.slice(-4);
                    return temp;
                });
                this.isDataFetched = true;
                setTimeout(() => {
                    this.buildChart(onlyLastTenMonths, this.monthPipe);
                });
            } else if (this.isDataNormelized) {
                this.isDataFetched = true;
                setTimeout(() => {
                    this.buildChart(data);
                }, 10000);
            }
        });
    }
    ngOnDestroy(): void {
        this.marketDataSub$.unsubscribe();
    }
    buildChart(dataForBars: AggregatedDataOverTime[] | any, pipe?: any): void {
        d3.select('svg#chartForSales').remove();
        const element: any = this.chartContainer.nativeElement;
        // const data: any = this.data;

        const svg: any = d3
            .select(element)
            .append('svg')
            .attr('id', 'chartForSales')
            .attr('width', element.offsetWidth - 100)
            .attr('height', element.offsetHeight - 100)
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('viewBox', '0 0 960 500');

        const data: any = dataForBars;

        const height: number = +svg.attr('height');
        const width: number = +svg.attr('width');

        const margin: any = { top: 0.1 * width, right: 0.1 * width, bottom: 0.1 * width, left: 0.15 * width };
        svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('fill', 'pink')
            .attr('width', width)
            .attr('height', height);

        const xScale: any = d3
            .scaleBand()
            .range([0, width])
            .domain(data.map((d: AggregatedDataOverTime) => d.timestamp))
            .padding(0.2);

        const yScale: any = d3
            .scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, (d: AggregatedDataOverTime) => d.avgRevenue)]);

        // create axis objects
        const xAxis: any = d3.axisBottom(xScale);
        const yAxis: any = d3.axisLeft(yScale).ticks(6);

        // Draw Axis
        svg.append('g')
            .attr('transform', 'translate(' + margin.left + ',' + (margin.top + height) + ')')
            .attr('class', 'axis axis--x')
            .call(xAxis);
        svg.append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .call(yAxis);
        // labels of axises x and y
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 50)
            .attr('x', 0 - (height + margin.top + margin.bottom) / 2)
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '12px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[0]);

        svg.append('text')
            .attr('transform', 'translate(' + (width + margin.left + margin.right) / 2 + ' ,' + (height + margin.top + 30) + ')')
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '12px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[1]);
        const Tooltip: typeof d3 = d3
            .select('#chartForSalesWrapper')
            .style('position', 'relavite')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            // .style('background-color', '#39214f')
            .style('border-radius', '20px')
            .style('height', '300px')
            .style('position', 'absolute')
            .style('padding', '10px');

        const mouseover: typeof d3 = function (): void {
            d3.select(this).attr('fill', 'rgba(255, 255, 255, .8)');

            Tooltip.style('opacity', 1);
            Tooltip.style('display', 'block');
        };

        const mousemove: typeof d3 = function (d: AggregatedDataOverTime, i: number): void {
            Tooltip.style('opacity', 1);
            Tooltip.style('display', 'block');
            Tooltip.html(
                `<div class="tooltip-data">
                <span class="month-notation">${d.timestamp}</span>
                <div class="chart-tooltip-bsr">
                <span class="title">BSR</span>
                <span class="data">#${Number(d.avgBsr)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                <div class="chart-tooltip-avg-price">
                <span class="title">Price</span>
                <span class="data">$${Number(d.avgPrice)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                <div class="chart-tooltip-avg-sales">
                <span class="title">Sales(Units)</span>
                <span class="data">${Number(d.avgSales)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                <div class="chart-tooltip-avg-revenue">
                <span class="title">Revenue</span>
                <span class="data">$${Number(d.avgRevenue)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </div>
                </div>
            `
            )
                .style('left', d3.mouse(this)[0] - 16 * i + 'px')
                .style(
                    'top',
                    30 -
                    parseInt(
                        d3
                            .select(this)
                            .style('height')
                            .replace('px', ''),
                        10
                    ) +
                    'px'
                );
        };

        const mouseleave: typeof d3 = function (): void {
            d3.select(this).attr('fill', 'rgba(255,255,255,.3)');
            Tooltip.style('opacity', 0);
            Tooltip.style('display', 'none');
        };

        // Draw Datapoints
        const points_g: any = svg
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + (margin.top - 10) + ')')
            // .attr('clip-path', 'url(#clip)')
            .classed('points_g', true);

        // var data = genRandomData(n, max);
        let points: any = points_g.selectAll('.bar').data(data);
        points = points
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', 'rgba(255,255,255,.3)')
            .style('cursor', 'pointer')
            .attr('x', (d: any) => xScale(d.timestamp))
            .attr('y', (d: any) => yScale(d.avgRevenue))
            .attr('height', (d: any) => height - yScale(d.avgRevenue))
            .attr('width', xScale.bandwidth())
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseleave', mouseleave);
    }
}
