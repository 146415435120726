import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
    selector: 'read-more-popup',
    templateUrl: './read-more-popup.component.html',
    styleUrls: ['./read-more-popup.component.scss']
})
export class ReadMorePopupComponent {
    @Input() title: string;
    @Input() content: string;
    @Input() imageName: string;
    @Input() redirectUrl: string;

    @Input() closable: boolean = true;
    @Input() visible: boolean;
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private translateService: TranslateService, public router: Router) {}

    close(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

    readMore(): void {
        this.close();
        this.redirect();
    }

    redirect(): void {
        if (this.redirectUrl) {
            this.router.navigateByUrl(this.redirectUrl);
        }
    }
}
