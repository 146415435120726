import { Component, OnInit } from '@angular/core';
import GetProductResearchProductFilter from '../../dtos/GetProductResearchProductFilter';
import { fadeOut, fade } from '../../animations/fade';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import ProductResearchResponseDto from '../../dtos/ProductResearchResponseDto';
import PaginateResponse from '../../dtos/paginateResponse';
import GetProductResearchHandler from '../../services/handler/GetProductResearchHandler';
import { HttpClient } from '@angular/common/http';
import GetProductResearchResponse from '../../dtos/GetProductResearchResponse';
import { KeywordAnalysisState } from '../../store/state/keywordAnalysis.state';
import { productResearchSelector } from '../../store/selectors/keywords.selector';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ToastrService } from '../../services/toastr.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { slideUpDown } from '../../../app/animations/slides';
import { PollingService } from '../../services/polling.service';
import { PageType } from '../../enums/PageType';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-product-research',
  templateUrl: './product-research.component.html',
  styleUrls: ['./product-research.component.scss'],
  animations: [fade, fadeOut, slideUpDown]
})
export class ProductResearchComponent implements OnInit {
  private static readonly FIRST_PAGE: number = 1;
  public productResearch$: Subscription;
  //-----Filters-----//
  public openMonthlyRevenue: boolean = false;
  public openPrice: boolean = false;
  public openReviewCount: boolean = false;
  public openReviewRating: boolean = false;
  public openTimeOnAmazonMonths: boolean = false;
  public openBsr: boolean = false;
  public openAsins: boolean = false;
  public openCategories: boolean = false;
  public openTierSize: boolean = false;
  public openTerms: boolean = false;
  public asins: string;
  public categories: string;

  public categoriesList: string[];
  //END-----Filters-----//

  // more filters toggle
  public isMoreFilters: boolean = false;

  // mockdata
  public mainDetails: any = [{ value: 45, description: 'Last Mo' }, { value: -10, description: 'Last Yr' }, { value: 102, description: 'Reviews Last Mo' }]

  // stars for rating
  public starGood: string = 'assets/images/star-good.svg';
  public starBad: string = 'assets/images/star-bad.svg';
  public starSemi: string = 'assets/images/star-semi.svg';
  public stars: string[];

  isVolumeAsc: boolean = true;
  //--For sorting functionality--//
  isDesc: boolean = true;
  column: string = 'asin';
  direction: number = 1;
  //END--For sorting functionality--//
  public productResearchResponseDtos: ProductResearchResponseDto[] = [];
  public productResearchPaginateResponse: PaginateResponse;
  public getProductResearchProductFilter: GetProductResearchProductFilter = new GetProductResearchProductFilter();

  constructor(private toastrService: ToastrService, private marketPlaceService: MarketPlaceService, private httpClient: HttpClient, private store: Store<KeywordAnalysisState>, public progressBarService: ProgressBarService, private pollingService: PollingService) { }

  ngOnInit(): void {
    this.categoriesList = ['video games', 'toys & games', 'tools & home improvement', 'sports & outdoors', 'software', 'pet supplies', 'patio lawn & garden', 'patio, lawn & garden', 'office products',
      'kitchen & dining',
      'industrial & scientific',
      'home & kitchen',
      'health & household',
      'grocery & gourmet food',
      'electronics',
      'clothing shoes & jewelry',
      'clothing, shoes & jewelry',
      'cell phones & accessories',
      'cell phones',
      'books',
      'beauty & personal care',
      'baby',
      'automotive',
      'arts crafts & sewing',
      'arts, crafts & sewing',
      'musical instruments',
      'computers & accessories',
      'camera & photo',
    ];

    this.progressBarService.setProgressBar();
    this.callProductResearchApi(ProductResearchComponent.FIRST_PAGE);

    this.productResearch$ = this.store.pipe(select(productResearchSelector)).subscribe((data: GetProductResearchResponse) => {
      if (data) {
        this.progressBarService.removeProgressBar();
        this.productResearchPaginateResponse = data.paginateResponse;
        this.productResearchResponseDtos = data.productResearchResponseDtos;
      }
    });
  }

  public getPhraseDataInput(emittedData: string[]): void {
    if (emittedData[1] = 'asins')
      this.asins = emittedData[0]
  }

  public callProductResearchApi(pageNumber: number): void {
    this.pollingService.setNoProductResearchTaskId();
    this.getProductResearchProductFilter.page = pageNumber;
    new GetProductResearchHandler(PageType.ProductResearchPage, this.marketPlaceService, this.getProductResearchProductFilter, this.httpClient, this.store, this.pollingService).process();
  }

  public ngOnDestroy(): void {
    this.productResearch$.unsubscribe();
  }

  public makeStartsByStarsRate(stars: string): string[] {
    if (stars) {
      stars = stars.toString();
      this.stars = [];
      if (!/^(\.)$/.test(stars)) {
        stars = stars + '.0';
      }
      const [int, dec] = stars.split('.');
      for (let i: number = 0; i < 5; i++) {
        if (i < parseInt(int, 10)) {
          this.stars.push('good');
        } else if (parseInt(dec, 10) > 0 && i === parseInt(int, 10)) {
          this.stars.push('semi');
        } else {
          this.stars.push('bad');
        }
      }
      return this.stars;
    }
  }

  public toggleMoreFilters(): void {
    this.isMoreFilters = !this.isMoreFilters;
  }

  public sort(property): void {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  };

  public sortByVolume(): void {
    this.isVolumeAsc = !this.isVolumeAsc;
    this.isDesc = this.isVolumeAsc;
    this.column = 'volume';
    this.direction = this.isDesc ? 1 : -1;
    this.getProductResearchProductFilter.sortVolume(this.isVolumeAsc);
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(ProductResearchComponent.FIRST_PAGE);
  }
  public getReportFromCompetittorProduct(asin: string): void {
    const url: string = `${environment.bloooomUrl}/#/dashboard/report?asin=${asin}`;
    window.open(url, '_blank');
  }
  public applyFilter(): void {
    let errorMessage = this.getProductResearchProductFilter.checkIsValid(this);
    if (errorMessage.length > 0) {
      this.toastrService.showError(errorMessage);
      return;
    }
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(ProductResearchComponent.FIRST_PAGE);
  }

  public clearFilter(): void {
    this.getProductResearchProductFilter.clear(this);
    this.progressBarService.setProgressBar();
    this.callProductResearchApi(ProductResearchComponent.FIRST_PAGE);
  }

}
