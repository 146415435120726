import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: number, args?: any): string | number {
        if (value && value.toString().length > 3) {
            const number: string = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            const numberSplited: string[] = number.split(',');
            const letterDefineNumber: string = value.toString().length > 6 ? 'M' : 'K';
            const numberFormatted: string = numberSplited[0] + '.' + numberSplited[1].toString().slice(0, 1) + letterDefineNumber;
            return numberFormatted;
        }
        return value;
    }
}
