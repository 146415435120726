import { reportReducers } from './report.reducers';
import { ActionReducerMap } from '@ngrx/store';
import { IApplicationState } from '../state/application.state';
import { userTaskReducers } from './userTask.reducers';
import { taskReducers } from './task.reducers';
import { campaignReducers } from './campaign.reducers';

export const applicationReducers: ActionReducerMap<IApplicationState> = {
    reportState: reportReducers,
    userTaskState: userTaskReducers,
    keywordAnalysisState: taskReducers,
    campaignsState: campaignReducers
};
