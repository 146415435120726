import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    private popMessageSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public popMessage: Observable<boolean> = this.popMessageSubject.asObservable();

    constructor() {
        this.popMessageDelay();
    }

    popMessageDelay(): void {
        setTimeout((): void => {
            this.popMessageSubject.next(true);
        }, 3000);
    }

    closeMessage(): void {
        this.popMessageSubject.next(false);
    }
}
