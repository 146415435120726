import { Component, OnInit, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Component({
    selector: 'pop-tutorial-section',
    templateUrl: './pop-tutorial-section.component.html',
    styleUrls: ['./pop-tutorial-section.component.scss']
})
export class PopTutorialSectionComponent {
    @Output() public closePop: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() videoLink: string;

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.closePopFun();
        }
    }
    closePopFun(): void {
        this.closePop.emit(false);
    }
}
