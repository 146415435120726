import { Component, Input } from '@angular/core';
import { ICardsData } from '../../../app/model/ui-interfaces';

@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss']
})
export class CardsComponent {
    @Input() cardsData: ICardsData[];
}
