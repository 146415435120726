import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomerActionRequest, ParameterDto } from '../model/google/CustomerActionRequest';
import AsyncResponse from '../dtos/AsyncResponse';
import { TaskType } from '../enums/TaskType';
import { EntityType, SyncStatus } from '../model/google/newGoogleCampaignRequest';
import { PollingService } from './polling.service';
import { ActionType } from '../model/google/ActionType';

@Injectable({
  providedIn: 'root'
})
export class CustomerActionsService {

  constructor(private translateService: TranslateService, private pollingService: PollingService, private httpClient: HttpClient) { }

  public setStatusAction(entityIds: number[], entityName: EntityType, syncStatus: SyncStatus): void {
    if (!isEmpty(entityIds)) {
      console.log(syncStatus, entityName, entityIds)
      const url = environment.customerActionUrl.replace('{entityType}', entityName);
      const parameters: ParameterDto[] = [
        new ParameterDto('syncStatus', syncStatus),
        new ParameterDto('entityIds', entityIds)
      ]
      const payload: CustomerActionRequest = new CustomerActionRequest(ActionType.SET_STATUS, parameters);

      this.httpClient.post(url, payload).pipe(timeout(environment.timeout)).subscribe((response: AsyncResponse) => {
        if (response.errorKey) {
          // this.translateService.get(response.errorKey).subscribe((res) => {
          console.log('err on ui', response.errorKey)
          // });
        }
        this.pollingService.getStatus(response.taskId, TaskType.CUSTOMER_ACTION_SET_STATUS);

      });
    }
  }
}
