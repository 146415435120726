import { fade, fadeOut } from './../../animations/fade';

import { IBenefitsSlider } from './../../model/ui-interfaces';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'benefits-slider',
    templateUrl: './benefits-slider.component.html',
    styleUrls: ['./benefits-slider.component.scss'],
    animations: [fade, fadeOut]
})
export class BenefitsSliderComponent implements OnInit, OnDestroy {
    @Input() benefits: IBenefitsSlider[] = [] as IBenefitsSlider[];
    sliderIndexDisplay: number = 0;
    caruselSlides: any;

    ngOnInit(): void {
        this.caruselSlides = setInterval(() => {
            if (this.sliderIndexDisplay < this.benefits.length - 1) {
                this.sliderIndexDisplay++;
            } else {
                this.sliderIndexDisplay = 0;
            }
        }, 10000);
    }

    backToSlide(index: number): void {
        this.sliderIndexDisplay = index;
    }

    ngOnDestroy(): void {
        clearInterval(this.caruselSlides);
    }
}
