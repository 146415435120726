import { trigger, transition, state, animate, animation, style, keyframes, useAnimation, group, query, stagger } from '@angular/animations';


export const popIn = trigger('popIn', [
    state('in', style({ opacity: 1, top: '-30px' })),
    transition('void => *', [
        style({ opacity: 0, top: '-20px' }),
        animate(300)
    ]),
    transition('* => void', [

        animate(100, style({ width: '5px', height: '5px', borderRadius: '10px', backgroundColor: '#e86464' })),
        animate(400, style({ opacity: 0, top: '-200px', right: '40px' }))
    ]),
])
