import { Store } from "@ngrx/store";


import { KeywordAnalysisState } from "../../store/state/keywordAnalysis.state";
import { MarketPlaceService } from "../MarketPlaceService";
import TaskHandler from "./TaskHandler";
import { HttpClient } from "@angular/common/http";
import { PollingService } from "../polling.service";
import { PageType } from "../../enums/PageType";
import { GetCreateCampaignAction } from "../../../app/store/actions/campaigns.actions";
import AsyncResponse from "../../../app/dtos/AsyncResponse";

export default class GetCampaignHandler implements TaskHandler {

    private taskId: number;
    private keywords: string[] = [];


    public constructor(private pageType: PageType, private marketPlaceService: MarketPlaceService,
        private httpClient: HttpClient, private store: Store<KeywordAnalysisState>, private pollingService: PollingService, private asyncResponse: AsyncResponse) {
    }

    public async process(): Promise<void> {
        await this.getCampaignsData();
    }

    private async getCampaignsData(): Promise<void> {
        const getCampaignResponse: any = await this.getCampaignByName(this.asyncResponse.data.campaignName);
        this.store.dispatch(new GetCreateCampaignAction(getCampaignResponse));
    }

    public async getCampaignByName(campaignName: string): Promise<any> {
        return await this.marketPlaceService.getCampaignByName(campaignName).toPromise();
    }
}