import { Injectable } from '@angular/core';
import { IReportState } from '../store/state/report.state';
import { Store, select } from '@ngrx/store';
import { fbaSelector } from '../store/selectors/fba.selector';
import { BehaviorSubject, Observable } from 'rxjs';
import { FbaDto } from '../model/report.interface';

@Injectable({
    providedIn: 'root'
})
export class GetFbaService {
    public productDataFoFbaSubject: BehaviorSubject<FbaDto> = new BehaviorSubject<FbaDto>(null);
    public productDataFoFba$: Observable<FbaDto> = this.productDataFoFbaSubject.asObservable();

    getDataFromStore$: Observable<FbaDto>;

    constructor(private Store: Store<IReportState>) {}

    getProductDataForFba(asin: string): void {
        this.getDataFromStore$ = this.Store.pipe(select(fbaSelector, { asin: asin }));
        this.getDataFromStore$.subscribe((data: FbaDto) => {
            this.productDataFoFbaSubject.next(data);
        });
    }
}
