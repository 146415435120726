import { AbstractControl, ValidatorFn } from "@angular/forms";

export class DateValidators {
    static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
            const fromDate: any = new Date(formGroup.get(fromDateField).value).getTime();
            const toDate: any = new Date(formGroup.get(toDateField).value).getTime();

            if ((fromDate !== null && toDate !== null) && fromDate >= toDate) {
                return { [errorName]: true };
            }
            return null;
        };
    }
}
