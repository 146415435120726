import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minPipe'
})
export class MinPipePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            return Math.min(...value);
        }
    }
}
