import { ParameterDto } from "./CustomerActionRequest";
import CampaignForType from "../campaignForType";
import { CampaignStatusInEngine, SyncStatus } from "./newGoogleCampaignRequest";


export enum Engine {
    GOOGLE = "gogl",
    FACEBOOK = "fb",
    AMAZON = "amz",
    YOUTUBE = "yut"
}

export enum TargetSite {
    SHOPIFY = "sop",
    EBAY = "eb",
    AMAZON = "amz",
    OTHER = "oth"
}


export interface ICampaignTotal {
    active: number;
    unactive: number;
    googleToAmz: number;
    googleToShopify: number;

}


export interface ICampaignsSummary {
    totalBudget: number,
    totalClicks: number
}


export class IAdGroups {
    adGroupId: string;
    idInEngine: string;
    syncStatus: SyncStatus;
    ads: IAd[];
    keywords: IKeywords[];
    parameters: ParameterDto[];

}

export enum EKeywordType {
    PHRASE = 'Phrase',
    TERM = 'Term',
    GENERAL = 'General'
}

export class IKeywords {
    adKeywordsId: string;
    idInEngine: string;
    syncStatus: SyncStatus;
    keywordType: EKeywordType;
    keywords: string
    parameters: ParameterDto[];
}

export class IAd {
    adId: string;
    syncStatus: SyncStatus;
    adType: string;
    displayUrl: string;
    path1: string;
    path2: string;
    headline1: string;
    headline2: string;
    headline3: string;
    headline4: string;
    headline5: string;
    headline6: string;
    headline7: string;
    headline8: string;
    headline9: string;
    headline10: string;
    headline11: string;
    headline12: string;
    headline13: string;
    headline14: string;
    headline15: string;
    description1: string;
    description2: string;
    parameters: ParameterDto[];
}


export interface ICampaign {
    id: string,
    platform?: Engine,
    targetSite?: TargetSite,
    idInEngine: string;
    syncStatus: SyncStatus;
    statusInEngine: CampaignStatusInEngine;
    campaignName: string,
    advertiserCampaignType: any;
    biddingMethod: any;
    uploadIndication: boolean;
    startDate: string;
    endDate: string;
    dailyBudget: number;
    totalDays: number;

    campaignForType: CampaignForType;
    siteUrl: string;
    networks: string[];
    locationsToTarget: ILocationsToTarget[];
    languagesToTarget: ILanguagesToTarget[];
    sitelinksExtensions: ISitelinksExtentions[];
    calloutExtensions: ICallputExtentions[];
    imageAssetExtensions: IImageAssetExtensions;
    priceExtensions: IPriceExtensions;
    adGroups: IAdGroups[];
    totalBudget: number;
    asin: string;
    title: string;
    mainImgUrl: string;
}



// @Exclude()
// export default class CustomerCampaignDto {
//     @Expose()
//     public id: number;
//     @Expose()
//     public idInEngine: number;
//     @Expose()
//     public syncStatus: SyncStatus;
//     @Expose()
//     public statusInEngine: CampaignStatusInEngine;
//     @Expose()
//     public campaignName: string;
//     @Expose()
//     public advertiserCampaignType: AdvertiserCampaignType;
//     @Expose()
//     public biddingMethod: BiddingMethod;
//     @Expose()
//     public uploadIndication: boolean;
//     @Expose()
//     public startDate: Date;
//     @Expose()
//     public endDate: Date;
//     @Expose()
//     public dailyBudget: number;
//     @Expose()
//     public campaignForType: CampaignForType;
//     @Expose()
//     public siteUrl: string;
//     @Expose()
//     public balance: number;
//     @Expose()
//     public asin: string;
//     @Expose()
//     public title: string;
//     @Expose()
//     public mainImgUrl: string;
// }


export interface ISitelinksExtentions {
    id: string;
    idInEngine: any;
    syncStatus: SyncStatus;
    devicePreference: number,
    descriptionLine1: string,
    descriptionLine2: string,
    displayText: string,
    destinationUrl: string,
    parameters: ParameterDto[];
}

export interface ICallputExtentions {
    id: string;
    idInEngine: any;
    syncStatus: SyncStatus;
    calloutTextList: string[];
    parameters: ParameterDto[];
}
export interface ILanguagesToTarget {
    name: string;
}

export interface ILocationsToTarget {
    id: string;
    name: string;
}

export interface IImageAssetExtensions {

    id: string;
    idInEngine: any;
    syncStatus: SyncStatus;
    description: string;
    assetUrl: string;
    parameters: ParameterDto[];
}

export interface IPriceExtensions {
    id: string;
    idInEngine: any;
    syncStatus: SyncStatus;
    currency: string;
    priceOfferingList: IPriceOfferingList[];
    parameters: ParameterDto[];
}

export interface IPriceOfferingList {

    header: string;
    description: string;
    priceInMicros: number;
    currencyCode: string;
    unit: number;
    finalUrl: string;
    finalMobileUrl: string;
    parameters: ParameterDto[];
}