import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../../../../app/services/auth-service.service';
import { fade } from '../../../../app/animations/fade';
import { HandlePopupsService } from '../../../../app/services/handle-popups.service';

@Component({
    selector: 'app-signup-from-search',
    templateUrl: './signup-from-search.component.html',
    styleUrls: ['./signup-from-search.component.scss'],
    animations: [fade]
})
export class SignupFromSearchComponent implements OnInit {
    public watingPopDelay: boolean = false;
    public handlePopup$: Observable<boolean>;
    constructor(private handlePopup: HandlePopupsService, private authService: AuthServiceService) {}

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.handlePopup.setCtaFromSearchPopupStatus(false);
        }
    }
    public async login(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.login();
        }
    }

    public async signup(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.signUp();
        }
    }

    ngOnInit(): void {
        this.handlePopup$ = this.handlePopup.ctaFromSearchPopupSubject;
    }

    public closePopupWaiting(): void {
        this.handlePopup.setCtaFromSearchPopupStatus(false);
    }
}
