import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as qs from 'qs';
import { timeout } from 'rxjs/operators';
import GetProductResearchNicheRequest from '../dtos/GetProductResearchNicheRequest';

@Injectable({
  providedIn: 'root'
})
export class ProductResearchNicheService {

  constructor(private httpClient: HttpClient) { }

  public searchKeyword(keyword: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let getProductResearchNicheRequest: GetProductResearchNicheRequest = new GetProductResearchNicheRequest();
    getProductResearchNicheRequest.keyword = keyword;

    const url = `${environment.getProductResearchNicheUrl}`;
    return this.httpClient.post(url, getProductResearchNicheRequest, { headers: headers }).pipe(timeout(environment.timeout));
  }
}
