import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { AuthServiceService } from '../services/auth-service.service';
import { ThemeService } from '../services/theme.service';

export function initializer(keycloak: KeycloakService, themeService: ThemeService): () => Promise<any> {
    return (): Promise<any> => {
        // tslint:disable-next-line: typedef
        return new Promise(async (resolve, reject) => {
            try {
                let auth: boolean = false;
                const token: string = localStorage.getItem(AuthServiceService.TOKEN);
                if (token) {
                    const refreshToken: string = localStorage.getItem(AuthServiceService.REFRESH_TOKEN);
                    const idToken: string = localStorage.getItem(AuthServiceService.ID_TOKEN);
                    try {
                        auth = await keycloak.init({
                            config: environment.keycloak,
                            initOptions: {
                                checkLoginIframe: false,
                                token: token,
                                refreshToken: refreshToken,
                                idToken: idToken
                            },
                            bearerExcludedUrls: [
                                '/assets/*',
                                '/s3.us-east-2.amazonaws.com/www.assets.bloooom.co/*',
                                '/users/configuration/*',
                                '/products/get-product-data-by-asin/*',
                                '/marketplace/*',
                                '/users/contact-us/*'
                            ]
                        });
                    } catch (error) {
                        localStorage.removeItem(AuthServiceService.TOKEN);
                        localStorage.removeItem(AuthServiceService.REFRESH_TOKEN);
                        localStorage.removeItem(AuthServiceService.ID_TOKEN);
                        auth = await keycloak.init({
                            config: environment.keycloak,
                            initOptions: {
                                checkLoginIframe: false
                            },
                            bearerExcludedUrls: [
                                '/assets/*',
                                '/s3.us-east-2.amazonaws.com/www.assets.bloooom.co/*',
                                '/users/configuration/*',
                                '/products/get-product-data-by-asin/*',
                                '/marketplace/*',
                                '/users/contact-us/*'
                            ]
                        });
                    }
                } else {
                    auth = await keycloak.init({
                        config: environment.keycloak,
                        initOptions: {
                            checkLoginIframe: false
                        },
                        bearerExcludedUrls: [
                            '/assets/*',
                            '/s3.us-east-2.amazonaws.com/www.assets.bloooom.co/*',
                            '/users/configuration/*',
                            '/products/get-product-data-by-asin/*',
                            '/marketplace/*',
                            '/users/contact-us/*'
                        ]
                    });
                }

                if (auth) {
                    const idToken: string = keycloak.getKeycloakInstance().idToken;
                    const token: string = await keycloak.getToken();
                    const refreshToken: string = await keycloak.getKeycloakInstance().refreshToken;
                    localStorage.setItem(AuthServiceService.TOKEN, token);
                    localStorage.setItem(AuthServiceService.REFRESH_TOKEN, refreshToken);
                    localStorage.setItem(AuthServiceService.ID_TOKEN, idToken);
                }
                // await themeService.loadBrandStyle();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
