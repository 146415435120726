import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Theme } from '../../../assets/mocks/themes';
import { ThemeService } from '../../../app/services/theme.service';

@Component({
    selector: 'app-speedometer-bloooom-score',
    templateUrl: './speedometer-bloooom-score.component.html',
    styleUrls: ['./speedometer-bloooom-score.component.scss']
})
export class SpeedometerBloooomScoreComponent implements OnInit {
    constructor(private themeService: ThemeService) { }
    @Input() score: number;
    @ViewChild('clockHand') clockHand: ElementRef;
    @Output() totalResults: EventEmitter<boolean> = new EventEmitter<boolean>();
    topColor: string = '#ff00cf';
    bottomColor: string = '#ff00cf60';
    ngOnInit(): void {
        setTimeout(() => {
            this.bloooomScoreUi();
        }, 2000);
        this.themeService.getActiveTheme$
            .subscribe((theme: Theme) => {
                if (theme) {
                    this.topColor = theme.properties['--main-color-bright'];
                    this.bottomColor = theme.properties['--main-color-regular'];
                }
            })
            .unsubscribe();
    }

    gradientStrokeDash(score: number, rotateThemspeedometer: number): void {
        const pourcentage: number = score;
        const total: number = 600;
        const transitionTime: number = rotateThemspeedometer / 100;
        const result: number = (pourcentage * total) / 100;
        const circleEl: HTMLElement = document.querySelector('circle.pie');
        circleEl.style.strokeDasharray = `${result} 1000`;
        circleEl.style.transition = `stroke-dasharray ${transitionTime}s linear`;
    }

    bloooomScoreUi(): void {
        const highest: number = 194;
        const score: number = this.score;
        const rotateThemspeedometer: number = +((score / 100) * Math.abs(highest)).toFixed(0);
        let animationSteps: number = 0;
        this.gradientStrokeDash(this.score, rotateThemspeedometer);
        const theInterval: any = setInterval(() => {
            if (rotateThemspeedometer >= animationSteps) {
                this.clockHand.nativeElement.style.transform = `rotate(${animationSteps}deg)`;
                animationSteps++;
            } else {
                this.totalResults.emit(true);
                clearInterval(theInterval);
            }
        }, 10);
    }
}
