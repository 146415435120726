import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Theme, mercorE, bloooom } from '../../assets/mocks/themes';
import { AuthServiceService } from './auth-service.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private getActiveThemeSubject: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
    public getActiveTheme$: Observable<Theme> = this.getActiveThemeSubject.asObservable();
    private active: Theme = mercorE;
    private stylesMap = new Map<string, Theme>();
    // private availableThemes: Theme[] = [mercorE, bloooom];

    constructor(private httpClient: HttpClient, private translate: TranslateService) { }

    // getAvailableThemes(): Theme[] {
    //   return this.availableThemes;
    // }

    getActiveTheme(): Theme {
        return this.active;
    }

    isBloooomTheme(): boolean {
        return this.active.name === bloooom.name;
    }

    // setBloooomTheme(): void {
    //   this.setActiveTheme(bloooom);
    // }

    // setMercorETheme(): void {
    //   this.setActiveTheme(mercorE);
    // }

    public async getBrandStyle(brand: string): Promise<Theme> {
        try {
            if (!this.stylesMap.has(brand)) {
                const styleUrl: string = environment.styleUrl.replace('{{BRAND}}', brand);
                const brandStyle: any = await this.httpClient
                    .get(`${styleUrl}/style.brand.json`)
                    .pipe(timeout(environment.timeout))
                    .toPromise();
                this.stylesMap.set(brand, brandStyle as Theme);
            }
        } catch (error) {
            console.log(`no custom style for brand ${brand}`);
        }
        return this.stylesMap.get(brand);
    }

    setActiveTheme(theme: Theme): void {
        this.active = theme;
        this.getActiveThemeSubject.next(this.active);

        Object.keys(this.active.properties).forEach((property: any) => {
            document.documentElement.style.setProperty(property, this.active.properties[property]);
        });
    }
    public async loadBrandStyle(): Promise<void> {
      
        try {

            const userConfigurations: any = await this.httpClient
            .get(environment.userConfigurationUrl)
            .pipe(timeout(environment.timeout))
            .toPromise();

        const brand: string = userConfigurations.uiConfiguration.brand;
        await this.getAndSetTheme(brand);
        const styleUrl: string = environment.styleUrl.replace('{{BRAND}}', brand);
            const brandTranslation: any = await this.httpClient
                .get(`${styleUrl}/translation.brand.json`)
                .pipe(timeout(environment.timeout))
                .toPromise();
            if (brandTranslation) {
                this.translate.setTranslation(brand, brandTranslation, true);
                this.translate.use(brand).subscribe();
            }
        } catch (error) {
            console.log(`no custom translation for brand ${'brand'}`);
        }
    }

    private async getAndSetTheme(brand: string): Promise<void> {
        const brandStyle: Theme = await this.getBrandStyle(brand);
        if (brandStyle) {
            this.setActiveTheme(brandStyle as Theme);
        }
    }
}
