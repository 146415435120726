import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BestVariation } from '../../../app/model/report.interface';
import { TheBestVariationService } from '../../../app/services/the-best-variation.service';
import { fade, fadeOut } from '../../../app/animations/fade';
import { IAllProductDetails } from '../../../app/services/related-products-service.service';
import { environment } from '../../../environments/environment';
import { ReviewSummaryService } from '../../../app/services/review-summary.service';
import { Theme } from '../../../assets/mocks/themes';
import { ThemeService } from '../../../app/services/theme.service';

@Component({
    selector: 'app-the-searched-product',
    templateUrl: './the-searched-product.component.html',
    styleUrls: ['./the-searched-product.component.scss'],
    animations: [fade, fadeOut],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TheSearchedProductComponent implements OnInit {
    @Input() productDetails: IAllProductDetails | any;
    @Input() iconForTooltip: any;
    @Input() relatedProductSlider: boolean;
    @Input() fadeSlider: boolean = true;
    public reviewFactor$: Observable<string>;
    public theBestVariation$: Observable<BestVariation>;
    public starGood: string = 'assets/images/star-good.svg';
    public starBad: string = 'assets/images/star-bad.svg';
    public starSemi: string = 'assets/images/star-semi.svg';

    public getActiveTheme$: Observable<Theme>;
    private getActiveThemeSub: Subscription;

    constructor(private themeService: ThemeService, private reviewFactorService: ReviewSummaryService, private bestVariationService: TheBestVariationService) { }

    ngOnInit(): void {
        this.theBestVariation$ = this.bestVariationService.theBestVariation$;
        this.reviewFactor$ = this.reviewFactorService.getReviewFactor();

        this.getActiveTheme$ = this.themeService.getActiveTheme$;
        this.getActiveThemeSub = this.getActiveTheme$.subscribe((res: Theme) => {
            if (res) {
                this.starGood = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-good.svg';
                this.starSemi = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-semi.svg';
                this.starBad = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-bad.svg';
            }
        });
    }

    getReportFromCompetittorProduct(asin: string): void {
        const url: string = `${environment.bloooomUrl}/#/dashboard/report?asin=${asin}`;
        window.open(url, '_blank');
    }
}
