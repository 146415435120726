import { Component } from '@angular/core';
import { ToggleMenuService } from './../../../app/services/toggle-menu.service';
@Component({
    selector: 'main-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    public logoHeader: string = './assets/images/bloooom-logo.svg';

    constructor(private toggleMenuHandle: ToggleMenuService) { }

    toggleMenu(): void {
        this.toggleMenuHandle.toggleMenuHandle();
    }
}
