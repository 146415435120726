import { Component, Input, OnInit } from '@angular/core';
import { ICompetittorsHeader } from '../../../app/services/competittors.service';

@Component({
    selector: 'app-competittors-header',
    templateUrl: './competittors-header.component.html',
    styleUrls: ['./competittors-header.component.scss']
})
export class CompetittorsHeaderComponent implements OnInit {
    public numberOfcompetittors: number;
    @Input() competittorsGlobalData: ICompetittorsHeader;

    constructor() {}

    ngOnInit(): void {}
}
