import { keyOfGetAsin } from './../../model/ui-interfaces';
import { GetReportByAsinAction } from './../../store/actions/report.actions';
import { GetSearchedAsinService } from './../../services/get-searched-asin.service';

import { selectReportStatus } from './../../store/selectors/status.selector';
import { IReportState } from './../../store/state/report.state';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportStatus } from '../../enums/ReportStatus';
import { AuthServiceService } from '../../../app/services/auth-service.service';
import { alibabarelevantSuppliersSelector } from '../../../app/store/selectors/alibaba.selector';
import { HandlePopupsService } from '../../../app/services/handle-popups.service';
import { ThemeService } from '../../../app/services/theme.service';
import { Theme } from '../../../assets/mocks/themes';
import { environment } from '../../../environments/environment.prod';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
    public closePopupWaitingClosed: boolean = true;
    public closePopupDataNotFound: boolean = false;
    public closePopupLazyLoadClosed: boolean = true;
    public closePopupSearchAgain: boolean = false;
    private getReportStatus$: Subscription;
    private relevantSuppliersSub$: Subscription;
    public status: string = '';
    public reportSection: string[] = [];
    public relevantSuppliers: number = 0;
    public searchedQueryParam: string;
    public searchedAsinFromSearch: string;
    public theSearchedAsin: string;
    public closePopTutorial: boolean = false;
    public videoLink: string = '';
    public userLoggedIn: boolean;
    public demoAsin: string = 'B07VTM2MYQ';

    public toggleMenuOfConfigurations: boolean;

    public getActiveTheme$: Observable<Theme>;
    private getActiveThemeSub: Subscription;

    public headerLogoOfTheme: string = './assets/images/bloooom-logo.svg';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<IReportState>,
        private searchedAsin: GetSearchedAsinService,
        private authService: AuthServiceService,
        private popusService: HandlePopupsService,
        private themeService: ThemeService
    ) { }

    ngOnInit(): void {


        this.getActiveTheme$ = this.themeService.getActiveTheme$;
        this.getActiveThemeSub = this.getActiveTheme$.subscribe((res: Theme) => {
            if (res) {
                this.headerLogoOfTheme = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/logo.svg';
            }
        });

        this.popusService.setLazyLoadPopupStatus(true);

        this.authService.isUserLoggedIn().then((res: boolean) => {
            this.userLoggedIn = res;

            this.route.queryParamMap.subscribe((params: any) => {
                if (!this.userLoggedIn) {
                    this.searchedQueryParam = params.get(keyOfGetAsin.asin);
                    this.theSearchedAsin = this.searchedQueryParam;
                    if (this.searchedQueryParam !== this.demoAsin) {
                        this.router.navigate(['dashboard/report'], { queryParams: { asin: this.demoAsin } });
                        this.theSearchedAsin = this.searchedAsin.getSearchedAsin();
                    }
                    this.store.dispatch(new GetReportByAsinAction(this.demoAsin));
                    this.searchedAsin.setSearchedAsin(this.demoAsin);
                    this.theSearchedAsin = this.searchedAsin.getSearchedAsin();
                } else {
                    this.searchedQueryParam = params.get(keyOfGetAsin.asin);

                    if (this.searchedQueryParam) {
                        this.searchedAsin.setSearchedAsin(this.searchedQueryParam);
                        this.searchedAsinFromSearch = this.searchedAsin.getSearchedAsin();
                        this.theSearchedAsin = this.searchedAsinFromSearch;
                        this.store.dispatch(new GetReportByAsinAction(this.searchedQueryParam));
                    }
                }

            });
        });
        this.getReportStatus$ = this.store.pipe(select(selectReportStatus)).subscribe((data: any) => {
            if (data && this.searchedQueryParam) {
                this.status = data['status'];
                this.reportSection = data['lazyLoadBySections'];
                if (this.status) {
                    if (this.status === ReportStatus.READY) {
                        this.closePopupWaitingClosed = true;
                        this.closePopupLazyLoadClosed = true;
                    } else if (this.status === ReportStatus.CORRUPTED) {
                        this.closePopupWaitingClosed = true;
                        this.closePopupDataNotFound = true;
                    } else if (this.status === ReportStatus.EXTENDED) {
                        this.closePopupWaitingClosed = true;
                        this.closePopupLazyLoadClosed = true;

                        this.popusService.setExendedPopup(true);
                    } else {
                        this.closePopupWaitingClosed = false;
                        const ifThereIsDataFromReportCollection: any[] = this.reportSection;
                        ifThereIsDataFromReportCollection.some((item: any) => {
                            if (item.status) {
                                this.closePopupWaitingClosed = true;
                                setTimeout(() => {
                                    this.closePopupLazyLoadClosed = false;
                                }, 500);
                            }
                            return false;
                        });
                    }
                }
            }
            // else {
            //     this.closePopupWaitingClosed = false;
            // }
        });

        this.relevantSuppliersSub$ = this.store.pipe(select(alibabarelevantSuppliersSelector)).subscribe((data: number) => {
            if (data) {
                this.relevantSuppliers = data;
            }
        });
    }

    popupWaitingClosed(event: boolean): void {
        this.closePopupWaitingClosed = event;
    }

    openPopTutBySection(value: string): void {
        this.closePopTutorial = !this.closePopTutorial;
        this.videoLink = value;
    }
    closePopTutorialFun(value: boolean): void {
        this.closePopTutorial = value;
    }

    // changeConfiguration(): void {
    //     if (this.themeService.isBloooomTheme()) {
    //         this.themeService.setMercorETheme();
    //     } else {
    //         this.themeService.setBloooomTheme();
    //     }
    // }

    ngOnDestroy(): void {
        this.getReportStatus$.unsubscribe();
        this.relevantSuppliersSub$.unsubscribe();
        // this.getActiveThemeSub.unsubscribe();
    }

    toggleConfigurationsSwitcher(): void {
        this.toggleMenuOfConfigurations = !this.toggleMenuOfConfigurations;
    }
}
