import { inputInAnimation } from './../../animations/inputAnimation';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthServiceService } from '../../../app/services/auth-service.service';

@Component({
    selector: 'get-premium',
    templateUrl: './get-premium.component.html',
    styleUrls: ['./get-premium.component.scss'],
    animations: [inputInAnimation]
})
export class GetPremiumComponent implements OnInit {
    playAnimation: boolean = false;
    isCurrentInput: boolean = false;
    inputVarName: string = '';
    getPremiumForm: FormGroup = this.fb.group({
        fullName: [''],
        email: [''],
        interests: ['']
    });
    labelInputName: any = ElabelInputName;

    public freeTrail: boolean = true;

    constructor(private fb: FormBuilder, private authService: AuthServiceService) { }

    public async signup(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.signUp();
        }
    }

    ngOnInit(): void {
        this.playAnimation = true;
    }

    formSubmit(): void { }

    moveLabel(e: any, inputName: any): void {
        switch (inputName.name) {
            case this.labelInputName.FULLNAME:
                this.inputVarName = inputName.name;
                break;
            case this.labelInputName.EMAIL:
                this.inputVarName = inputName.name;
                break;
            case this.labelInputName.INTERESTS:
                this.inputVarName = inputName.name;
                break;
            default:
                break;
        }
        if (e.type === 'focus') {
            this.playAnimation = true;
        } else {
            if (e.target.value !== '') {
                this.playAnimation = true;
            } else {
                this.playAnimation = false;
            }
        }
    }
}

export enum ElabelInputName {
    FULLNAME = 'full-name',
    EMAIL = 'email',
    INTERESTS = 'interests'
}
