import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBenefitsSlider } from '../../../app/model/ui-interfaces';
import { ScreenResizeService } from '../../../app/services/screen-resize.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],

})
export class PartnersComponent implements OnInit {
  public onExtraHeader: boolean;
  public displayHeader: boolean = false;
  @ViewChild('callToActionEl') callToActionEl: ElementRef;
  public benefitsForPartners: IBenefitsSlider[] = [] as IBenefitsSlider[];

  constructor(private scrollService: ScreenResizeService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.get('bloooomComponent.partnersSliders').subscribe((res: IBenefitsSlider[]) => {
      this.benefitsForPartners = Object.keys(res).map((key: any) => res[key]);
    });
  };

  public callToAction(): void {
    const topPosition = this.callToActionEl.nativeElement.offsetTop;
    this.scrollService.autoScroll('bottom', topPosition);
  };

}
