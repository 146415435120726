import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanActivate } from '@angular/router/src/utils/preactivation';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class GuardRedirectGuard implements CanActivate {

  public path: ActivatedRouteSnapshot[];
  public route: ActivatedRouteSnapshot;
  public redirectFrom: string = 'dashboard';
  constructor(private authService: AuthServiceService, private router: Router) {
  }

  async canActivate(): Promise<boolean> {
    const isUserLoggedIn: boolean = await this.authService.isUserLoggedIn();
    if (isUserLoggedIn) {
      const currentUrl = this.router.url;
      if (currentUrl.indexOf(this.redirectFrom) > 0) {
      } else {
        this.router.navigateByUrl('/' + this.redirectFrom);
      }
    }
    return true;
  }
}