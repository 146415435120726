import { SyncStatus } from "../../../app/model/google/newGoogleCampaignRequest";
import { EKeywordType, Engine, ICampaign, ICampaignsSummary, ICampaignTotal, TargetSite } from "../../../app/model/google/campaignForGoogle";
import CampaignForType from "../../../app/model/campaignForType";


export const compaignsData: ICampaign[] = [
    {
        platform: Engine.GOOGLE,
        targetSite: TargetSite.AMAZON,
        id: "590",
        idInEngine: null,
        syncStatus: SyncStatus.APPROVED,
        statusInEngine: null,
        campaignName: "B08RNNJN4P_1649193256429",
        advertiserCampaignType: "Search",
        biddingMethod: "MANUAL_CPC",
        uploadIndication: true,
        startDate: "2022-04-14T21:00:00.000Z",
        endDate: "2022-04-19T21:00:00.000Z",
        dailyBudget: 18,
        asin: 'B01N9U5FLM',
        title: 'Kids Makeup Kit for Little Girl, Washable Girls Makeup Kit with Portable Bag',
        mainImgUrl: 'https://m.media-amazon.com/images/I/81u60ikCy8L._AC_SX679_.jpg',
        totalDays: 10,
        totalBudget: 300,
        campaignForType: CampaignForType.GOOGLE_FOR_AMAZON,
        siteUrl: "https://www.amazon.com/dp/B08RNNJN4P",
        networks: [
            "SearchNetwork",
            "Search"
        ],
        locationsToTarget: [
            {
                id: "226",
                name: "United States"
            }
        ],
        languagesToTarget: [
            {
                name: "English"
            }
        ],
        sitelinksExtensions: [
            {
                id: "1930",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                devicePreference: 0,
                descriptionLine1: "Amazon A thoughtfully designed",
                descriptionLine2: "Great case  Thick enough but not",
                displayText: "Amazon A thoughtfully",
                destinationUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                parameters: []
            },
            {
                id: "1929",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                devicePreference: 0,
                descriptionLine1: "Best Seller",
                descriptionLine2: "3 18 22 Update  This case is still",
                displayText: "Best Seller",
                destinationUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                parameters: []
            },
            {
                id: "1928",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                devicePreference: 0,
                descriptionLine1: "starts revies 5",
                descriptionLine2: "Absolutely an great product  This",
                displayText: "starts revies 5",
                destinationUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                parameters: []
            },
            {
                id: "1927",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                devicePreference: 0,
                descriptionLine1: "start review 5",
                descriptionLine2: "I’m not a big case guy  They",
                displayText: "start review 5",
                destinationUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                parameters: []
            }
        ],
        calloutExtensions: [
            {
                id: "1225",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                calloutTextList: [
                    "Ergonomic Design for a"
                ],
                parameters: []
            },
            {
                id: "1224",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                calloutTextList: [
                    "Made to Fit  Our"
                ],
                parameters: []
            },
        ],
        imageAssetExtensions: {
            id: "317",
            idInEngine: null,
            syncStatus: SyncStatus.APPROVED,
            description: "Elando Crystal Clear Case Compatible with iPhone 12 12 Pro  Non Yellowing Shockproof Protective Phone Case Slim Thin  6 1 inch",
            assetUrl: "https://m.media-amazon.com/images/I/71vDmoWaCuL._AC_SX679_.jpg",
            parameters: []
        },
        priceExtensions: {
            id: "296",
            idInEngine: null,
            syncStatus: SyncStatus.APPROVED,
            currency: "USD",
            priceOfferingList: [
                {
                    header: "Today Only",
                    description: "Elando Crystal Clear Case Compatible with iPhone 12/12 Pro, Non-Yellowing Shockproof Protective Phone Case Slim Thin, 6.1 inch",
                    priceInMicros: 13990000,
                    currencyCode: "USD",
                    unit: 0,
                    finalUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                    finalMobileUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                    parameters: []
                },
            ],
            parameters: []
        },
        adGroups: [
            {
                adGroupId: "683",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                ads: [
                    {
                        adId: "659",
                        syncStatus: SyncStatus.APPROVED,
                        adType: "1",
                        displayUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                        path1: "cell phone case",
                        path2: "best-selling",
                        headline1: "cell phone case",
                        headline2: "phone case",
                        headline3: "iphone case",
                        headline4: "iphone 12 case",
                        headline5: "iphone 12 pro case",
                        headline6: "clear phone cases",
                        headline7: "iphone 12 phone case",
                        headline8: "phone cases amazon",
                        headline9: "iphone 12 cases amazon",
                        headline10: "iphone phone case",
                        headline11: null,
                        headline12: null,
                        headline13: null,
                        headline14: null,
                        headline15: null,
                        description1: "Elando Crystal Clear Case Compatible with iPhone 12 12 Pro  Non Yellowing Shockproof",
                        description2: "two day shipping",
                        parameters: []
                    }
                ],
                keywords: [
                    {
                        adKeywordsId: "6647",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "45786",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "98065",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                ],
                "parameters": []
            },
            {
                adGroupId: "683",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                ads: [
                    {
                        adId: "659",
                        syncStatus: SyncStatus.APPROVED,
                        adType: "1",
                        displayUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                        path1: "cell phone case",
                        path2: "best-selling",
                        headline1: "cell phone case",
                        headline2: "phone case",
                        headline3: "iphone case",
                        headline4: "iphone 12 case",
                        headline5: "iphone 12 pro case",
                        headline6: "clear phone cases",
                        headline7: "iphone 12 phone case",
                        headline8: "phone cases amazon",
                        headline9: "iphone 12 cases amazon",
                        headline10: "iphone phone case",
                        headline11: null,
                        headline12: null,
                        headline13: null,
                        headline14: null,
                        headline15: null,
                        description1: "Elando Crystal Clear Case Compatible with iPhone 12 12 Pro  Non Yellowing Shockproof",
                        description2: "two day shipping",
                        parameters: []
                    }
                ],
                keywords: [
                    {
                        adKeywordsId: "6647",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "45786",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "98065",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                ],
                "parameters": []
            },
            {
                adGroupId: "683",
                idInEngine: null,
                syncStatus: SyncStatus.APPROVED,
                ads: [
                    {
                        adId: "659",
                        syncStatus: SyncStatus.APPROVED,
                        adType: "1",
                        displayUrl: "https://www.amazon.com/dp/B08RNNJN4P",
                        path1: "cell phone case",
                        path2: "best-selling",
                        headline1: "cell phone case",
                        headline2: "phone case",
                        headline3: "iphone case",
                        headline4: "iphone 12 case",
                        headline5: "iphone 12 pro case",
                        headline6: "clear phone cases",
                        headline7: "iphone 12 phone case",
                        headline8: "phone cases amazon",
                        headline9: "iphone 12 cases amazon",
                        headline10: "iphone phone case",
                        headline11: null,
                        headline12: null,
                        headline13: null,
                        headline14: null,
                        headline15: null,
                        description1: "Elando Crystal Clear Case Compatible with iPhone 12 12 Pro  Non Yellowing Shockproof",
                        description2: "two day shipping",
                        parameters: []
                    }
                ],
                keywords: [
                    {
                        adKeywordsId: "6647",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "45786",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                    {
                        adKeywordsId: "98065",
                        idInEngine: null,
                        syncStatus: SyncStatus.APPROVED,
                        keywordType: EKeywordType.GENERAL,
                        keywords: "amazon cell phone case",
                        parameters: []
                    },
                ],
                "parameters": []
            }
        ]
    }
];



export const compaignsTotalData: ICampaignTotal = {
    active: 19,
    unactive: 6,
    googleToAmz: 12,
    googleToShopify: 15
}


export const compaignsSummaryData: ICampaignsSummary = {
    totalBudget: 2400,
    totalClicks: 1900
}
