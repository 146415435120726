import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatusCodes } from 'http-status-codes';
import { ENotificationsStatus } from '../../assets/data/notification.mock';
import { environment } from '../../environments/environment';
import AsyncResponse from '../dtos/AsyncResponse';
import GetAmpScoreFilter from '../dtos/GetAmpScoreFilter';
import GetKeywordResponse from '../dtos/getKeywordResponse';
import GetKeywordsFilter from '../dtos/GetKeywordsFilter';
import GetProductResearchProductFilter from '../dtos/GetProductResearchProductFilter';
import KeywordResearchTaskResultsDto from '../dtos/keywordResearchTaskResultsDto';
import PaginateResponse from '../dtos/paginateResponse';
import { PageType } from '../enums/PageType';
import { TaskType } from '../enums/TaskType';
import { GetKeywordAnalysisAction } from '../store/actions/task.actions';
import { KeywordAnalysisState } from '../store/state/keywordAnalysis.state';
import { AuthServiceService } from './auth-service.service';
import { CampaignService } from './campaign.service';
import GetKeywordHandler from './handler/GetKeywordHandler';
import GetProductResearchHandler from './handler/GetProductResearchHandler';
import { MarketPlaceService } from './MarketPlaceService';
import { NotificationsService } from './notifications.service';
import { ProgressBarService } from './progress-bar.service';
import { ToastrService } from './toastr.service';
import GetCampaignHandler from './handler/GetCampaignHandler'


@Injectable({
  providedIn: 'root'
})
export class PollingService {

  private static readonly FIRST_TIME: number = 0;
  private notificationService: NotificationsService;
  private productResearchNicheLatestTaskId: number;
  private productCampaignLatestTaskId: number;
  private wordMapperLatestTaskId: number;
  private wordMapperByAsinsLatestTaskId: number;
  private static readonly GLOBAL_TASK_ID: number = -1;

  constructor(private httpClient: HttpClient, private marketPlaceService: MarketPlaceService, private store: Store<KeywordAnalysisState>, private toastrService: ToastrService, public progressBarService: ProgressBarService) { }

  public setNotificationService(notificationService: NotificationsService) {
    this.notificationService = notificationService;
    this.startUserNotificationPolling();
  }

  private startUserNotificationPolling(): void {
    this.getUserNotifications();
    setInterval(async () => {
      this.getUserNotifications();
    }, environment.pollingServiceInterval);
  }

  private getUserNotifications(): void {
    const tokenExist = localStorage.getItem(AuthServiceService.TOKEN);
    if (tokenExist !== null) {
      this.notificationService.getNotifications();
    }
  }

  public getStatus(taskId: number, taskType: TaskType): void {
    this.getStatusInner(taskId);
  }

  public getStatusInner(taskId: number): void {
    this.marketPlaceService.getTaskStatus(taskId).subscribe(
      (response) => {
        if (response.status === ENotificationsStatus.IN_PROGRESS) {
          setTimeout(() => this.getStatusInner(taskId), environment.getTaskStatusTimeout);
          return;
        }
        this.publishTaskFinish(response, response.status);
      });
  }

  public callGetAmpScore(getAmpScoreFilter: GetAmpScoreFilter, paginateResponse: PaginateResponse, keywordResearchTaskResultsDtos: KeywordResearchTaskResultsDto[]) {

    this.marketPlaceService.getAmpScore(getAmpScoreFilter).toPromise().then(response => {

      if (response.status === ENotificationsStatus.IN_PROGRESS) {
        setTimeout(() => this.callGetAmpScore(getAmpScoreFilter, paginateResponse, keywordResearchTaskResultsDtos), environment.getTaskStatusTimeout);
        return;
      }

      for (let keywordResearchTaskResultsDto of keywordResearchTaskResultsDtos) {

        for (let keywordAmpScoreData of response.data) {

          if (keywordAmpScoreData.keyword === keywordResearchTaskResultsDto.keyword) {
            keywordResearchTaskResultsDto.ampScore = keywordAmpScoreData.ampScore;
            break;
          }
        }
      }

      let getKeywordResponse: GetKeywordResponse = new GetKeywordResponse();
      getKeywordResponse.paginateResponse = paginateResponse;
      getKeywordResponse.keywordResearchTaskResultsDtos = keywordResearchTaskResultsDtos;
      this.store.dispatch(new GetKeywordAnalysisAction(getKeywordResponse));

    }).catch(e => {

      this.progressBarService.removeProgressBar();
      if (e.error.httpStatus === StatusCodes.PAYMENT_REQUIRED) {
        this.toastrService.showError("Please contact Bloooom. Your monthly use plan limit is exceeded");
      } else {
        this.toastrService.showError("Some problem occurred. Please try again after some time or contact Bloooom.");
      }
    });
  }

  private publishTaskFinish(asyncResponse: AsyncResponse, status: String): void {
    if (asyncResponse.data.taskType === TaskType.KEYWORDS_FOR_PHRASES && this.isLatestWordMapperTaskId(asyncResponse.taskId)) {
      this.callGetKeywords(asyncResponse);
    }
    if (asyncResponse.data.taskType === TaskType.KEYWORDS_FOR_ASINS && this.isLatestWordMapperByAsinsTaskId(asyncResponse.taskId)) {
      this.callGetKeywords(asyncResponse);
    }
    if (asyncResponse.data.taskType === TaskType.GET_PRODUCT_RESEARCH_NICHE) {
      this.getProductResearchProduct(asyncResponse);
    }
    if (asyncResponse.taskType === TaskType.CAMPAIGN) {
      this.getCampaign(asyncResponse);
    }
  }

  private getCampaign(asyncResponse: AsyncResponse): void {
    console.log(asyncResponse)
    new GetCampaignHandler(PageType.CampaignManagerPage, this.marketPlaceService, this.httpClient, this.store, this, asyncResponse).process();
  }

  private getProductResearchProduct(asyncResponse: AsyncResponse): void {
    const getProductResearchProductFilter = new GetProductResearchProductFilter({ taskId: asyncResponse.taskId, page: 1, take: environment.getKeywordsTake });
    new GetProductResearchHandler(PageType.ProductResearchNichePage, this.marketPlaceService, getProductResearchProductFilter, this.httpClient, this.store, this).process();
  }

  private callGetKeywords(asyncResponse: AsyncResponse) {
    const getKeywordsFilter = new GetKeywordsFilter({ taskId: asyncResponse.taskId, page: 1, take: environment.getKeywordsTake });

    let pageType: PageType = PageType.WordMapper;

    if (asyncResponse.data.taskType === TaskType.KEYWORDS_FOR_ASINS) {
      pageType = PageType.WordMapperByAsins;
    }

    new GetKeywordHandler(pageType, this.marketPlaceService, this.store, getKeywordsFilter, this.httpClient).process();
  }

  //For word-mapper
  public isLatestWordMapperTaskId(taskId: number): boolean {
    return Number(this.wordMapperLatestTaskId) === Number(taskId);
  }
  public setLatestWordMapperTaskId(taskId: number): void {
    this.wordMapperLatestTaskId = taskId;
  }
  //END For word-mapper

  //For word-mapper-by-asins
  public isLatestWordMapperByAsinsTaskId(taskId: number): boolean {
    return Number(this.wordMapperByAsinsLatestTaskId) === Number(taskId);
  }
  public setLatestWordMapperByAsinsTaskId(taskId: number): void {
    this.wordMapperByAsinsLatestTaskId = taskId;
  }
  //END For word-mapper-by-asins

  public getLatestProductResearchTaskId(): number {
    return Number(this.productResearchNicheLatestTaskId);
  }
  public setLatestProductResearchTaskId(taskId: number): void {
    this.productResearchNicheLatestTaskId = Number(taskId);
  }
  public setNoProductResearchTaskId(): void {
    this.productResearchNicheLatestTaskId = PollingService.GLOBAL_TASK_ID;
  }
  public isProductResearchTaskIdMissing(): boolean {
    return this.productResearchNicheLatestTaskId === PollingService.GLOBAL_TASK_ID;
  }
  public setLatestCampaignTaskId(taskId: number): void {
    this.productCampaignLatestTaskId = Number(taskId);
  }
}
