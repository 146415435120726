import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IRelatedPoductsDTO } from '../../../app/model/report.interface';
import { fade, fadeOut } from '../../../app/animations/fade';
import { CompetittorsService, ICompetittorsHeader } from '../../../app/services/competittors.service';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
    animations: [fade, fadeOut]
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
    public productDetailsSub$: Subscription;
    public searchedProductData: IRelatedPoductsDTO;
    public allProductsData: IRelatedPoductsDTO[];
    public competittorProduct: IRelatedPoductsDTO;
    public iconForTooltip: any;
    public sliderIndex: number = 0;
    public competittorsGlobalData$: Observable<ICompetittorsHeader>;
    public fadeSlider: boolean = true;

    @Input() searchedAsin: string;

    constructor(private productDetailsService: CompetittorsService, private competittorsHeaderService: CompetittorsService) {}

    ngOnInit(): void {
        this.competittorsHeaderService.getDataOfCompetittors();
        this.competittorsGlobalData$ = this.competittorsHeaderService.compettitorsHeader$;

        this.productDetailsService.getDataOfProducts();
        this.productDetailsSub$ = this.productDetailsService.productsData$.subscribe((products: IRelatedPoductsDTO[]) => {
            if (products) {
               
                this.searchedProductData = products[0];
                // products.filter((product: IRelatedPoductsDTO) => product.asin === this.searchedAsin)[0];
                this.allProductsData = products.slice(1);
                this.competittorProduct = this.allProductsData[this.sliderIndex];
                console.log(this.searchedProductData);
            }
        });
        this.iconForTooltip = [
            { src: 'amazon-time-icon.svg', alt: 'time on amazon icon' },
            { src: 'sales-volume-icon.svg', alt: 'volume of sales icon' },
            { src: 'avg-price-icon.svg', alt: 'price chart icon' },
            { src: 'fees-icon.svg', alt: 'FBA fees icon' },
            { src: 'variations-icon.svg', alt: 'variations icon' },
            { src: 'review-factor-icon.svg', alt: 'review factor icon' }
        ];
    }
    ngOnDestroy(): void {
        this.productDetailsSub$.unsubscribe();
    }

    moveSlidesForCompetittorsProducts(rightOrLeft: string): void {
        const competittorsLengthArr: number = this.allProductsData.length - 1;
        if (rightOrLeft === 'right') {
            if (this.sliderIndex < competittorsLengthArr) {
                this.sliderIndex = this.sliderIndex + 1;
            } else {
                this.sliderIndex = 0;
            }
        } else {
            if (this.sliderIndex > 0) {
                this.sliderIndex = this.sliderIndex - 1;
            } else {
                this.sliderIndex = competittorsLengthArr;
            }
        }
        this.competittorProduct = this.allProductsData[this.sliderIndex];
        this.createFadeForSliderOfCompetittorProduct();
    }

    createFadeForSliderOfCompetittorProduct(): void {
        this.fadeSlider = false;
        setTimeout(() => {
            this.fadeSlider = true;
        }, 300);
    }
}
