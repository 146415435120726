import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { isEmpty } from 'lodash';
import { IMessageIdentifier } from '../events/events';

@Injectable({
    providedIn: 'root'
})
export class AuthServiceService {
    constructor(private keycloakService: KeycloakService) { }

    public static readonly TOKEN: string = 'token';
    public static readonly REFRESH_TOKEN: string = 'refreshToken';
    public static readonly ID_TOKEN: string = 'idToken';

    public isUserLoggedIn(): Promise<boolean> {
        return this.keycloakService.isLoggedIn();
    }

    public login(): Promise<void> {
        return this.keycloakService.login();
    }

    public signUp(): Promise<void> {
        return this.keycloakService.register();
    }

    public logOut(): Promise<void> {
        localStorage.removeItem(AuthServiceService.TOKEN);
        localStorage.removeItem(AuthServiceService.REFRESH_TOKEN);
        localStorage.removeItem(AuthServiceService.ID_TOKEN);
        return this.keycloakService.logout();
    }

    public async createPayloadWithUserCredentials(): Promise<IMessageIdentifier> {
        const messageIdentifier: IMessageIdentifier = {} as IMessageIdentifier;
        if (await this.keycloakService.isLoggedIn()) {
            messageIdentifier.sender = this.keycloakService.getUsername();
            messageIdentifier.token = await this.keycloakService.getToken();
        } else {
            // think about what to do here.
        }
        return messageIdentifier;
    }

    public async getUsername(): Promise<string> {
        if (await this.keycloakService.isLoggedIn()) {
            return this.keycloakService.getUsername();
        }
        return '';
    }

    public getToken(): Promise<string> {
        return this.keycloakService.getToken();
    }

    public getRoles(): string[] {
        return this.keycloakService.getUserRoles();
    }
}
