import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import * as d3 from 'd3';
@Component({
    selector: 'app-graph-bars',
    templateUrl: './graph-bars.component.html',
    styleUrls: ['./graph-bars.component.scss']
})
export class GraphBarsComponent implements OnInit, AfterViewInit {
    @Input() getSalesVolumeByPrice: any;
    @Input() getSalesVolumeByPriceLabels: string[] = [];
    @Input() svgId: string;
    @Input() chartDescription: string;
    @Input() headerChart: { title: string; content: string };
    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if (this.getSalesVolumeByPrice) {
            this.buildChart(this.getSalesVolumeByPriceLabels);
        }
    }

    buildChart(getSalesVolumeByPriceLabels: any): void {
        const data: any = this.getSalesVolumeByPrice.points;
        const delta: number = data[0].x - this.getSalesVolumeByPrice.startX;
        const svg: any = d3.select('svg#' + this.svgId);
        let height: number = +svg.attr('height');
        let width: number = +svg.attr('width');
        width = 0.8 * width;
        height = 0.8 * height;
        const margin: any = { top: 0.1 * width, right: 0.1 * width, bottom: 0.1 * width, left: 0.15 * width };
        svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('fill', 'pink')
            .attr('width', width)
            .attr('height', height);

        const xScale: any = d3
            .scaleLinear()
            .range([0, width])
            .domain([0, d3.max(data, (d: any) => d.x)]);

        const yScale: any = d3
            .scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, (d: any) => parseFloat(d.y))]);

        // create axis objects
        const xAxis: any = d3.axisBottom(xScale);
        const yAxis: any = d3.axisLeft(yScale);

        // Draw Axis
        svg.append('g')
            .attr('transform', 'translate(' + margin.left + ',' + (margin.top + height) + ')')
            .attr('class', 'axis axis--x')
            .call(xAxis);
        svg.append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .call(yAxis);
        // labels of axises x and y
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0)
            .attr('x', 0 - (height + margin.top + margin.bottom) / 2)
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '12px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[0]);

        svg.append('text')
            .attr('transform', 'translate(' + (width + margin.left + margin.right) / 2 + ' ,' + (height + margin.top + margin.bottom) + ')')
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '12px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[1]);
        const Tooltip: typeof d3 = d3
            .select('#chart' + this.svgId)
            .style('position', 'relavite')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            // .style('background-color', '#39214f')
            .style('border-radius', '20px')
            .style('height', '220px')
            .style('position', 'absolute')
            .style('top', '0')
            .style('padding', '10px');

        const mouseover: typeof d3 = function(): any {
            d3.select(this).attr('fill', 'rgba(255, 255, 255, .8)');

            Tooltip.style('opacity', 1);
            Tooltip.style('display', 'block');
        };

        const mousemove: typeof d3 = function(d: any): any {
            Tooltip.html(
                `<div class="tooltip-data">
                <div class="chart-tooltip-revenue">
                <span class="title">${getSalesVolumeByPriceLabels[1]}</span>
                <span class="data">${d.x}</span>
                </div>
                <div class="chart-tooltip-avg-price">
                <span class="title">${getSalesVolumeByPriceLabels[0]}</span>
                <span class="data">${d.y}%</span>
                </div>
                </div>
            `
            )
                .style('left', d3.mouse(this)[0] - 70 + 'px')
                .style('top', d3.mouse(this)[1] - 230 + 'px');
        };
        const mouseleave: typeof d3 = function(): any {
            d3.select(this).attr('fill', 'rgba(255,255,255,.3)');
            Tooltip.style('opacity', 0);
            Tooltip.style('display', 'none');
        };

        // Draw Datapoints
        const points_g: any = svg
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .attr('clip-path', 'url(#clip)')
            .classed('points_g', true);

        // var data = genRandomData(n, max);
        let points: any = points_g.selectAll('.bar').data(data);

        points = points
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', 'rgba(255,255,255,.3)')
            .style('cursor', 'pointer')
            .attr('x', function(d: any): number {
                return xScale(d.x - delta);
            })
            .attr('width', function(d: any): number {
                return xScale(delta);
            })
            .attr('y', function(d: any): number {
                return yScale(parseFloat(d.y));
            })
            .attr('height', function(d: any): number {
                return height - yScale(parseFloat(d.y));
            })
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseleave', mouseleave);
    }
}
