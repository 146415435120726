import { Component, Input } from '@angular/core';
import { IBenefitsSlider } from '../../..//app/model/ui-interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'benefits-section',
    templateUrl: './benefits-section.component.html',
    styleUrls: ['./benefits-section.component.scss']
})
export class BenefitsSectionComponent {
    @Input() benefits: IBenefitsSlider[] = [] as IBenefitsSlider[];
    @Input() title: string = '';
    @Input() description: string = '';
}
