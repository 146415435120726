export enum TaskType {
    GET_REPORT = 'get_report',
    PRODUCTS_BY_FILTER = 'products_by_filter',
    PRODUCT_DATA = 'product_data',
    KEYWORDS_FOR_ASINS = 'keywords_for_asins',
    KEYWORDS_FOR_PHRASES = 'keywords_for_phrases',
    GET_PRODUCT_RESEARCH_NICHE = 'get_product_research_niche',
    AMP_SCORE = 'amp_score',
    CAMPAIGN = 'create_google_campaign',
    CUSTOMER_ACTION_SET_STATUS = 'customer_action_set_status',
    UPLOAD_CAMPAIGN = 'upload_campaign',
}
