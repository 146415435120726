import { isNullOrUndefined } from 'util';
import { IReportDTO, ProductsPriceRange } from '../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

// status selectors *****

export const selectReportStatus: any = createSelector(selectReportsState, (state: IReportDTO): any => {
    if (state) {
        const reportStatus: string = state.status;
        const reportSection: string[] = state.reportSection;
        if (!isNullOrUndefined(reportStatus)) {
            return { status: reportStatus, lazyLoadBySections: reportSection };
        } else {
            return null;
        }
    }
});

export const lengthOfRelatedProductsSelector: any = createSelector(selectReportsState, (state: IReportDTO): number => {
    if (state) {
        const lengthOfRelatedProducts: number = state.amountOfRelatedPoducts;
        if (!isNullOrUndefined(lengthOfRelatedProducts)) {
            return lengthOfRelatedProducts;
        } else {
            return null;
        }
    }
});

// ProductsPriceRange
export const selectReportProductsPriceRangeSelector: any = createSelector(
    selectReportsState,
    (state: IReportDTO): ProductsPriceRange => {
        if (state) {
            const ProductsPriceRange: ProductsPriceRange = state.productsPriceRange;
            if (!isNullOrUndefined(ProductsPriceRange)) {
                return ProductsPriceRange;
            } else {
                return null;
            }
        }
    }
);

export const marketNamesSelector: any = createSelector(selectReportsState, (state: IReportDTO): string => {
    if (state) {
        return state.productDescription;
    }
});

marketNamesSelector.release();
