import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignService } from '../../../app/services/campaign.service';
import { ICampaign, ICampaignsSummary, ICampaignTotal } from '../../../app/model/google/campaignForGoogle';
import { HandlePopupsService } from '../../../app/services/handle-popups.service';
import { ProductBoosterService } from '../../../app/services/product-booster.service';
import { pluck } from 'rxjs/operators';

@Component({
  selector: 'app-product-launcher',
  templateUrl: './product-launcher.component.html',
  styleUrls: ['./product-launcher.component.scss']
})
export class ProductLauncherComponent implements OnInit {
  public campaignsData$: Observable<ICampaign[]>;
  public campaignsTotal$: Observable<ICampaignTotal>;
  public campaignsSummary$: Observable<ICampaignsSummary>;
  public timeSteps: any;
  public handlePopup$: Observable<boolean>;

  constructor(private campaignService: CampaignService, private productBoosterService: ProductBoosterService, private handlePopup: HandlePopupsService) { }

  ngOnInit(): void {
    this.handlePopup$ = this.handlePopup.editCampaignPopup$;
    this.timeSteps = [
      { key: 'days60', value: 'Last 60 days' },
      { key: 'days30', value: 'Last 30 days' },
      { key: 'days7', value: 'Last 7 days' }
    ]
    this.productBoosterService.getCampaignsData();
    this.campaignsData$ = this.campaignService.getCampaigns().pipe(pluck('data'))
    this.productBoosterService.getCampaignsTotal();
    this.campaignsTotal$ = this.productBoosterService.campaignsTotal$;
    this.productBoosterService.getCampaignsSummary();
    this.campaignsSummary$ = this.productBoosterService.campaignsSummary$;



  }

  public createCampaign(): void {
    this.handlePopup.setCreateCampaignPopupStatus(true);
  }

  public editCampaign(): void {
    this.handlePopup.setEditCampaignPopupStatus(true);
  }

  public getChoosenItem(choosenItem: any): void {
    console.log(choosenItem);
  }

}
