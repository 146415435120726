import { isNullOrUndefined } from 'util';
import { IReportDTO, IKewordsDTO } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';
import { KeywordAnalysisState } from '../state/keywordAnalysis.state';
import { selectBestKewordSelector, selectKeywordsByAsinsFromAnalysisState, selectKeywordsFromAnalysisState, selectMainWordAndTopProductsAnalysisSelector, selectProductResearchFromAnalysisState, selectProductResearchNicheFromAnalysisState } from '../reducers/task.reducers';
import GetKeywordResponse from '../../dtos/getKeywordResponse';
import TopProductResponse from '../../dtos/TopProductResponse';
import { GetBestKeywordAction, GetKeywordAnalysisByAsinsAction, GetProductResearchAction, GetProductResearchNicheAction } from '../actions/task.actions';

export const keywordsSelector: any = createSelector(selectReportsState, (state: IReportDTO): IKewordsDTO[] => {
    if (state) {
        const keywords: any = state.keywords;
        if (!isNullOrUndefined(keywords)) {
            return keywords;
        }
    }
});

export const keywordsAnalysisSelector: any = createSelector(selectKeywordsFromAnalysisState, (state: KeywordAnalysisState): GetKeywordResponse => {
    if (state) {
        const keywords: GetKeywordResponse = state;
        if (keywords)
            return keywords;
    }
});


export const keywordsAnalysisByAsinsSelector: any = createSelector(selectKeywordsByAsinsFromAnalysisState, (state: GetKeywordAnalysisByAsinsAction): GetKeywordAnalysisByAsinsAction => {
    if (state) {
        const keywordsByAsins: GetKeywordAnalysisByAsinsAction = state;
        if (keywordsByAsins)
            return keywordsByAsins;
    }
});


export const mainWordAndTopProductsAnalysisSelector: any = createSelector(selectMainWordAndTopProductsAnalysisSelector, (state: TopProductResponse): TopProductResponse => {
    if (state) {
        const topProducts: TopProductResponse = state;
        if (topProducts)
            return topProducts;
    }
});


export const bestKewordSelector: any = createSelector(selectBestKewordSelector, (state: GetBestKeywordAction): GetBestKeywordAction => {
    if (state) {
        const topProducts: GetBestKeywordAction = state;
        if (topProducts)
            return topProducts;
    }
});


export const keywordsSummery: any = createSelector(selectReportsState, (state: IReportDTO): IKewordsDTO[] => {
    if (state) {
        const keywordsSummery: any = state.keywordsSummery;
        if (!isNullOrUndefined(keywordsSummery)) {
            return keywordsSummery;
        }
    }
});

export const productResearchSelector: any = createSelector(selectProductResearchFromAnalysisState, (state: GetProductResearchAction): GetProductResearchAction => {
    if (state) {
        const productResearch: GetProductResearchAction = state;
        if (productResearch)
            return productResearch;
    }
});

export const productResearchNicheSelector: any = createSelector(selectProductResearchNicheFromAnalysisState, (state: GetProductResearchNicheAction): GetProductResearchNicheAction => {
    if (state) {
        const productResearchNiche: GetProductResearchNicheAction = state;
        if (productResearchNiche)
            return productResearchNiche;
    }
});

keywordsSelector.release();
keywordsAnalysisSelector.release();
keywordsAnalysisByAsinsSelector.release();
mainWordAndTopProductsAnalysisSelector.release();
bestKewordSelector.release();
keywordsSummery.release();
productResearchSelector.release();
productResearchNicheSelector.release();
