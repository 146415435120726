import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-initialze-data-table',
  templateUrl: './initialze-data-table.component.html',
  styleUrls: ['./initialze-data-table.component.scss']
})
export class InitialzeDataTableComponent implements OnInit {

  @Input() mainText: string;
  @Input() secoundText: string;
  @Input() image: string;

  constructor() { }

  ngOnInit() {
  }

}
