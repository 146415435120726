import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',

})
export class ToggleMenuService {
    private toggleMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public toggleMenu$: Observable<boolean> = this.toggleMenuSubject.asObservable();
    public isToggleMenu: boolean = false;

    constructor() { }

    toggleMenuHandle(): boolean {
        this.isToggleMenu = !this.isToggleMenu;
        this.toggleMenuSubject.next(this.isToggleMenu);
        return this.isToggleMenu;
    }
}
