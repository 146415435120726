import { ScreenResizeService } from './../../services/screen-resize.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToggleMenuService } from '../../services/toggle-menu.service';
import { TranslateService } from '@ngx-translate/core';
import { IBenefitsSlider } from '../../../app/model/ui-interfaces';
import { slideUpDown } from '../../../app/animations/slides';
@Component({
    selector: 'app-bloooom',
    templateUrl: './bloooom.component.html',
    styleUrls: ['./bloooom.component.scss'],
    animations: [slideUpDown]
})
export class BloooomComponent implements OnInit {
    screenScroll: number = 0;
    showDialog: boolean = false;
    displayHeader: boolean = false;

    public isMenuOpen$: Observable<boolean>;
    public onExtraHeader: boolean;
    public routerSub: Subscription;
    public benefitsForHomepage: IBenefitsSlider[] = [] as IBenefitsSlider[];
    constructor(private screenScrollService: ScreenResizeService, private toggleMenuHandle: ToggleMenuService, private router: Router, private translate: TranslateService, private location: Location) {
        this.screenScrollService.scroll$.subscribe((pos: number) => {
            this.screenScroll = pos;
        });
    };

    ngOnInit(): void {
        this.translate.get('bloooomComponent.benefitsSliders').subscribe((res: IBenefitsSlider[]) => {
            this.benefitsForHomepage = Object.keys(res).map((key: any) => res[key]);
        });
        this.isMenuOpen$ = this.toggleMenuHandle.toggleMenu$;
        this.routerSub = this.router.events.subscribe(() => {
            if (this.location.path() !== '/bloooom') {
                this.onExtraHeader = true;
            } else {
                this.onExtraHeader = false;
            }
        });
    }

    @HostListener('window:scroll', ['$event']) onWindowScroll(): void {
        if (this.screenScroll > 200) {
            this.displayHeader = true;
        } else {
            this.displayHeader = false;
        }
    }
}
