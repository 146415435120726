import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    pageTitle: string = 'Contact Us';
    pageContent: string = 'Please feel free to contact us in any case.';
    isContanctForm: boolean = true;
    crumbs: any = ['Home', this.pageTitle];
    constructor() {}

    ngOnInit(): void {}
}
