import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/**
 * Reference from
 * https://github.com/michaelbromley/ngx-pagination
 */
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobxAngularModule } from 'mobx-angular';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './utils/app-init.ts';
import { BloooomComponent } from './layouts/bloooom/bloooom.component';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { applicationReducers } from './store/reducers/application.reducers';
import { ReportEffects } from './store/effects/report.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReportService } from './services/report.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PruningTranslationLoader } from './services/pruning-translation-loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportComponent } from './layouts/report/report.component';
import { SignupComponent } from './layouts/signup/signup.component';
import { SubscriptionComponent } from './layouts/subscription/subscription.component';
import { PurchaseSuccessfulComponent } from './layouts/purchase-successful/purchase-successful.component';
import { ContactUsComponent } from './layouts/contact-us/contact-us.component';
import { AboutComponent } from './layouts/about/about.component';
import { FaqComponent } from './layouts/faq/faq.component';
import { UsermanualComponent } from './layouts/usermanual/usermanual.component';
import { TermsComponent } from './layouts/terms/terms.component';
import { PolicyComponent } from './layouts/policy/policy.component';
import { HeaderSectionContentPageComponent } from './layouts/components/header-section-content-page/header-section-content-page.component';
import { ScoreMethodBuilderComponent } from './layouts/score-method-builder/score-method-builder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeService } from './services/theme.service';
import { LoginCheckComponent } from './core/login-check/login-check.component';
import { TokenInterceptor } from './utils/TokenInterceptor';
import { MentorsComponent } from './layouts/mentors/mentors.component';
import { MarketplaceCardComponent } from './core/marketplace-card/marketplace-card.component';
import { KeywordsAnalysisComponent } from './layouts/keywords-analysis/keywords-analysis.component';
import { MentorLandingPageComponent } from './layouts/mentor-landing-page/mentor-landing-page.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { DashboardHeaderComponent } from './layouts/dashboard/dashboard-header/dashboard-header.component';
import { MainOverviewComponent } from './layouts/dashboard/main-overview/main-overview.component';
/**
 * Reference from 
 * https://www.npmjs.com/package/ng6-toastr-notifications
 */
import { ToastrModule } from 'ng6-toastr-notifications';
import { OrderByPipe } from './pipes/orderByPipe';
import { ProductResearchComponent } from './layouts/product-research/product-research.component';
import { ProductResearchNicheComponent } from './layouts/product-research-niche/product-research-niche.component';
import { KeywordAnalysisAsinsComponent } from './layouts/keyword-analysis-asins/keyword-analysis-asins.component';
import { PartnersComponent } from './layouts/partners/partners.component';


export function httpLoaderFactory(http: HttpClient): PruningTranslationLoader {
    return new PruningTranslationLoader(http, '/assets/i18n/', `.json`);
}

@NgModule({
    declarations: [
        AppComponent,
        BloooomComponent,
        ReportComponent,
        SignupComponent,
        SubscriptionComponent,
        PurchaseSuccessfulComponent,
        ContactUsComponent,
        AboutComponent,
        FaqComponent,
        UsermanualComponent,
        TermsComponent,
        PolicyComponent,
        HeaderSectionContentPageComponent,
        ScoreMethodBuilderComponent,
        LoginCheckComponent,
        MentorsComponent,
        MarketplaceCardComponent,
        KeywordsAnalysisComponent,
        MentorLandingPageComponent,
        DashboardComponent,
        DashboardHeaderComponent,
        MainOverviewComponent,
        OrderByPipe,
        ProductResearchComponent,
        ProductResearchNicheComponent,
        KeywordAnalysisAsinsComponent,
        PartnersComponent
    ],
    imports: [
        NgbModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MobxAngularModule,
        KeycloakAngularModule,
        StoreModule.forRoot(applicationReducers),
        EffectsModule.forRoot([ReportEffects]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        environment.production ? [] : StoreDevtoolsModule.instrument(),
        NgxPaginationModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            deps: [KeycloakService, ReportService, ThemeService],
            multi: true,
            useFactory: initializer
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
