import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberOfStarsToArray'
})
export class NumberOfStarsToArrayPipe implements PipeTransform {
    transform(value: number, args?: any): any {
        const starsOfArray: { star: number; isFull: boolean }[] = [];
        const valueToString: string[] = value.toString().split('.');
        const [integer, decimal] = [+valueToString[0], +valueToString[1]];
        if (integer > 0) {
            for (let i: number = 0; i < integer; i++) {
                starsOfArray.push({ star: i, isFull: true });
            }
            if (decimal > 0) {
                starsOfArray.push({ star: decimal, isFull: false });
            }
        } else {
            return [];
        }
        return starsOfArray;
    }
}
