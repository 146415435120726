import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { fade, fadeOut } from './../../../animations/fade';
import { RelatedProductsServiceService } from './../../../services/related-products-service.service';
import { ReviewsSummary } from '../../../model/report.interface';
import { ThemeService } from '../../../../app/services/theme.service';
import { Theme } from '../../../../assets/mocks/themes';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-reviews-insights',
    templateUrl: './reviews-insights.component.html',
    styleUrls: ['./reviews-insights.component.scss'],
    animations: [fade, fadeOut]
})
export class ReviewsInsightsComponent implements OnInit {
    public reviews$: Observable<any>;
    @Input() reviewSummary$: Observable<ReviewsSummary>;

    public reviewsInsights: any[] = ['sdf', 'sdf', 'sdf', 'sdf', 'sdf', 'sdf', 'sdf'];
    public stars: string[] = [];

    public starGood: string = 'assets/images/star-good.svg';
    public starBad: string = 'assets/images/star-bad.svg';
    public starSemi: string = 'assets/images/star-semi.svg';
    public reviewMisureIcon: string = 'assets/images/icon-reviws-measure.svg';
    public getActiveTheme$: Observable<Theme>;
    private getActiveThemeSub: Subscription;

    constructor(private themeService: ThemeService, private Reviews: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.reviews$ = this.Reviews.reviewsSubject;
        this.getActiveTheme$ = this.themeService.getActiveTheme$;
        this.getActiveThemeSub = this.getActiveTheme$.subscribe((res: Theme) => {
            if (res) {
                this.starGood = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-good.svg';
                this.starSemi = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-semi.svg';
                this.starBad = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/star-bad.svg';
                this.reviewMisureIcon = environment.baseBrandUrl.replace('{{BRAND}}', res.name) + '/icon-reviws-measure.svg';
            }
        });
    }
    makeStartsByStarsRate(stars: string): string[] {
        if (stars) {
            this.stars = [];
            const [int, dec] = stars.split('.');
            for (let i: number = 0; i < 5; i++) {
                if (i < parseInt(int, 10)) {
                    this.stars.push('good');
                } else if (parseInt(dec, 10) > 0 && i === parseInt(int, 10)) {
                    this.stars.push('semi');
                } else {
                    this.stars.push('bad');
                }
            }
            return this.stars;
        }
    }
}
