import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    public bgImageTermsPage: string = 'term-bg-image.png';
    constructor() {}

    ngOnInit(): void {}
}
