import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProductBoosterService } from '../../../app/services/product-booster.service';
import { HandlePopupsService } from '../../../app/services/handle-popups.service';
import { ICampaign } from '../../../app/model/google/campaignForGoogle';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignCardComponent implements OnInit {


  public isCampaignFinished: boolean;
  @Input() campaignData: ICampaign;

  constructor(private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.isCampaignFinished = this.campaignService.checkCampaginFinished(this.campaignData);
  }

  public uploadCampaign(): void {
    this.campaignService.uploadCampaigns(Number(this.campaignData.id));
    window.location.reload();
  }
}
