
export enum ENotificationsStatus {
    IN_PROGRESS = 'In progress',
    READY = 'Ready',
    FINISHED_WITH_ERRORS = 'Finished With Errors',
    FAILURE = 'FAILURE'
}

export interface INotifications {
    taskId: string | number,
    taskType: string,
    title: string,
    description: string,
    url?: string,
    status: ENotificationsStatus,
    isRead: boolean
    createdAt: string,
}

export const notificationsMock: INotifications[] = [
    {
        taskId: 46756,
        createdAt: '12/12/2022  16:50',
        taskType: '',
        title: 'Your report is beening prossess...',
        description: 'report for RUIUSHJK34 asin is now on proccess. its assume to finished about 4 minutes, and also you will get an email when it finished.',
        status: ENotificationsStatus.FAILURE,
        isRead: false
    },
    {
        taskId: 4568,
        createdAt: '12/12/2022  16:50',
        taskType: '',
        title: 'Your report request is completed',
        description: 'report for RUIUSHJK34 asin is finished. check it here: ',
        url: 'https://#',
        status: ENotificationsStatus.IN_PROGRESS,
        isRead: false
    },
    {
        taskId: 6465,
        taskType: '',
        createdAt: '12/12/2022  16:50',
        title: 'Your report request is canceled',
        description: 'report for RUIUSHJK34 asin is now on proccess. its assume to finished about 4 minutes, and also you will get an email when it finished.',
        status: ENotificationsStatus.FAILURE,
        isRead: true
    },
    {
        taskId: 9786,
        taskType: '',
        createdAt: '12/12/2022  16:50',
        title: 'Your report request is canceled',
        description: 'report for RUIUSHJK34 asin is now on proccess. its assume to finished about 4 minutes, and also you will get an email when it finished.',
        status: ENotificationsStatus.FAILURE,
        isRead: true
    }
]