import { IRelatedPoductsDTO } from './../../model/report.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'report-overview',
    templateUrl: './report-overview.component.html',
    styleUrls: ['./report-overview.component.scss']
})
export class ReportOverviewComponent implements OnInit {
    @Input() productReport: IRelatedPoductsDTO = null;
    ngOnInit(): void {}
}
