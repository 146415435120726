import { AuthServiceService } from './../../services/auth-service.service';
import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
// declare var $: JQueryStatic;

@Component({
    selector: 'app-subscription-plan',
    templateUrl: './subscription-plan.component.html',
    styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit, OnDestroy, AfterViewInit {
    public subscriptionPlanPrice: number = 60;
    public benefitsPlan: string[];
    protected translationSubs: Subscription;
    public isUserLogin: any;
    @Input() brandLogo: string;

    constructor(private translate: TranslateService, private authService: AuthServiceService) {
        this.translationSubs = this.translate.get('subscriptionPage.benefitsPlan').subscribe((res: string[]) => {
            this.benefitsPlan = Object.keys(res).map((key: any) => res[key]);
        });
    }

    ngOnInit(): void {
        this.isLoggedIn().then((isLogedIn: boolean) => {
            this.isUserLogin = isLogedIn;
        });
    }
    isLoggedIn(): Promise<boolean> {
        return this.authService.isUserLoggedIn();
    }
    setNewPrice(newPrice: number): void {
        this.subscriptionPlanPrice = newPrice;
    }
    ngAfterViewInit(): void {
        this.animationForIcon();
    }
    ngOnDestroy(): void {
        this.translationSubs.unsubscribe();
    }
    animationForIcon(): void {
        const allClouds: any = document.querySelectorAll('.circle');

        allClouds.forEach((element: any) => {
            element.style.animation = 'circlesMove ' + (Math.random() * (30 - 20) + 20) + 's ' + Math.random() * 3 + 's ease-in-out infinite';
        });
    }
}
