import { Pipe, PipeTransform } from '@angular/core';
import CampaignForType from '../model/campaignForType';
import { Engine, TargetSite } from '../model/google/campaignForGoogle';

@Pipe({
  name: 'extractPlatformAndTarget'
})
export class ExtractPlatformAndTargetPipe implements PipeTransform {

  transform(value: CampaignForType, type: string): Engine | TargetSite {
    switch (value) {
      case CampaignForType.GOOGLE_FOR_AMAZON:
        if (type === 'platform') {
          return Engine.GOOGLE;
        } else if (type == 'target') {
          return TargetSite.AMAZON;
        }
      default:
        return null
    }
  }

}
