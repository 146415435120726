import { IApplicationState } from './../state/application.state';
import { IReportDTO, GetProductData, IRelatedPoductsDTO } from './../../model/report.interface';
import { IReportState } from './../state/report.state';
import reportExample from '../../../assets/mocks/exmple.json';

import { initialReportState } from '../state/report.state';
import {
    ReportActions,
    ReportActionsEnum,
    GetReportByAsinAction,
    GetReportByAsinSuccessAction,
    GetReportByAsinFailureAction,
    ReportIsInProgressPartialReportReturnedAction,
    GetProductDataByAsinSuccessAction,
    GetProductDataByAsinFailureAction,
    GetUserExceededMonthlyThresholdAction,
    GetNicheByFiltersAction
} from '../actions/report.actions';
import { ReportStatus } from '../../enums/ReportStatus';

export function reportReducers(state: IReportState = initialReportState, action: ReportActions): IReportState {
    switch (action.type) {
        case ReportActionsEnum.GET_REPORT_BY_ASIN: {
            return handleGetReportByAsinAction(action, state);
        }
        case ReportActionsEnum.GET_REPORT_FINISH_SUCCESSFULLY: {
            return handleGetReportByAsinSuccessAction(action, state);
        }
        case ReportActionsEnum.REPORT_IN_PROGRESS_RETURN_PARTIAL: {
            return handleReportInProgressAction(action, state);
        }
        case ReportActionsEnum.GET_REPORT_BY_ASIN_FAILURE: {
            return handleGetReportByAsinFailureAction(action, state);
        }
        case ReportActionsEnum.GET_PRODUCT_DATA_BY_ASIN_FINISHED_SUCCESSFULLY: {
            return handleGetProductDataByAsinAction(action, state);
        }
        case ReportActionsEnum.GET_PRODUCT_DATA_BY_ASIN_FAILURE: {
            return handleGetProductDataByAsinFailureAction(action, state);
        }
        case ReportActionsEnum.USER_EXCEEDED_MONTHLY_THRESHOLD: {
            return handleGetUserExceededMonthlyThresholdAction(action, state);
        }
        case ReportActionsEnum.GET_NICHE_BY_FILTERS: {
            return handleGetNicheByFiltersAction(action, state);
        }
        default:
            const x = JSON.stringify(reportExample);
            return {
                ...JSON.parse(x),
                errorKey: 'ok',
                
            };
    }
}
function handleGetNicheByFiltersAction(action: GetNicheByFiltersAction, state: IReportState): IReportState {
    const report: IReportState = state;
    return {
        ...report,
        //  : report.nicheFinderRequest.push(action),
        errorKey: action.type
    };
}
function handleGetUserExceededMonthlyThresholdAction(action: GetUserExceededMonthlyThresholdAction, state: IReportState): IReportState {
    const report: IReportDTO = { ...state.report, status: ReportStatus.EXTENDED };
    return {
        report,
        errorKey: action.type
    };
}

function handleGetProductDataByAsinAction(action: GetProductDataByAsinSuccessAction, state: IReportState): IReportState {
    const tempState: any = { ...state };
    const variationByProduct: IRelatedPoductsDTO = getRelatedProductForVariation(tempState, action.product.asin);
    addVariationDataToRelatedProduct(variationByProduct, action);
    return {
        ...tempState
    };
}

function handleGetProductDataByAsinFailureAction(action: GetProductDataByAsinFailureAction, state: IReportState): IReportState {
    const tempState: any = { ...state };
    const variationByProduct: IRelatedPoductsDTO = getRelatedProductForVariation(tempState, action.asin);
    addFailureVariationDataToRelatedProduct(variationByProduct, action);
    return {
        ...tempState
    };
}

function addFailureVariationDataToRelatedProduct(variationByProduct: IRelatedPoductsDTO, action: GetProductDataByAsinFailureAction): void {
    if (variationByProduct) {
        const variations: GetProductData[] = [...(variationByProduct.getProductVariationsData || [])];
        variations.push({ asin: action.asin, errorMessage: action.errorMessage });
        variationByProduct.getProductVariationsData = variations;
    }
}

function addVariationDataToRelatedProduct(variationByProduct: IRelatedPoductsDTO, action: GetProductDataByAsinSuccessAction): void {
    if (variationByProduct) {
        const variations: GetProductData[] = [...(variationByProduct.getProductVariationsData || [])];
        variations.push(action.product);
        variationByProduct.getProductVariationsData = variations;
    }
}

function getRelatedProductForVariation(tempState: IReportState, asin: string): IRelatedPoductsDTO {
    return tempState.report.relatedProducts.find((relatedProduct: IRelatedPoductsDTO) => {
        const variation: any = relatedProduct.variations.find((variation: any) => variation.values.find((v: any) => v.asin === asin));
        return !!variation;
    });
}

function handleGetReportByAsinAction(action: GetReportByAsinAction, state: IReportState): IReportState {
    return {
        report: { reportAsin: action.asin } as IReportDTO,
        errorKey: null
    };
}

function handleGetReportByAsinSuccessAction(action: GetReportByAsinSuccessAction, state: IReportState): IReportState {
    if (state && state.report && state.report.reportAsin === action.report.reportAsin) {
        const report: IReportDTO = action.report;
        return {
            report,
            errorKey: null
        };
    }
    return {
        ...state
    };
}

function handleReportInProgressAction(action: ReportIsInProgressPartialReportReturnedAction, state: IReportState): IReportState {
    if (state && state.report && state.report.reportAsin === action.report.reportAsin) {
        return {
            report: action.report,
            errorKey: null
        };
    }
    return {
        ...state
    };
}

function handleGetReportByAsinFailureAction(action: GetReportByAsinFailureAction, state: IReportState): IReportState {
    
    if (state && state.report && action.asin === state.report.reportAsin) {
        const report: IReportDTO = { ...state.report, status: ReportStatus.CORRUPTED };
        return {
            report,
            errorKey: action.type
        };
    }
    return {
        ...state
    };
}

export const selectReportsState: any = (state: IApplicationState): IReportDTO => state.reportState.report;
