import { isEmpty } from 'lodash';
import { Variation } from './../../../model/report.interface';
import { fadeOut, fade } from './../../../animations/fade';
import { Observable, Subscription } from 'rxjs';
import { RelatedProductsServiceService, IProductInformation } from './../../../services/related-products-service.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'product-specs',
    templateUrl: './product-specs.component.html',
    styleUrls: ['./product-specs.component.scss'],
    animations: [fade, fadeOut]
})
export class ProductSpecsComponent implements OnInit, OnDestroy {
    public displayTooltip: boolean = false;
    public productInformation$: Observable<IProductInformation>;
    public variations$: Observable<Variation[]>;
    private variationsSubs$: Subscription;
    public variations: any[] = [];
    public variationsLength: number;
    public closePopVariations: boolean = false;

    constructor(private ProductInformation: RelatedProductsServiceService) {}

    ngOnInit(): void {
        this.productInformation$ = this.ProductInformation.productInformationSubject;
        // this.variations$ = this.productInformation$.pipe(takeUntil(this.productInformation$), pluck('variations'));
        this.variationsSubs$ = this.productInformation$.subscribe((productVariations: IProductInformation) => {
            if (productVariations && !isEmpty(productVariations.variations)) {
                this.variations = productVariations.variations;
                this.variationsLength = productVariations.variations.length;
            }
        });
    }
    closePopVariationsFn(value: boolean): void {
        this.closePopVariations = value;
    }
    handleTooltip(val: boolean): void {
        this.displayTooltip = val;
    }
    ngOnDestroy(): void {
        this.variationsSubs$.unsubscribe();
    }
}
