import { MarketAnalysisDto, Point } from './../../model/report.interface';
import { RelatedProductsServiceService } from './../../services/related-products-service.service';
import { BloooomScoreService } from './../../services/bloooom-score.service';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { fadeOut, fade } from './../../../app/animations/fade';
import { selectReportStatus } from '../../../app/store/selectors/status.selector';
import { Store, select } from '@ngrx/store';
import { IReportState } from '../../../app/store/state/report.state';

@Component({
    selector: 'app-market-analysis',
    templateUrl: './market-analysis.component.html',
    styleUrls: ['./market-analysis.component.scss'],
    animations: [fadeOut, fade],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketAnalysisComponent implements OnInit, OnDestroy {
    private static MISSING_PARAMETER: string = '---';
    private static FRACTION_DIGITS: number = 2;
    public bloooomScore$: Observable<number>;
    public marketInfo: any[] = [];
    public getSalesVolumeByPriceLabels: string[] = ['Sales Volume in units', 'Product Price ($)'];
    public getSalesReviewsByPriceLabels: string[] = ['Sales Volume in units', 'Time on Amazon store'];

    public getDataToGraphs$: Subscription;

    public salesVolumeByPrice: Point[] = [];
    public salesVolumeByMonths: Point[] = [];
    public legendsChart: any[] = [
        { title: 'Your searched ASIN', color: 'rgb(244,255,85)' },
        { title: 'Related products', color: 'rgba(255,255,255,.3)' }
    ];
    public headerChartOne: any = {
        title: 'Sales Volume by Product’s Price',
        content: 'This chart shows the competitor’s sales volume by their price. Click on a dot to get more informations or to generate a new report for the specific competitor.'
    };
    public headerChartTwo: any = {
        title: 'Sales Volume by Product’s Time on Amazon',
        content:
            'This chart shows the competitor’s sales volume by their time on amazon. Click on a dot to get more informations or to generate a new report for the specific competitor.'
    };
    public clockText: string =
        'Market Potential Profitability Score - based on the demand for the product, competition level, reviews, trends and more. A high score means you are on the “right track”, a low score means low probability to succeed in sales';

    public marketName: string;
    public isSpedometerFinished: boolean;
    public getReportStatus$: Observable<string | null>;
    public scoreSummary: { title: string; content: string } = { title: '', content: '' };

    constructor(private bloooomScore: BloooomScoreService, private marketAnalysisDto: RelatedProductsServiceService, private store: Store<IReportState>) {}
    ngOnInit(): void {
        this.getReportStatus$ = this.store.pipe(select(selectReportStatus));
        this.bloooomScore$ = this.bloooomScore.bloooomScoreData$;
        this.getDataToGraphs$ = this.marketAnalysisDto.marketAnalysisSubject.subscribe((data: any) => {
            if (data) {
                this.salesVolumeByPrice = data.marketMetaData.salesVolumeByPrice.points;
                this.salesVolumeByMonths = data.marketMetaData.salesVolumeByMonths.points;
                this.marketName = data.marketMetaData.marketName;
                this.marketInfo = [
                    {
                        title: 'Product Price',
                        range:
                            this.cleanParameterToFixed(data.marketMetaData.productPriceRange.min, MarketAnalysisComponent.FRACTION_DIGITS) +
                            ' - ' +
                            this.cleanParameterToFixed(data.marketMetaData.productPriceRange.max, MarketAnalysisComponent.FRACTION_DIGITS),
                        avarage: '$' + this.cleanParameterToFixed(data.marketMetaData.productPriceAvg, MarketAnalysisComponent.FRACTION_DIGITS)
                    },
                    {
                        title: 'Sales Volume',
                        range: this.cleanParameter(data.marketMetaData.salesVolumeRange.min) + ' - ' + this.cleanParameter(data.marketMetaData.salesVolumeRange.max),
                        avarage: this.cleanParameter(data.marketMetaData.salesVolumeAvg)
                    },
                    {
                        title: 'Monthly Revenue',
                        range:
                            '$' + this.cleanParameter(data.marketMetaData.monthlyRevenueRange.min) + ' - ' + '$' + this.cleanParameter(data.marketMetaData.monthlyRevenueRange.max),
                        avarage: '$' + this.cleanParameter(data.marketMetaData.monthlyRevenueAvg)
                    }
                ];
            } else {
                this.salesVolumeByPrice = [];
                this.salesVolumeByMonths = [];
                this.marketName = '';
                this.marketInfo = [];
                this.isSpedometerFinished = false;
            }
        });
    }

    summaryBloooomScore(score: number): void {
        const scoreRange: string =
            score >= 0 && score <= 20
                ? 'low'
                : score > 20 && score <= 40
                ? 'ok'
                : score > 40 && score <= 60
                ? 'good'
                : score > 60 && score <= 80
                ? 'great'
                : score > 80 && score <= 100
                ? 'wow'
                : 'wrong';

        this.scoreSummary.content = this.clockText;
        switch (scoreRange) {
            case 'low':
                this.scoreSummary.title = '0-20 | Needs hard work';
                break;
            case 'ok':
                this.scoreSummary.title = '20-40 | Risky but possible';
                break;
            case 'good':
                this.scoreSummary.title = '40-60 | Medium chance';
                break;
            case 'great':
                this.scoreSummary.title = '60-80 | Good opportunity';
                break;
            case 'wow':
                this.scoreSummary.title = '80-100 | High potential';
                break;
            default:
        }
    }
    spedometerFinished(value: boolean): void {
        this.isSpedometerFinished = value;
    }
    ngOnDestroy(): void {
        this.getDataToGraphs$.unsubscribe();
    }

    private cleanParameter(value: number): string {
        if (!isNullOrUndefined(value)) {
            return Number(value)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return MarketAnalysisComponent.MISSING_PARAMETER;
    }

    private cleanParameterToFixed(value: number, fractionDigits: number): string {
        if (!isNullOrUndefined(value)) {
            return Number(value).toFixed(fractionDigits);
        }
        return MarketAnalysisComponent.MISSING_PARAMETER;
    }
}
