import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkTotalDays'
})
export class CheckTotalDaysPipe implements PipeTransform {

  transform(value: string, endDate: string, startDate: string): any {

    const totalDays: number = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24));
    const daysLeft: number = totalDays - Math.floor((new Date(endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    const daysLeftValid: string | number = daysLeft >= totalDays ? 'END' : daysLeft < 0 ? '--' : daysLeft;
    return { totalDays, daysLeft: daysLeftValid };
  }

}
