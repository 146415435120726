import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { compaignsData, compaignsSummaryData, compaignsTotalData } from '../core/product-launcher/product-launcher.mock';
import { IAdGroups, ICampaign, ICampaignsSummary, ICampaignTotal } from '../model/google/campaignForGoogle';
import { CampaignService } from './campaign.service';

@Injectable({
  providedIn: 'root'
})
export class ProductBoosterService {

  private campaignsDataSub: BehaviorSubject<ICampaign[]> = new BehaviorSubject<ICampaign[]>(null);
  public campaignsData$: Observable<ICampaign[]> = this.campaignsDataSub.asObservable();


  private campaignsTotalSub: BehaviorSubject<ICampaignTotal> = new BehaviorSubject<ICampaignTotal>(null);
  public campaignsTotal$: Observable<ICampaignTotal> = this.campaignsTotalSub.asObservable();

  private campaignsSummarySub: BehaviorSubject<ICampaignsSummary> = new BehaviorSubject<ICampaignsSummary>(null);
  public campaignsSummary$: Observable<ICampaignsSummary> = this.campaignsSummarySub.asObservable();


  private campaignByIdSub: BehaviorSubject<ICampaign> = new BehaviorSubject<ICampaign>(null);
  public campaignById$: Observable<ICampaign> = this.campaignByIdSub.asObservable();

  campaignByEditZone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public campaignByEditZone$: Observable<any> = this.campaignByEditZone.asObservable();

  constructor(private campaignService: CampaignService) { }

  public getCampaignsData(): void {
    this.campaignService.getCampaigns().pipe(pluck('data')).subscribe((data: ICampaign[]) => {
      this.campaignsDataSub.next(data);
    })
  }

  public getCampaignDataById(campaignId: string, editZone: any, innerDataId?: string): void {
    this.campaignsData$.subscribe((data: ICampaign[]) => {
      const campaignData = data.find((item: ICampaign) => item.id === campaignId);
      this.campaignByIdSub.next(campaignData);
      let editZoneData = campaignData[editZone];
      if (innerDataId) {
        const innerEditZoneData = editZoneData.find((item: IAdGroups) => item.adGroupId === innerDataId);
        this.campaignByEditZone.next({ id: campaignData.id, editZone, data: innerEditZoneData });
      } else {
        this.campaignByEditZone.next({ id: campaignData.id, editZone, data: editZoneData });
      }
    })
  }

  public getCampaignsTotal(): void {
    this.campaignsTotalSub.next(compaignsTotalData);
  }

  public getCampaignsSummary(): void {
    this.campaignsSummarySub.next(compaignsSummaryData);
  }
}
