import { Component, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ToggleMenuService } from '../../../app/services/toggle-menu.service';

@Component({
    selector: 'app-menu-links',
    templateUrl: './menu-links.component.html',
    styleUrls: ['./menu-links.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    },
})
export class MenuLinksComponent implements OnInit, OnDestroy {

    public closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
    private initApp: boolean = false;

    constructor(private _eref: ElementRef, private toggleMenuHandle: ToggleMenuService) { }

    public ngOnInit(): void {
        setTimeout(() => {
            this.initApp = true;
        });
    }
    public onClick(event: Event): void {
        if (!this._eref.nativeElement.contains(event.target) && this.initApp)
            this.toggleMenuHandle.toggleMenuHandle();
    }
    public closeMenuFun(): void {
        this.toggleMenuHandle.toggleMenuHandle();
    }
    public ngOnDestroy(): void {
        this.closeMenu.emit(false)
    }

}
