import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DateValidators } from '../../../../app/validators/dateValidators';
import { fade } from '../../../../app/animations/fade';
import { HandlePopupsService } from '../../../../app/services/handle-popups.service';

import { TranslateService } from '@ngx-translate/core';
import { CampaignService } from '../../../../app/services/campaign.service';
import { PollingService } from '../../../../app/services/polling.service';
import { select, Store } from '@ngrx/store';
import { campaignSelector } from '../../../../app/store/selectors/campaign.selector';
import { CampaignsState } from '../../../../app/store/state/campaigns.state';
import { TaskType } from '../../../../app/enums/TaskType';
import { Engine, TargetSite } from '../../../../app/model/google/campaignForGoogle';
import { NewGoogleCampaignRequest, CampaignStatusInEngine } from '../../../../app/model/google/newGoogleCampaignRequest';
import { AuthServiceService } from '../../../../app/services/auth-service.service';
import { environment } from '../../../../environments/environment';
import * as qs from 'qs';
import CampaignForType from '../../../../app/model/campaignForType';
import AsyncResponse from '../../../dtos/AsyncResponse';
import { MarketPlaceService } from '../../../services/MarketPlaceService';

@Component({
  selector: 'app-create-campaign',
  templateUrl: './create-campaign.component.html',
  styleUrls: ['./create-campaign.component.scss'],
  animations: [fade]
})
export class CreateCampaignComponent implements OnInit {
  public handlePopup$: Observable<boolean>;
  public createCampaginForm: FormGroup;
  public targetOptions: { key: TargetSite, value: string }[] = [];
  public platformOptions: { key: Engine, value: string }[] = [];
  public choosenTarget: { key: TargetSite, value: string };
  public choosenPlatform: { key: Engine, value: string };
  public productAnchor: string = 'asin';
  public totalBudget: number = 10;
  public daysAmount: number = 0;
  public asinForProductData: string;
  private CHECK_ASIN_REX: RegExp = /^[0-9A-Z]{10}$/;
  private today: string = new Date().toISOString().slice(0, 10);
  public createCampaignSteps: string = 'details';
  public urlIframe: string;
  public statuses: any = CampaignStatusInEngine;
  public formValidation: boolean;
  public displayTooltip: number | null;
  public campaignManagementFee: number = 10;

  public statusesInfo: { status: CampaignStatusInEngine, text: string }[] = [
    { status: this.statuses.Active, text: '' },
    { status: this.statuses.Paused, text: '' },
    { status: this.statuses.Pending, text: '' },
    { status: this.statuses.Scheduled, text: '' },
    { status: this.statuses.Expired, text: '' },
    { status: this.statuses.Suspended, text: '' },
    { status: this.statuses.Disapproved, text: '' }
  ];



  public currentTaskId: number;
  public allCampaigns$: any;
  public userName: string = '';
  public campaignName: string = '';
  @ViewChild('paymentTranzila') paymentTranzila: ElementRef;


  constructor(private authService: AuthServiceService, private store: Store<CampaignsState>, private campaignService: CampaignService, public pollingService: PollingService, private fb: FormBuilder, private handlePopup: HandlePopupsService, private translate: TranslateService, private marketPlaceService: MarketPlaceService) { }


  public campaignCreation(): void {
    if (this.campaignService.getCreateCampaign()) {
      this.campaignService.createCampaign(this.campaignService.getCreateCampaign()).subscribe(response => {
        try {
          if (response) {
            this.pollingService.getStatus(response.taskId, TaskType.CAMPAIGN);
            this.campaignService.setCreateCampaign(null);
          }
        } catch (err) {
          console.log(err);
        }
      })
    }
  }

  public getToken(): Promise<string> {
    return this.authService.getToken();
  }

  getUsername(): Promise<string> {
    return this.authService.getUsername();
  }

  ngOnInit(): void {
    this.allCampaigns$ = this.store.pipe(select(campaignSelector)).subscribe((data: any) => {
      if (data) {
        console.log({ data })
      }
    });

    const brand = "bloooom_basic"
    this.marketPlaceService.getPricingAccordingBrand(brand).subscribe(
      (response: AsyncResponse) => {
        this.campaignManagementFee = response.data.brandPaymentMethod.campaignManagmentFee;
      },
      (error: any) => {
        console.log(`issue getting campaign managment fee ${error.message}`);
      }
    );

    this.createForm();
    this.targetOptions = [
      // { key: TargetSite.SHOPIFY, value: 'Shopify' },
      { key: TargetSite.AMAZON, value: 'Amazon' }
    ];

    this.platformOptions = [
      { key: Engine.GOOGLE, value: 'Google' },
      // { key: Engine.FACEBOOK, value: 'Facebook' },
      // { key: Engine.AMAZON, value: 'Amazon' },
      // { key: Engine.YOUTUBE, value: 'Youtube' }
    ];

    this.handlePopup$ = this.handlePopup.createCampaignPopupSubject;

    this.translate.get('createCampagin.statusesInfo').subscribe((res: { text: string }[]) => {
      Object.keys(res).map((key: string) => {
        this.statusesInfo[key].text = res[key].text
      });
    });
  }

  public createCampaing(): void {

    const newCampaign: NewGoogleCampaignRequest = this.getDataForCreateCampagin();
    this.campaignService.setCreateCampaign(newCampaign)
  }

  public getChoosenTarget(choosenData: { key: TargetSite, value: string }): void {
    this.choosenTarget = choosenData;
    this.productAnchor = choosenData.key === TargetSite.SHOPIFY ? 'URL' : 'asin'
  }

  public getChoosenPlatform(choosenData: { key: Engine, value: string }): void {
    this.choosenPlatform = choosenData;
  }

  private createForm(): void {
    this.createCampaginForm = this.fb.group({
      engine: [null, Validators.required],
      target: [null, Validators.required],
      asin: [null, [Validators.required,
      Validators.pattern(this.CHECK_ASIN_REX)]],
      asinRequested: [null, Validators.required],
      URL: [null],
      datePicker: this.fb.group({
        startDate: [this.today, Validators.required],
        endDate: [null, Validators.required],
      }, { validators: [DateValidators.fromToDate('startDate', 'endDate')] }),
      dailyBudget: [null, Validators.required]
    })
  }


  public getDataForCreateCampagin() {

    const dataForCampaign: NewGoogleCampaignRequest = {
      campaignName: this.campaignName,
      asin: `${this.createCampaginForm.get('asin').value}`,
      dailyBudget: this.createCampaginForm.get('dailyBudget').value,
      startDate: new Date(this.createCampaginForm.get('datePicker').get('startDate').value),
      endDate: new Date(this.createCampaginForm.get('datePicker').get('endDate').value),
      engine: (this.createCampaginForm.get('engine').value).value.toLowerCase(),
      campaignForType: CampaignForType.GOOGLE_FOR_AMAZON
      // campaignForType: (this.createCampaginForm.get('engine').value).value.toUpperCase() +'_FOR_'+(this.createCampaginForm.get('target').value).value.toUpperCase(),
    }
    return dataForCampaign;
  }
  public calculateBudget(): void {

    const start = new Date(this.createCampaginForm.get('datePicker').get('startDate').value);
    const end = new Date(this.createCampaginForm.get('datePicker').get('endDate').value);
    const budget = new Date(this.createCampaginForm.get('dailyBudget').value);
    if (start && end && budget) {
      this.daysAmount = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
      this.totalBudget = this.daysAmount * (+budget);
    }
  };

  public closePopupWaiting(): void {
    this.resetForm();
    this.createCampaignSteps = 'details';
    this.handlePopup.setCreateCampaignPopupStatus(false);
  };

  public getAsinForProductData(asin: string): void {
    if (this.CHECK_ASIN_REX.exec(asin)) {
      this.asinForProductData = asin;
      this.createCampaginForm.patchValue({ asinRequested: true });
    }
  };

  public onSubmit(form: FormGroup, e: Event): void {
    e.preventDefault();
    this.calculateBudget();

    if (form.valid) {
      this.createCampaignSteps = 'payment';
      this.formValidation = form.invalid;
      this.campaignName = `${this.createCampaginForm.get('asin').value}_${new Date().getTime()}`;
      this.createCampaing();
      this.getUsername().then((res: string) => {
        this.userName = res;
        this.getToken().then((token: string) => {
          const url: string = environment.paymentServiceUrl;
          if (this.paymentTranzila) {
            this.paymentTranzila.nativeElement.src += '';
          }
          this.urlIframe = `${url}?${qs.stringify({
            sum: this.totalBudget,
            currency: 2,
            email: this.userName,
            nologo: 1,
            cred_type: 1,
            tranmode: 'A',
            trButtonColor: 'ff00cf',
            ppnewwin: 2,
            campaignName: this.campaignName,
            token
          })}`;
        });
      });
    } else {
      this.formValidation = form.invalid;
    }


    this.validateAllFormFields(form);
    setTimeout(() => {
      this.campaignCreation();
    }, 30000);
  }


  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public backToForm(): void {
    this.createCampaignSteps = 'details';
  }
  public resetForm(): void {
    this.createCampaginForm.reset();
  }

  public handleTooltip(val: boolean, index: number): void {
    this.displayTooltip = index;
  }
}
