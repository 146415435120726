import { Console } from 'console';
import { KeywordAnalysisAsinsComponent } from '../layouts/keyword-analysis-asins/keyword-analysis-asins.component';
import { KeywordsAnalysisComponent } from '../layouts/keywords-analysis/keywords-analysis.component';
import { MarketPlaceService } from '../services/MarketPlaceService';
import Direction from './Direction';
import GetAmpScoreFilter from './GetAmpScoreFilter';
import MatchType from './MatchType';
import OrderBy from './OrderBy';
import PaginationFilter from './PaginationFilter';

export default class GetKeywordsFilter extends PaginationFilter {
    public taskId: number;
    public searchVolumeFrom: number;
    public searchVolumeTo: number;
    public finalScoreFrom: number;
    public finalScoreTo: number;
    public wordCountFrom: number;
    public wordCountTo: number;
    public competingProductsFrom: number;
    public competingProductsTo: number;
    public matchType: MatchType;
    public showKeywordsThatContain: string[];
    public excludeKeywordsThatContain: string[];
    public organicSearchPositionFrom: number;
    public organicSearchPositionTo: number;
    public sponsoredSearchPositionFrom: number;
    public sponsoredSearchPositionTo: number;
    public asins: string[];
    public orderBy: OrderBy[];
    public keyword: string[] = [];
    public limit: number;
    public rankingCompetitorsFrom: number;
    public rankingCompetitorsTo: number;

    public constructor(param?: Partial<GetKeywordsFilter>) {
        super();
        if (param) {
            this.page = param.page;
            this.take = param.take;
            this.taskId = param.taskId;
        }
    }

    public checkIsValid(keywordsAnalysisComponent: KeywordsAnalysisComponent) {

        //searchVolume
        if (!this.searchVolumeFrom || !this.searchVolumeTo) {
            if (!this.searchVolumeFrom)
                this.searchVolumeFrom = undefined;
            if (!this.searchVolumeTo)
                this.searchVolumeTo = undefined;
        } else if (parseInt(this.searchVolumeFrom.toString()) > parseInt(this.searchVolumeTo.toString()))
            return "SearchVolumeTo must be greater or equals than SearchVolumeFrom";

        //finalScore
        if (!this.finalScoreFrom || !this.finalScoreTo) {
            if (!this.finalScoreFrom)
                this.finalScoreFrom = undefined;
            if (!this.finalScoreTo)
                this.finalScoreTo = undefined;
        } else if (parseInt(this.finalScoreFrom.toString()) > parseInt(this.finalScoreTo.toString()))
            return "FinalScoreTo must be greater or equals than FinalScoreFrom";

        //wordCountTo
        if (!this.wordCountFrom || !this.wordCountTo) {
            if (!this.wordCountFrom)
                this.wordCountFrom = undefined;
            if (!this.wordCountTo)
                this.wordCountTo = undefined;
        } else if (parseInt(this.wordCountFrom.toString()) > parseInt(this.wordCountTo.toString()))
            return "WordCountTo must be greater or equals than WordCountFrom";

        //competingProductsFrom
        if (!this.competingProductsFrom || !this.competingProductsTo) {
            if (!this.competingProductsFrom)
                this.competingProductsFrom = undefined;
            if (!this.competingProductsTo)
                this.competingProductsTo = undefined;
        } else if (parseInt(this.competingProductsFrom.toString()) > parseInt(this.competingProductsTo.toString()))
            return "CompetingProductsTo must be greater or equals than CompetingProductsFrom";

        //showKeywordsThatContain
        if (!keywordsAnalysisComponent.showKeywordsThatContain)
            this.showKeywordsThatContain = undefined;
        else {
            this.showKeywordsThatContain = keywordsAnalysisComponent.showKeywordsThatContain.split(",");
        }

        //excludeKeywordsThatContain
        if (!keywordsAnalysisComponent.excludeKeywordsThatContain)
            this.excludeKeywordsThatContain = undefined;
        else {
            this.excludeKeywordsThatContain = keywordsAnalysisComponent.excludeKeywordsThatContain.split(",");
        }

        return '';
    }
    public checkIsValid1(keywordAnalysisAsinsComponent: KeywordAnalysisAsinsComponent) {

        //searchVolume
        if (!this.searchVolumeFrom || !this.searchVolumeTo) {
            if (!this.searchVolumeFrom)
                this.searchVolumeFrom = undefined;
            if (!this.searchVolumeTo)
                this.searchVolumeTo = undefined;
        } else if (parseInt(this.searchVolumeFrom.toString()) > parseInt(this.searchVolumeTo.toString()))
            return "SearchVolumeTo must be greater or equals than SearchVolumeFrom";

        //finalScore
        if (!this.finalScoreFrom || !this.finalScoreTo) {
            if (!this.finalScoreFrom)
                this.finalScoreFrom = undefined;
            if (!this.finalScoreTo)
                this.finalScoreTo = undefined;
        } else if (parseInt(this.finalScoreFrom.toString()) > parseInt(this.finalScoreTo.toString()))
            return "FinalScoreTo must be greater or equals than FinalScoreFrom";

        //wordCountTo
        if (!this.wordCountFrom || !this.wordCountTo) {
            if (!this.wordCountFrom)
                this.wordCountFrom = undefined;
            if (!this.wordCountTo)
                this.wordCountTo = undefined;
        } else if (parseInt(this.wordCountFrom.toString()) > parseInt(this.wordCountTo.toString()))
            return "WordCountTo must be greater or equals than WordCountFrom";

        //competingProductsFrom
        if (!this.competingProductsFrom || !this.competingProductsTo) {
            if (!this.competingProductsFrom)
                this.competingProductsFrom = undefined;
            if (!this.competingProductsTo)
                this.competingProductsTo = undefined;
        } else if (parseInt(this.competingProductsFrom.toString()) > parseInt(this.competingProductsTo.toString()))
            return "CompetingProductsTo must be greater or equals than CompetingProductsFrom";

        //showKeywordsThatContain
        if (!keywordAnalysisAsinsComponent.showKeywordsThatContain)
            this.showKeywordsThatContain = undefined;
        else {
            this.showKeywordsThatContain = keywordAnalysisAsinsComponent.showKeywordsThatContain.split(",");
        }

        //excludeKeywordsThatContain
        if (!keywordAnalysisAsinsComponent.excludeKeywordsThatContain)
            this.excludeKeywordsThatContain = undefined;
        else {
            this.excludeKeywordsThatContain = keywordAnalysisAsinsComponent.excludeKeywordsThatContain.split(",");
        }

        return '';
    }

    public sortVolume(isAsc: boolean) {

        this.orderBy = [];
        let or = new OrderBy();
        //sorting Volumn
        if (isAsc) {
            or.field = 'volume'
            or.direction = Direction.ASC;
        } else {
            or.field = 'volume';
            or.direction = Direction.DESC;
        }
        this.orderBy.push(or);
    }

    public clear(keywordsAnalysisComponent: KeywordsAnalysisComponent) {

        this.searchVolumeFrom = undefined;
        this.searchVolumeTo = undefined;

        this.finalScoreFrom = undefined;
        this.finalScoreTo = undefined;

        this.wordCountFrom = undefined;
        this.wordCountTo = undefined;

        this.competingProductsFrom = undefined;
        this.competingProductsTo = undefined;

        keywordsAnalysisComponent.showKeywordsThatContain = '';
        this.showKeywordsThatContain = undefined;

        keywordsAnalysisComponent.excludeKeywordsThatContain = '';
        this.excludeKeywordsThatContain = undefined;
    }
    public clear1(keywordAnalysisAsinsComponent: KeywordAnalysisAsinsComponent) {

        this.searchVolumeFrom = undefined;
        this.searchVolumeTo = undefined;

        this.finalScoreFrom = undefined;
        this.finalScoreTo = undefined;

        this.wordCountFrom = undefined;
        this.wordCountTo = undefined;

        this.competingProductsFrom = undefined;
        this.competingProductsTo = undefined;

        keywordAnalysisAsinsComponent.showKeywordsThatContain = '';
        this.showKeywordsThatContain = undefined;

        keywordAnalysisAsinsComponent.excludeKeywordsThatContain = '';
        this.excludeKeywordsThatContain = undefined;
    }
}


