import { Point } from './../../model/report.interface';
import { Component, OnInit, AfterViewInit, Input, HostListener, ElementRef, Renderer, ChangeDetectorRef, DoCheck } from '@angular/core';
import * as d3 from 'd3';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-graph-scatter',
    templateUrl: './graph-scatter.component.html',
    styleUrls: ['./graph-scatter.component.scss']
})
export class GraphScatterComponent implements OnInit, AfterViewInit {
    @Input() getDataForGraph: Point[];
    @Input() getSalesVolumeByPriceLabels: string[] = [];
    @Input() svgId: string;
    @Input() chartDescription: string;
    @Input() units: string = '';
    @Input() legendsChart: { title: string; color: string }[];
    @Input() headerChart: { title: string; content: string };
    public productDetails: any;

    constructor(private el: ElementRef) { }

    ngOnInit(): void { }
    ngAfterViewInit(): void {
        if (this.getDataForGraph) {
            this.buildChart(this.getSalesVolumeByPriceLabels);
        }
    }
    @HostListener('click', ['$event']) handleClick(event: Event): void {
        const part: any = this.el.nativeElement.querySelector('.close-pop-product');
        if (part === event.target) {
            this.el.nativeElement.querySelector('#popProduct').remove();
        }
    }
    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.el.nativeElement.querySelector('.pop-product').style.display = 'none';
        }
    }

    buildChart(getSalesVolumeByPriceLabels: any): void {
        const data: any = this.getDataForGraph;
        const units: string = this.units;
        const svg: any = d3.select('svg#' + this.svgId);
        let height: number = +svg.attr('height');
        let width: number = +svg.attr('width');
        width = 0.8 * width;
        height = 0.8 * height;
        const margin: any = { top: 0.1 * width, right: 0.2 * width, bottom: 0.1 * width, left: 0.2 * width };
        svg.append('defs')
            .append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('fill', 'pink')
            .attr('width', width)
            .attr('height', height);

        const xScale: any = d3
            .scaleLinear()
            .range([0, width])
            .domain([0, d3.max(data, (d: any) => Math.ceil(d.x / 10) * 10.2)]);

        const yScale: any = d3
            .scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, (d: any) => d.y * 1.1)]);

        // create axis objects
        const xAxis: any = d3.axisBottom(xScale);
        const yAxis: any = d3.axisLeft(yScale);

        // Draw Axis
        svg.append('g')
            .attr('transform', 'translate(' + margin.left + ',' + (margin.top + height) + ')')
            .attr('class', 'axis axis--x')
            .call(xAxis);
        svg.append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .call(yAxis);

        // labels of axises x and y
        // **y
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0)
            .attr('x', 0 - (height + margin.top + margin.bottom) / 2)
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[0]);

        // **x
        svg.append('text')
            .attr('transform', 'translate(' + (width + margin.left + margin.right) / 2 + ' ,' + (height + margin.top + margin.bottom - 10) + ')')
            .attr('dy', '.8em')
            .style('text-anchor', 'middle')
            .style('font-weight', 'bold')
            .style('font-size', '14px')
            .style('fill', 'rgba(255, 255, 255, 0.5)')
            .text(this.getSalesVolumeByPriceLabels[1]);

        const Tooltip: typeof d3 = d3
            .select('#chart' + this.svgId)
            .style('position', 'relavite')
            .append('div')
            .style('opacity', 0)
            .attr('class', 'tooltip')
            // .style('background-color', '#39214f')
            .style('border-radius', '20px')
            .style('height', '170px')
            .style('position', 'absolute')
            .style('top', '-40px')
            .style('padding', '10px');

        const popProduct: typeof d3 = d3
            .select('#chart' + this.svgId)
            .append('div')
            .style('display', 'none')
            .attr('class', 'pop-product');

        const mouseover: typeof d3 = function (d: any): any {
            d3.select(this)
                .attr('stroke', 'rgba(255, 255, 255, 0.3)')
                .attr('stroke-width', 20);
            Tooltip.html(
                `<div class="tooltip-data">
                ${d.searchedAsin ? '<span class="product-asin your-asin"> Your searched ASIN</span>' : '<span class="product-asin">ASIN:' + d.asin + '</span>'}
                    <div class="chart-tooltip-revenue">
                    <span class="title">${getSalesVolumeByPriceLabels[0]}</span>
                    <span class="data">${d.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    </div>
                    <div class="chart-tooltip-avg-price">
                    <span class="title">${getSalesVolumeByPriceLabels[1]}</span>
                    <span class="data">${units + d.x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    </div>
                   </div>
                `
            )
                .style('left', d3.mouse(this)[0] - 50 + 'px')
                .style('top', d3.mouse(this)[1] - 150 + 'px');
            Tooltip.style('opacity', 1);
            Tooltip.style('display', 'block');
        };

        const mouseleave: typeof d3 = function (): any {
            d3.select(this)
                .attr('stroke', '')
                .attr('stroke-width', 0);
            Tooltip.style('opacity', 0);
            Tooltip.style('display', 'none');
        };

        const onclick: typeof d3 = function (d: any): any {
            popProduct.html(
                `<div  id="popProduct" class="pop-overlay">
                <div class="pop-container">
                    <div class="pop-content">
                    <button class="close">
                        <img class="close-pop-product" src="./assets/images/close-pop-icon.svg" alt="close icon" />
                    </button>
                    <div class="product-graph-content-container">
                   <a href="https://www.amazon.com/dp/${d.asin}" target="_blank">
                   <img class="close-pop-product" src="${d.mainImage.imageUrl}" alt="product image" />
                   </a>
                      <div class="content">
                      <span class="product-asin">${d.asin}</span>
                      <h3 class="product-title">${d.title}</h3>
                      <div class="product-data-container">
                        <div class="product-data">
                        ${units === '$'
                    ? '<span>Avarage Price:</sapn><span class="the-data">$' + d.x + '</sapn>'
                    : '<span>Time on Amazon:</sapn><span class="the-data">' + d.x + ' ' + units + '</sapn>'
                }
                        </div>
                        <div class="product-data">
                        <span>Avg Sales Volume:</sapn><span class="the-data">${d.y}</sapn>
                        </div>
                      </div>
                      <div class="button-report-for-new-asin">
                      ${d.searchedAsin ? '<button class="searched-asin">Searched Asin</button>' : '<button id="getReportFromGraph" class="get-data-product">Get Report</button>'}
                      <span class="info-report-for-new-asin">It will open a nwe tab with new report.</span>
                      </div>
                      </div>
                    </div>
                    </div>
                </div>
                </div>`
            );
            popProduct.style('display', 'block');
            document.getElementById('getReportFromGraph').addEventListener('click', (): void => {
                const url: string = `${environment.bloooomUrl}/#/dashboard/report?asin=${d.asin}`;
                window.open(url, '_blank');
            });
        };

        // Draw Datapoints
        const points_g: any = svg
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .attr('clip-path', 'url(#clip)')
            .classed('points_g', true);

        // var data = genRandomData(n, max);
        let points: any = points_g.selectAll('circle').data(data);
        points = points
            .enter()
            .append('circle')
            .attr('fill', function (d: any): string {
                return d.searchedAsin ? 'rgb(244,255,85)' : 'rgba(255,255,255,.4)';
            })
            .style('cursor', 'pointer')
            .attr('class', function (d: any): string {
                return d.searchedAsin ? 'the-searched-asin' : 'the-asin';
            })
            .attr('cx', function (d: any): number {
                return xScale(d.x);
            })
            .attr('cy', function (d: any): number {
                return yScale(d.y);
            })
            .attr('r', 7)
            .on('mouseover', mouseover)
            .on('mouseleave', mouseleave)
            .on('click', onclick);
    }
}
