import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { popIn } from '../../animations/notificationAnimations';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  animations: [popIn]

})
export class ToastNotificationComponent implements OnInit, OnChanges {

  @Input() newNotificationPushed: string;
  @Input() numberOfNotifications: number;
  @Input() inputValue: string = '';
  public contentIncommingNotification: string;
  public isNewNotification: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfNotifications['previousValue'] !== changes.numberOfNotifications['currentValue']) {
      this.displayToast(this.newNotificationPushed, this.inputValue);
      this.isNewNotification = true;
      setTimeout(() => {
        this.isNewNotification = false
      }, 3500);
    };
  }

  private displayToast(dataOfNotification: string, inputValue: string): void {
    this.contentIncommingNotification = dataOfNotification + ' for: ' + inputValue;
  };
}
