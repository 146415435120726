import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { HandlePopupsService } from '../services/handle-popups.service';
import { ProductBoosterService } from '../services/product-booster.service';

@Directive({
  selector: '[appEditButton]'
})
export class EditButtonDirective implements OnInit {

  @Input() editZone: string;
  @Input() campaignDataId: string;
  @Input() dataZone?: any;
  @Input() isCampaignFinished: boolean;
  @Input() innerDataId?: string;

  constructor(private productBoosterService: ProductBoosterService, private handlePopup: HandlePopupsService, private el: ElementRef) { }

  @HostListener('mouseenter', ['$event']) onMouseover($event: MouseEvent): void {

    if ($event.type === 'mouseenter' && this.isCampaignFinished) {
      this.el.nativeElement.insertAdjacentHTML('beforeend',
        `<button class="edit-campaign-fragment-btn"></button>`);
    }
  }

  @HostListener('click', ['$event']) onClick($event: Event): void {
    if (this.isCampaignFinished) {
      this.handlePopup.setEditCampaignPopupStatus(true);
      this.productBoosterService.getCampaignDataById(this.campaignDataId, this.editZone, this.innerDataId);
    }

  }

  @HostListener('mouseleave', ['$event']) onMouseleave(): void {
    if (this.isCampaignFinished) {
      this.el.nativeElement.removeChild(document.querySelector('.edit-campaign-fragment-btn'));
    }
  }
  ngOnInit(): void { }
}
