import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCampaignTotalBudget'
})
export class GetCampaignTotalBudgetPipe implements PipeTransform {


  transform(value: number, endDate: string, startDate: string): any {

    const totalDays: number = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24));
    const daysLeft: number = totalDays - Math.floor((new Date(endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
    return { totalBudget: value * totalDays > 0 ? '$' + value * totalDays : '--', budgetLeft: value * daysLeft >= 0 && totalDays > daysLeft ? '$' + value * daysLeft : 'END' };
  }


}
