import { IRelatedPoductsDTO, IReportDTO, GetProductData } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'lodash';

export const selectRelatedProducts: any = createSelector(
    selectReportsState,
    (state: IReportDTO, props: Partial<Props>): IRelatedProductByIndex => {
        if (state) {
            const relatedProduct: IRelatedPoductsDTO = state.relatedProducts[props.index];
            if (!isNullOrUndefined(relatedProduct)) {
                if (props.byAsin) {
                    const searchedRelatedProduct: IRelatedPoductsDTO = state.relatedProducts.find((product: IRelatedPoductsDTO) => product.asin === props.byAsin);
                    const searchedRelatedProductIndex: number = state.relatedProducts.findIndex((product: IRelatedPoductsDTO) => product.asin === props.byAsin);
                    return { searchedRelatedProduct, searchedRelatedProductIndex };
                } else {
                    return { searchedRelatedProduct: relatedProduct };
                }
            }
        }
    }
);

export const RelatedProductsSelector: any = createSelector(selectReportsState, (state: IReportDTO): IRelatedPoductsDTO[] => {
    if (state) {
        return state.relatedProducts ? state.relatedProducts : [];
    }
    return [];
});

export const RelatedProductsSelectorByAsin: any = createSelector(selectReportsState, (state: IReportDTO, props: any): GetProductData[] => {
    if (state) {
        const relatedProduct: IRelatedPoductsDTO[] = state.relatedProducts ? state.relatedProducts : [];
        if (!isNullOrUndefined(relatedProduct) && relatedProduct.length > 0) {
            const currentRelatedProduct: IRelatedPoductsDTO = relatedProduct.find((relatedProduct: IRelatedPoductsDTO) => relatedProduct.asin === props.asin);
            if (currentRelatedProduct) {
                return currentRelatedProduct.getProductVariationsData;
            }
        }
    }
    return [];
});

RelatedProductsSelector.release();
RelatedProductsSelectorByAsin.release();

export interface IRelatedProductByIndex {
    searchedRelatedProduct?: IRelatedPoductsDTO;
    searchedRelatedProductIndex?: number;
}

export class Props {
    public index?: number;
    public byAsin?: string;
}
