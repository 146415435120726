import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ReviewsSummary } from '../model/report.interface';
import { IReportState } from '../store/state/report.state';
import { Store, select } from '@ngrx/store';
import { reviewFactorSelector, reviewsSelector } from '../store/selectors/reviews.selector';

@Injectable({
    providedIn: 'root'
})
export class ReviewSummaryService {
    reviewSummarySubject: BehaviorSubject<ReviewsSummary> = new BehaviorSubject<ReviewsSummary>(null);
    reviewSummary$: Observable<ReviewsSummary> = new Observable<ReviewsSummary>();
    reviewFactor$: Observable<string> = new Observable<string>();

    constructor(private Store: Store<IReportState>) {}

    getReviewSummary(): Observable<ReviewsSummary> {
        return (this.reviewSummary$ = this.Store.pipe(select(reviewsSelector)));
    }
    getReviewFactor(): Observable<string> {
        this.reviewFactor$ = this.Store.pipe(select(reviewFactorSelector));
        return this.reviewFactor$;
    }
}
