import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'saparateArrayString',
  pure: false
})
export class SaparateArrayStringPipe implements PipeTransform {

  transform(value: string[]): string {
    if (value)
      return value.join(',')
  }

}
