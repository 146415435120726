import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-chorme-ex-link',
  templateUrl: './chorme-ex-link.component.html',
  styleUrls: ['./chorme-ex-link.component.scss']
})
export class ChormeExLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public getExtentionFromChromeWebstore(): void {
    window.open(
      environment.chromeExtentionURL,
      '_blank'
    )
  }

}
