import { fade } from './../../../animations/fade';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'app-popup-related-product',
    templateUrl: './popup-related-product.component.html',
    styleUrls: ['./popup-related-product.component.scss'],
    animations: [fade]
})
export class PopupRelatedProductComponent implements OnInit {
    @Output() closePopupWaitingClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() productDetails: any;
    constructor() {}

    ngOnInit(): void {}

    public closePopupWaiting(): void {
        this.closePopupWaitingClosed.emit(false);
    }
}
