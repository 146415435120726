//import { Expose, Transform, Type } from 'class-transformer';
//import { IsArray, IsBoolean, IsEnum, IsNumber, IsOptional, IsPositive, ValidateNested } from 'class-validator';
import PaginationFilter from '../dtos/PaginationFilter';
import { AnalysisShortLongTerm } from '../enums/AnalysisShortLongTerm';
import { ShippingSizeTier } from '../enums/ShippingSizeTier';
import { ProductResearchComponent } from '../layouts/product-research/product-research.component';
//import OrderBy from '../dtos/OrderBy';
//import { GreaterOrEquelsThan } from '../../../dto/validators/GreaterOrEquelsThanConstraint';
//import { DependsOnFieldIsTrue } from '../../../dto/validators/DependsOnFieldIsEquelsTo';
//import ShippingSizeTier from '../../../enums/ShippingSizeTier';
//import { BloooomToken } from '../../BloooomToken';
//import AnalysisShortLongTerm from '../../../enums/AnalysisShortLongTerm';
import OrderBy from "./OrderBy";
import Direction from "../dtos/Direction";
import { ProductResearchNicheComponent } from '../layouts/product-research-niche/product-research-niche.component';

export default class GetProductResearchProductFilter extends PaginationFilter {

    //No need to pass this
    public taskId: number;

    //No needed
    //public token: BloooomToken;

    //No needed for now
    //Put checkbox for this option
    public useBrandDefault: boolean;

    //Need to show dropdown
    //For now pass mock
    //Take mock categories from here for now, just take few of them
    //https://prnt.sc/26suawl
    //multi select
    public categories: string[];

    /*
    enum ShippingSizeTier {
        SMALL_STANDARD = 'Small (Standard)',
        SMALL_OVERSIZE = 'Small (Oversize)',
        MEDIUM_OVERSIZE = 'Medium (Oversize)',
        SPECIAL_OVERSIZE = 'Special (Oversize)',
        LARGE_STANDARD = 'Large (Standard)',
        LARGE_OVERSIZE = 'Large (Oversize)',
    }
    */
    //Dropdown, multi select
    public shippingSizeTier: ShippingSizeTier[];

    /*
    enum AnalysisShortLongTerm {
        DEFAULT = 'default',
        MEDIUM = 'medium',
        LONG = 'long',
    }
    */
    public term: AnalysisShortLongTerm;

    //public orderBy: ProductsOrderBy[];


    //Filter
    //Comma seperated, in input text
    public asins: string[];

    //All below are from/to filters
    public monthlyRevenueFrom: number;

    public monthlyRevenueTo: number;

    public priceFrom: number;

    public priceTo: number;

    public reviewCountFrom: number;

    public reviewCountTo: number;

    public reviewRatingFrom: number;

    public reviewRatingTo: number;

    public timeOnAmazonMonthsFrom: number;

    public timeOnAmazonMonthsTo: number;

    public bsrFrom: number;

    public bsrTo: number;

    public orderBy: OrderBy[];

    public constructor(param?: Partial<GetProductResearchProductFilter>) {
        super();
        if (param) {
            this.page = param.page;
            this.take = param.take;
            this.taskId = param.taskId;
        }
    }

    public clear(productResearchComponent: ProductResearchComponent) {

        this.monthlyRevenueFrom = undefined;
        this.monthlyRevenueTo = undefined;

        this.priceFrom = undefined;
        this.priceTo = undefined;

        this.reviewCountFrom = undefined;
        this.reviewCountTo = undefined;

        this.reviewRatingFrom = undefined;
        this.reviewRatingTo = undefined;

        this.timeOnAmazonMonthsFrom = undefined;
        this.timeOnAmazonMonthsTo = undefined;

        this.bsrFrom = undefined;
        this.bsrTo = undefined;

        this.asins = undefined;

        this.shippingSizeTier = undefined;

        this.term = undefined;

        this.categories = undefined;
    }

    public clear1(productResearchNicheComponent: ProductResearchNicheComponent) {

        this.monthlyRevenueFrom = undefined;
        this.monthlyRevenueTo = undefined;

        this.priceFrom = undefined;
        this.priceTo = undefined;

        this.reviewCountFrom = undefined;
        this.reviewCountTo = undefined;

        this.reviewRatingFrom = undefined;
        this.reviewRatingTo = undefined;

        this.timeOnAmazonMonthsFrom = undefined;
        this.timeOnAmazonMonthsTo = undefined;

        this.bsrFrom = undefined;
        this.bsrTo = undefined;

        this.asins = undefined;

        this.shippingSizeTier = undefined;

        this.term = undefined;

        this.categories = undefined;

    }

    public checkIsValid(productResearchComponent: ProductResearchComponent) {

        //monthlyRevenue
        if (!this.monthlyRevenueFrom || !this.monthlyRevenueTo) {
            if (!this.monthlyRevenueFrom)
                this.monthlyRevenueFrom = undefined;
            if (!this.monthlyRevenueTo)
                this.monthlyRevenueTo = undefined;
        } else if (parseInt(this.monthlyRevenueFrom.toString()) > parseInt(this.monthlyRevenueTo.toString()))
            return "MonthlyRevenueTo must be greater or equals than MonthlyRevenueFrom";

        //price
        if (!this.priceFrom || !this.priceTo) {
            if (!this.priceFrom)
                this.priceFrom = undefined;
            if (!this.priceTo)
                this.priceTo = undefined;
        } else if (parseInt(this.priceFrom.toString()) > parseInt(this.priceTo.toString()))
            return "PriceTo must be greater or equals than PriceFrom";

        //reviewCount
        if (!this.reviewCountFrom || !this.reviewCountTo) {
            if (!this.reviewCountFrom)
                this.reviewCountFrom = undefined;
            if (!this.reviewCountTo)
                this.reviewCountTo = undefined;
        } else if (parseInt(this.reviewCountFrom.toString()) > parseInt(this.reviewCountTo.toString()))
            return "ReviewCountTo must be greater or equals than ReviewCountFrom";

        //reviewRating
        if (!this.reviewRatingFrom || !this.reviewRatingTo) {
            if (!this.reviewRatingFrom)
                this.reviewRatingFrom = undefined;
            if (!this.reviewRatingTo)
                this.reviewRatingTo = undefined;
        } else if (parseInt(this.reviewRatingFrom.toString()) > parseInt(this.reviewRatingTo.toString()))
            return "ReviewRatingTo must be greater or equals than ReviewRatingFrom";

        //timeOnAmazonMonths
        if (!this.timeOnAmazonMonthsFrom || !this.timeOnAmazonMonthsTo) {
            if (!this.timeOnAmazonMonthsFrom)
                this.timeOnAmazonMonthsFrom = undefined;
            if (!this.timeOnAmazonMonthsTo)
                this.timeOnAmazonMonthsTo = undefined;
        } else if (parseInt(this.timeOnAmazonMonthsFrom.toString()) > parseInt(this.timeOnAmazonMonthsTo.toString()))
            return "TimeOnAmazonMonthsTo must be greater or equals than TimeOnAmazonMonthsFrom";

        //excludeKeywordsThatContain
        if (!productResearchComponent.asins)
            this.asins = undefined;
        else {
            this.asins = productResearchComponent.asins.split(",");
        }

        return '';
    }

    public checkIsValid1(productResearchNicheComponent: ProductResearchNicheComponent) {

        //monthlyRevenue
        if (!this.monthlyRevenueFrom || !this.monthlyRevenueTo) {
            if (!this.monthlyRevenueFrom)
                this.monthlyRevenueFrom = undefined;
            if (!this.monthlyRevenueTo)
                this.monthlyRevenueTo = undefined;
        } else if (parseInt(this.monthlyRevenueFrom.toString()) > parseInt(this.monthlyRevenueTo.toString()))
            return "MonthlyRevenueTo must be greater or equals than MonthlyRevenueFrom";

        //price
        if (!this.priceFrom || !this.priceTo) {
            if (!this.priceFrom)
                this.priceFrom = undefined;
            if (!this.priceTo)
                this.priceTo = undefined;
        } else if (parseInt(this.priceFrom.toString()) > parseInt(this.priceTo.toString()))
            return "PriceTo must be greater or equals than PriceFrom";

        //reviewCount
        if (!this.reviewCountFrom || !this.reviewCountTo) {
            if (!this.reviewCountFrom)
                this.reviewCountFrom = undefined;
            if (!this.reviewCountTo)
                this.reviewCountTo = undefined;
        } else if (parseInt(this.reviewCountFrom.toString()) > parseInt(this.reviewCountTo.toString()))
            return "ReviewCountTo must be greater or equals than ReviewCountFrom";

        //reviewRating
        if (!this.reviewRatingFrom || !this.reviewRatingTo) {
            if (!this.reviewRatingFrom)
                this.reviewRatingFrom = undefined;
            if (!this.reviewRatingTo)
                this.reviewRatingTo = undefined;
        } else if (parseInt(this.reviewRatingFrom.toString()) > parseInt(this.reviewRatingTo.toString()))
            return "ReviewRatingTo must be greater or equals than ReviewRatingFrom";

        //timeOnAmazonMonths
        if (!this.timeOnAmazonMonthsFrom || !this.timeOnAmazonMonthsTo) {
            if (!this.timeOnAmazonMonthsFrom)
                this.timeOnAmazonMonthsFrom = undefined;
            if (!this.timeOnAmazonMonthsTo)
                this.timeOnAmazonMonthsTo = undefined;
        } else if (parseInt(this.timeOnAmazonMonthsFrom.toString()) > parseInt(this.timeOnAmazonMonthsTo.toString()))
            return "TimeOnAmazonMonthsTo must be greater or equals than TimeOnAmazonMonthsFrom";

        //excludeKeywordsThatContain
        if (!productResearchNicheComponent.asins)
            this.asins = undefined;
        else {
            this.asins = productResearchNicheComponent.asins.split(",");
        }

        return '';
    }



    public sortVolume(isAsc: boolean) {

        this.orderBy = [];
        let or = new OrderBy();
        //sorting Volumn
        if (isAsc) {
            or.field = 'volume'
            or.direction = Direction.ASC;
        } else {
            or.field = 'volume';
            or.direction = Direction.DESC;
        }
        this.orderBy.push(or);
    }

}
