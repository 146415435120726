import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../../../../app/services/auth-service.service';
import { PccServiceService } from '../../../../../app/services/pcc-service.service';

@Component({
    selector: 'app-banner-for-campaign',
    templateUrl: './banner-for-campaign.component.html',
    styleUrls: ['./banner-for-campaign.component.scss']
})
export class BannerForCampaignComponent implements OnInit {
    public closePopupWaitingClosed: boolean = false;
    public userIsLoggedIn: boolean;
    public emailSent: boolean;
    constructor(private authService: AuthServiceService, private sendEmail: PccServiceService) {}

    getUsername(): Promise<boolean> {
        return this.authService.isUserLoggedIn();
    }

    ngOnInit(): void {
        this.getUsername().then((isLoggedIn: boolean) => {
            this.userIsLoggedIn = isLoggedIn;
        });
    }

    popupWaitingClosed(event: boolean): void {
        this.closePopupWaitingClosed = event;
    }

    sendThirdPartyEmail(): void {
        if (this.userIsLoggedIn) {
            this.closePopupWaitingClosed = true;
            this.sendEmail
                .sendPpcRequest()
                .then((success: boolean) => {
                    if (success === true) {
                        this.emailSent = success;
                    }
                })
                .catch((e: any) => {
                    this.emailSent = false;
                });
        }
    }
}

// this.sendEmail
//     .sendPpcRequest()
//     .then((data: any) => {
//         console.log('success');
//     })
//     .catch(e => {
//         console.log(e);
//     });
// }
