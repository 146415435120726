import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'pop-password',
    templateUrl: './pop-password.component.html',
    styleUrls: ['./pop-password.component.scss']
})
export class PopPasswordComponent {
    public password: string = '1@#';
    isPasswordOK: any;
    @Output() public isCorrect: EventEmitter<boolean> = new EventEmitter<boolean>();

    submit(password: NgForm): void {
        if (password.value.password === this.password) {
            this.isCorrect.emit(true);
        }
        password.reset();
    }
}
