import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import PageType from '../../enums/PageType';
import GetAmpScoreFilter from '../../dtos/GetAmpScoreFilter';
import GetBestKeywordResponse from '../../dtos/GetBestKeywordResponse';
import GetKeywordResponse from '../../dtos/getKeywordResponse';
import GetKeywordsFilter from '../../dtos/GetKeywordsFilter';
import KeywordResearchTaskResultsDto from '../../dtos/keywordResearchTaskResultsDto';
import PaginateResponse from '../../dtos/paginateResponse';
import TopProductResponse from '../../dtos/TopProductResponse';
import GetKeywordHandler from '../../services/handler/GetKeywordHandler';
import { MarketPlaceService } from '../../services/MarketPlaceService';
import { NotificationsService } from '../../services/notifications.service';
import { PollingService } from '../../services/polling.service';
import { ProgressBarService } from '../../services/progress-bar.service';
import { ToastrService } from '../../services/toastr.service';
import { bestKewordSelector, keywordsAnalysisByAsinsSelector, mainWordAndTopProductsAnalysisSelector } from '../../store/selectors/keywords.selector';
import { KeywordAnalysisState } from '../../store/state/keywordAnalysis.state';
import * as CONSTANTS_CLASS from '../../utils/constants';
import { TaskType } from '../../enums/TaskType';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-keyword-analysis-asins',
  templateUrl: './keyword-analysis-asins.component.html',
  styleUrls: ['./keyword-analysis-asins.component.scss']
})
export class KeywordAnalysisAsinsComponent implements OnInit {

  public searchText: string;
  public getKeywordsPaginateResponse: PaginateResponse;
  public keywordResearchTaskResultsDtos: KeywordResearchTaskResultsDto[] = [];

  public keywords$: Subscription;
  public bestkeyword$: Subscription;
  public getKeywordsFilter: GetKeywordsFilter = new GetKeywordsFilter();
  public CONSTANTS_CLASS = CONSTANTS_CLASS;
  //---Filter show hide variables---//
  public openSearchVolume: boolean = false;
  public openFinalScore: boolean = false;
  public openWordCount: boolean = false;
  public openCompetingProducts: boolean = false;
  public openByPhrase: boolean = false;
  public openExcludePhrase: boolean = false;
  public showKeywordsThatContain: string;
  public excludeKeywordsThatContain: string;
  //---END Filter show hide variables---//

  // yairs work

  public topProducts$: Subscription;
  public topProducts: TopProductResponse[] = []

  public choosenProduct: number = 0;

  public mainWordData: GetBestKeywordResponse;

  // end yairs work

  isVolumeAsc: boolean = true;
  //--For sorting functionality--// 
  isDesc: boolean = true;
  column: string = 'keyword';
  direction: number = 1;
  //END--For sorting functionality--//
  isShowGetAmpScoreButton: boolean = false;

  constructor(private httpClient: HttpClient, private toastrService: ToastrService, private marketPlaceService: MarketPlaceService, private pollingService: PollingService,
    private store: Store<KeywordAnalysisState>, private notificationService: NotificationsService, public progressBarService: ProgressBarService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.keywords$ = this.store.pipe(select(keywordsAnalysisByAsinsSelector)).subscribe((data: GetKeywordResponse) => {
      this.progressBarService.removeProgressBar();
      if (data) {
        this.getKeywordsPaginateResponse = data.paginateResponse;
        this.keywordResearchTaskResultsDtos = data.keywordResearchTaskResultsDtos;
      }
    }, (error) => {
      console.log("error: " + error);
      this.progressBarService.removeProgressBar();
    });

    this.bestkeyword$ = this.store.pipe(select(bestKewordSelector)).subscribe((data: GetBestKeywordResponse) => {
      if (data) {
        this.mainWordData = data;
      }
    })

    this.topProducts$ = this.store.pipe(select(mainWordAndTopProductsAnalysisSelector)).subscribe((data: TopProductResponse[]) => {
      if (data) {
        this.topProducts = data.slice(0, 5);
      }
    })
    const searchText = this.route.snapshot.queryParamMap.get('search');
    if (searchText) {
      this.searchText = searchText;
      this.generatePhrase(this.searchText);
    }
  }

  validateASINS(searchText: string) {

    let keywordsString: string = '';
    //Validate comma seperated asins
    const CHECK_ASIN_REX: RegExp = /^[0-9A-Z]{10}$/;
    let keywords: string[] = searchText.split(',');
    let isValid = true;
    for (let index = 0; index < keywords.length; index++) {
      const keyword = keywords[index].trim();
      if (index != 0)
        keywordsString += ',' + keyword;
      else
        keywordsString += keyword;
      if (!CHECK_ASIN_REX.exec(keyword)) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      this.searchText = keywordsString;
      return true;
    } else
      return false;
  }

  public generatePhrase(searchText: string): void {

    if (searchText && this.validateASINS(searchText)) {
      this.progressBarService.setProgressBar();
      this.marketPlaceService.generatePhraseAsins(this.searchText.split(',')).subscribe(
        (response) => {
          this.getKeywordsFilter.taskId = response.taskId;
          this.pollingService.setLatestWordMapperByAsinsTaskId(response.taskId);
          this.pollingService.getStatus(response.taskId, TaskType.KEYWORDS_FOR_ASINS);
        }, err => {
          console.log(err);
          console.log('YAIR for you to handle error')
        })
    } else {
      this.toastrService.showError("Please enter a valid comma seperated Amazon ASINS");
    }
  }

  public productSlider(sideDirection: string): void {
    const topProductsLengthArr: number = this.topProducts.length - 1;

    if (sideDirection === 'right') {
      if (this.choosenProduct < topProductsLengthArr) {
        this.choosenProduct = this.choosenProduct + 1;
      } else {
        this.choosenProduct = 0;
      }
    } else {
      if (this.choosenProduct > 0) {
        this.choosenProduct = this.choosenProduct - 1;
      } else {
        this.choosenProduct = topProductsLengthArr;
      }
    }
  }

  public ngOnDestroy(): void {
    this.keywords$.unsubscribe();
  }

  //----Filters----//
  public toggleSearchVolume() {

    this.openSearchVolume = !this.openSearchVolume;
  }

  public toggleFinalScore() {

    this.openFinalScore = !this.openFinalScore;
  }

  public toggleWordCount() {

    this.openWordCount = !this.openWordCount;
  }

  public toggleCompetingProducts() {

    this.openCompetingProducts = !this.openCompetingProducts;
  }

  public toggleByPhrase() {

    this.openByPhrase = !this.openByPhrase;
  }

  public toggleExcludePhrase() {

    this.openExcludePhrase = !this.openExcludePhrase;
  }

  public clearFilter() {

    this.getKeywordsFilter.clear1(this);
    this.callGetKeywords(1);
  }

  public applyFilter() {

    //Check is all filters have valid values
    let errorMessage = this.getKeywordsFilter.checkIsValid1(this);
    if (errorMessage.length > 0) {
      this.toastrService.showError(errorMessage);
      return;
    }
    this.progressBarService.setProgressBar();
    this.callGetKeywords(1);
  }
  //----END Filters----//

  public callGetKeywords(pageNumber: number) {

    this.getKeywordsFilter.page = pageNumber;
    new GetKeywordHandler(PageType.WordMapperByAsins, this.marketPlaceService, this.store, this.getKeywordsFilter, this.httpClient).process();
  }

  public sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  };

  public sortByVolume() {

    this.isVolumeAsc = !this.isVolumeAsc;
    this.isDesc = this.isVolumeAsc;
    this.column = 'volume';
    this.direction = this.isDesc ? 1 : -1;
    this.getKeywordsFilter.sortVolume(this.isVolumeAsc);
    this.progressBarService.setProgressBar();
    this.callGetKeywords(1);
  }

  public onCheckKeyword() {

    for (let keywordResearchTaskResultsDto of this.keywordResearchTaskResultsDtos) {
      if (keywordResearchTaskResultsDto.isChecked) {
        this.isShowGetAmpScoreButton = true;
        return;
      }
    }

    this.isShowGetAmpScoreButton = false;
  }

  public getAmpScore() {

    let getAmpScoreFilter: GetAmpScoreFilter = new GetAmpScoreFilter();
    getAmpScoreFilter.keywords = [];
    getAmpScoreFilter.taskId = this.getKeywordsFilter.taskId;
    for (let keywordResearchTaskResultsDto of this.keywordResearchTaskResultsDtos) {
      if (keywordResearchTaskResultsDto.isChecked) {
        getAmpScoreFilter.keywords.push(keywordResearchTaskResultsDto.keyword);
      }
    }

    this.pollingService.callGetAmpScore(getAmpScoreFilter, this.getKeywordsPaginateResponse, this.keywordResearchTaskResultsDtos)
  }

}
