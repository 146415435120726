import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { BestVariation } from '../model/report.interface';
import { bestVaritionSelector } from '../store/selectors/best-variation.selector';
import { IReportState } from '../store/state/report.state';

@Injectable({
    providedIn: 'root'
})
export class TheBestVariationService {
    private theBestVariationSubject: BehaviorSubject<BestVariation> = new BehaviorSubject<BestVariation>(null);
    public theBestVariation$: Observable<BestVariation> = this.theBestVariationSubject.asObservable();

    constructor(private store: Store<IReportState>) {}
    getTheBestVariation(): void {
        this.store.pipe(select(bestVaritionSelector)).subscribe((theVariation: BestVariation) => this.theBestVariationSubject.next(theVariation));
    }
}
