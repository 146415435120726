import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { slideRight } from '../../../app/animations/slides';

@Component({
  selector: 'app-image-text-block',
  templateUrl: './image-text-block.component.html',
  styleUrls: ['./image-text-block.component.scss'],
  animations: [slideRight]
})
export class ImageTextBlockComponent implements OnInit {

  @Input() isOppositeSide: boolean = false;
  @Input() title: string;
  @Input() text: string;
  @Input() image: string;
  public isInView: boolean = false;

  @ViewChild('scrollToMe') scrollToMe: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  @HostListener("window:scroll") scrolling() {
    if (this.scrollToMe.nativeElement.offsetTop - 500 < this.document.documentElement.scrollTop) {
      this.isInView = true;
    }
  }
  ngOnInit() {

  }

}
