import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, elementAt } from 'rxjs/operators';
import BrandResponse, { BrandPaymentMethod } from '../dtos/brandResponse';

export interface IPlans {
    monthlyPrice: number;
    tranzilaParam: string;
    totalPayment: number;
    title: string;
    content: string;
    isEnabled: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PricesService {
    public static DEFAULT_BRAND: string = 'bloooom_basic';
    public isBrandPassedInUrl: boolean = true;
    public choosenPlanIndex: number;
    public choosenSubscriptionIndex: number;
    public isCouponActive: boolean;
    public couponDisscount: number = 0;
    public url: String;

    private plansPrice: IPlans[] = [
        // { monthlyPrice: 59, tranzilaParam: '4', totalPayment: 59, title: 'Monthly', content: 'billed monthly', isEnabled: true },
        // { monthlyPrice: 49, tranzilaParam: '5', totalPayment: 147, title: 'Quarterly (3 months)', content: 'billed quarterly (3 months)', isEnabled: true },
        // { monthlyPrice: 39, tranzilaParam: '7', totalPayment: 468, title: 'Yearly (12 months)', content: 'billed annually (12 months)', isEnabled: true }
    ];

    private plansSubscription: BrandPaymentMethod[] = [
        // { price: 29, isYearlyPaymentEnabled: true, isMonthlyPaymentEnabled: true, yearlyDiscountPrecentage: 20, isQuarterlyPaymentEnabled: true, monthlyDiscountPrecentage: 0, quarterlyDiscountPrecentage: 10, brand: "bloooom basic" }
    ];

    private pricesSub$: BehaviorSubject<IPlans[]> = new BehaviorSubject<IPlans[]>(null);
    private subscriptionsSub$: BehaviorSubject<BrandPaymentMethod[]> = new BehaviorSubject<BrandPaymentMethod[]>(null);

    public prices$: Observable<IPlans[]> = this.pricesSub$.asObservable();
    public subscriptions$: Observable<BrandPaymentMethod[]> = this.subscriptionsSub$.asObservable();

    private choosenPlanSub$: BehaviorSubject<IPlans> = new BehaviorSubject<IPlans>({
        monthlyPrice: 59,
        tranzilaParam: '4',
        totalPayment: 59,
        title: 'Monthly',
        content: 'billed monthly',
        isEnabled: true
    });

    private choosenSubscriptionSub$: BehaviorSubject<BrandPaymentMethod> = new BehaviorSubject<BrandPaymentMethod>({
        price: 29,
        isYearlyPaymentEnabled: true,
        isMonthlyPaymentEnabled: true,
        yearlyDiscountPrecentage: 20,
        isQuarterlyPaymentEnabled: true,
        monthlyDiscountPrecentage: 0,
        quarterlyDiscountPrecentage: 10,
        brand: 'bloooom_basic'
    });

    public choosenPlan$: Observable<IPlans> = this.choosenPlanSub$.asObservable();
    public choosenSubscription$: Observable<BrandPaymentMethod> = this.choosenSubscriptionSub$.asObservable();
    private brandResponse: BrandResponse;

    constructor() { }

    getPrices(): void {
        this.pricesSub$.next(this.plansPrice);
    }

    getSubscriptions(): void {
        this.subscriptionsSub$.next(this.plansSubscription);
    }

    makeDisscount(): void {
        if (!this.isCouponActive) {
            return;
        }

        this.plansPrice.map((price: IPlans) => {
            price.monthlyPrice = +(price.monthlyPrice * this.couponDisscount).toFixed(2);
            price.totalPayment = +(price.totalPayment * this.couponDisscount).toFixed(2);
            return price;
        });
        this.pricesSub$.next(this.plansPrice);
        this.choosenPlan(this.choosenPlanIndex);
    }

    choosenPlan(index: number): void {
        this.choosenPlanIndex = index;
        this.choosenPlanSub$.next(this.plansPrice[index]);
    }

    choosenSubscription(index: number): void {
        this.choosenSubscriptionIndex = index;
        this.calculatePrice(this.plansSubscription[index]);
        this.choosenPlanSub$.next(this.plansPrice[this.choosenPlanIndex]);
    }

    setPricingData(brandResponse: BrandResponse): void {
        this.brandResponse = brandResponse;
        let choosenPlansSubscription: BrandPaymentMethod;
        // Take 'Bloom basic' as by default choosen plan
        const subscriptionsPaymentMethods: BrandPaymentMethod[] = brandResponse.subscriptionsPaymentMethod.filter((o: any) => o.brand === PricesService.DEFAULT_BRAND);
        choosenPlansSubscription = subscriptionsPaymentMethods && subscriptionsPaymentMethods.length > 0 ? subscriptionsPaymentMethods[0] : null;

        this.plansSubscription = brandResponse.subscriptionsPaymentMethod;
        this.calculatePrice(choosenPlansSubscription);
    }

    calculatePrice(choosenPlansSubscription: BrandPaymentMethod): any {
        let planToProcess: BrandPaymentMethod = null;
        planToProcess = this.brandResponse.brandPaymentMethod;

        let monthlyPrice = 0,
            totalPayment = 0;
        let isEnabledMonthly = true;
        let quarterlyPrice = 0,
            quarterlyTotalPayment = 0;
        let isEnabledQuaterly = true;
        let yearlyPrice = 0,
            yearlyTotalPayment = 0;
        let isEnabledYearly = true;

        // First check for is brand need to take in calculation

        if (planToProcess && this.brandResponse.userCurrentRegisteredBrands.indexOf(planToProcess.brand) === -1) {
            monthlyPrice = planToProcess.price;
            totalPayment = planToProcess.price;

            quarterlyPrice = (planToProcess.price * (100 - planToProcess.quarterlyDiscountPrecentage)) / 100;
            quarterlyTotalPayment = quarterlyPrice * 3;

            yearlyPrice = (planToProcess.price * (100 - planToProcess.yearlyDiscountPrecentage)) / 100;
            yearlyTotalPayment = yearlyPrice * 12;

            isEnabledMonthly = planToProcess.isMonthlyPaymentEnabled;
            isEnabledQuaterly = planToProcess.isQuarterlyPaymentEnabled;
            isEnabledYearly = planToProcess.isYearlyPaymentEnabled;
        }

        // Check for is succent choosen subscription need to take in calculation


        // if (choosenPlansSubscription && this.brandResponse.userCurrentSubscription.indexOf(choosenPlansSubscription.brand) === -1) {
        //     monthlyPrice = monthlyPrice + choosenPlansSubscription.price;
        //     totalPayment = totalPayment + choosenPlansSubscription.price;

        //     // Check for is enable to apply discount

        //     if (choosenPlansSubscription.isQuarterlyPaymentEnabled) {
        //         const currentQuaterlyPrice = (choosenPlansSubscription.price * (100 - choosenPlansSubscription.quarterlyDiscountPrecentage)) / 100;
        //         quarterlyPrice = quarterlyPrice + currentQuaterlyPrice;
        //         quarterlyTotalPayment = quarterlyTotalPayment + currentQuaterlyPrice * 3;
        //     } else {
        //         // Add price as it is without discount calculations

        //         const currentQuaterlyPrice = choosenPlansSubscription.price;
        //         quarterlyPrice = quarterlyPrice + currentQuaterlyPrice;
        //         quarterlyTotalPayment = quarterlyTotalPayment + currentQuaterlyPrice * 3;
        //     }

        //     // Check for is enable to apply discount

        //     if (choosenPlansSubscription.isYearlyPaymentEnabled) {
        //         const currentYearlyPrice = (choosenPlansSubscription.price * (100 - choosenPlansSubscription.yearlyDiscountPrecentage)) / 100;
        //         yearlyPrice = yearlyPrice + currentYearlyPrice;
        //         yearlyTotalPayment = yearlyTotalPayment + currentYearlyPrice * 12;
        //     } else {
        //         // Add price as it is without discount calculations

        //         const currentYearlyPrice = choosenPlansSubscription.price;
        //         yearlyPrice = yearlyPrice + currentYearlyPrice;
        //         yearlyTotalPayment = yearlyTotalPayment + currentYearlyPrice * 12;
        //     }

        //     // If brand passed from url then these variables are already set so no need to set it again

        //     if (!planToProcess) {
        //         isEnabledMonthly = choosenPlansSubscription.isMonthlyPaymentEnabled;
        //         isEnabledQuaterly = choosenPlansSubscription.isQuarterlyPaymentEnabled;
        //         isEnabledYearly = choosenPlansSubscription.isYearlyPaymentEnabled;
        //     }
        // }

        const monthly: any = {};
        monthly.monthlyPrice = monthlyPrice;
        monthly.tranzilaParam = 4;
        monthly.totalPayment = totalPayment;
        monthly.title = 'Monthly';
        monthly.content = 'billed monthly';
        monthly.isEnabled = isEnabledMonthly;

        const quarterly: any = {};
        quarterly.monthlyPrice = quarterlyPrice;
        quarterly.tranzilaParam = 5;
        quarterly.totalPayment = quarterlyTotalPayment.toFixed(2);
        quarterly.title = 'Quarterly (3 months)';
        quarterly.content = 'billed quarterly (3 months)';
        quarterly.isEnabled = isEnabledQuaterly;

        const yearly: any = {};
        yearly.monthlyPrice = yearlyPrice;
        yearly.tranzilaParam = 7;
        yearly.totalPayment = yearlyTotalPayment.toFixed(2);
        yearly.title = 'Yearly (12 months)';
        yearly.content = 'billed annually (12 months)';
        yearly.isEnabled = isEnabledYearly;

        this.plansPrice.length = 0;

        if (monthly.isEnabled) {
            this.plansPrice.push(monthly);
        }
        if (quarterly.isEnabled) {
            this.plansPrice.push(quarterly);
        }
        if (yearly.isEnabled) {
            this.plansPrice.push(yearly);
        }

        this.makeDisscount();
    }
}
