import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from './services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { MessagesService } from './services/messages.service';
import { Observable, Subscription } from 'rxjs';
import { fade } from './animations/fade';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToggleMenuService } from './services/toggle-menu.service';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [fade]
})
export class AppComponent implements OnInit, OnDestroy {
    public popMessage$: Observable<boolean>;
    public routerSub: Subscription;
    public isPluginBanner: boolean;

    constructor(
        private messagesService: MessagesService,
        private readonly reportService: ReportService,
        private translate: TranslateService,
        private themeService: ThemeService
    ) {
        this.translate.setDefaultLang(environment.defaultLang);
    }

    async ngOnInit(): Promise<void> {
        setTimeout(() => {
            this.isPluginBanner = true;
        }, 3000);

        this.popMessage$ = this.messagesService.popMessage;

        this.reportService.init();
        await this.themeService.loadBrandStyle();

        if (environment.production === false) {
            (Map.prototype as any).toJSON = function toJSON(): string {
                return JSON.stringify(Array.from(this.entries()));
            };
        }
    }

    private loadExternalScript(scriptUrl: string): any {
        return new Promise((resolve: any): void => {
            const scriptElement: any = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
    }
    ngOnDestroy(): void {
        this.routerSub.unsubscribe();
    }
    onActivate(): void {
        window.scroll(0, 0);
    }
}
