import { CampaignActionsEnum, CampaignsActions, GetCreateCampaignAction } from "../actions/campaigns.actions";
import { IApplicationState } from "../state/application.state";
import { CampaignsState, initialCampaignsState } from "../state/campaigns.state";

export function campaignReducers(state: CampaignsState = initialCampaignsState, action: CampaignsActions): CampaignsState {
    switch (action.type) {
        case CampaignActionsEnum.GET_CAMPAIGN: {
            return handleCampaginsAction(action, state);
        }
        default:
            return state;
    }

    function handleCampaginsAction(action: GetCreateCampaignAction, state: CampaignsState): any {
        console.log(action.campaignManager.data)
        return {
            ...state,
            allCampaigns: state.allCampaigns.push(action.campaignManager.data[0])
        };
    }
}


export const selectCampaignState: any = (state: IApplicationState): any => state.campaignsState.allCampaigns;
