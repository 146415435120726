import { LogosSectionComponent } from './logos-section/logos-section.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppRoutingModule } from '../app-routing.module';
import { LoginUserHeaderComponent } from './login-user-header/login-user-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchComponent } from './search/search.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { DropdownLoginComponent } from './dropdown-login/dropdown-login.component';
import { ReadMorePopupComponent } from './popups/read-more-popup/read-more-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VideoSectionComponent } from './video-section/video-section.component';
import { VideoSliderComponent } from './video-slider/video-slider.component';
import { SafePipe } from '../pipes/safe.pipe';
import { BloooomSectionComponent } from './bloooom-section/bloooom-section.component';
import { BenefitsSectionComponent } from './benefits-section/benefits-section.component';
import { BenefitsSliderComponent } from './benefits-slider/benefits-slider.component';
import { CardsSectionComponent } from './cards-section/cards-section.component';
import { CardsComponent } from './cards/cards.component';
import { TeamComponent } from './team/team.component';
import { CallToActionSectionComponent } from './call-to-action-section/call-to-action-section.component';
import { GetPremiumComponent } from './get-premium/get-premium.component';
import { ReportOverviewComponent } from './report-overview/report-overview.component';
import { ProductHighlightComponent } from './product-highlight/product-highlight.component';
import { OverviewReviewsComponent } from './report-overview/overview-reviews/overview-reviews.component';
import { ProductSpecsComponent } from './report-overview/product-specs/product-specs.component';
import { ProductOptimizationComponent } from './report-overview/product-optimization/product-optimization.component';
import { MarketChartComponent } from './report-overview/market-chart/market-chart.component';
import { ShowTooltipDirective } from '../directives/show-tooltip.directive';
import { LoadingDataDirective } from '../directives/loading-data.directive';
import { LimitTextPipe } from '../pipes/limit-text.pipe';
import { MinPipePipe } from '../pipes/min-pipe.pipe';
import { MaxPipePipe } from '../pipes/max-pipe.pipe';
import { NumberOfStarsToArrayPipe } from '../pipes/number-of-stars-to-array.pipe';
import { StatusColorGeneratorPipe } from '../pipes/status-color-generator.pipe';
import { BloooomScoreComponent } from './bloooom-score/bloooom-score.component';
import { ReportEcommerceComponent } from './report-ecommerce/report-ecommerce.component';
import { BestVariationsComponent } from './report-ecommerce/best-variations/best-variations.component';
import { CompettitorsComponent } from './report-ecommerce/compettitors/compettitors.component';
import { KeywordsComponent } from './report-ecommerce/keywords/keywords.component';
import { ReviewsComponent } from './report-ecommerce/reviews/reviews.component';
import { PopWaitingReportComponent } from './popups/pop-waiting-report/pop-waiting-report.component';
import { FindDaysPipe } from '../pipes/find-days.pipe';
import { GetMonthPipe } from '../pipes/get-month.pipe';
import { NumberFormatPipe } from '../pipes/number-format.pipe';
import { CtaMemberPopupComponent } from './popups/cta-member-popup/cta-member-popup.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { PopSearchAgainComponent } from './popups/pop-search-again/pop-search-again.component';
import { VariationsPopComponent } from './popups/variations-pop/variations-pop.component';
import { MarketGraphComponent } from './market-graph/market-graph.component';
import { PopPasswordComponent } from './popups/pop-password/pop-password.component';
import { VariationTypesPipe } from '../pipes/variation-types.pipe';
import { GoogleAdsComponent } from './google-ads/google-ads.component';
import { PopTutorialSectionComponent } from './popups/pop-tutorial-section/pop-tutorial-section.component';
import { AlibabaComponent } from './alibaba/alibaba.component';
import { GraphScatterComponent } from './graph-scatter/graph-scatter.component';
import { MarketAnalysisComponent } from './market-analysis/market-analysis.component';
import { KeywordsAndCategoriesComponent } from './keywords-and-categories/keywords-and-categories.component';
import { BannerForCampaignComponent } from './report-ecommerce/keywords/banner-for-campaign/banner-for-campaign.component';
import { SummaryResultsKeywordsComponent } from './report-ecommerce/keywords/summary-results-keywords/summary-results-keywords.component';
import { RatingAndReviewsComponent } from './rating-and-reviews/rating-and-reviews.component';
import { WodsonDataReviewsComponent } from './rating-and-reviews/wodson-data-reviews/wodson-data-reviews.component';
import { ReviewsInsightsComponent } from './rating-and-reviews/reviews-insights/reviews-insights.component';
import { GraphBarsComponent } from './graph-bars/graph-bars.component';
import { ProductAndOptimizatoinComponent } from './product-and-optimizatoin/product-and-optimizatoin.component';
import { ListingTableComponent } from './listing-table/listing-table.component';
import { AlibabaProductSliderComponent } from './alibaba/alibaba-product-slider/alibaba-product-slider.component';
import { PopupRelatedProductComponent } from './graph-scatter/popup-related-product/popup-related-product.component';
import { SingleVariationComponent } from './report-ecommerce/best-variations/single-variation/single-variation.component';
import { PopUnderConstructionsComponent } from './popups/pop-under-constructions/pop-under-constructions.component';
import { SupplierComponent } from './alibaba/supplier/supplier.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { CheckoutLoggedUserComponent } from './checkout-logged-user/checkout-logged-user.component';
import { CheckoutLoginUserComponent } from './checkout-login-user/checkout-login-user.component';
import { SignupFromSearchComponent } from './popups/signup-from-search/signup-from-search.component';
import { SmallMessageComponent } from './small-message/small-message.component';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { PopLazyLoadingComponent } from './popups/pop-lazy-loading/pop-lazy-loading.component';
import { DownloadReportComponent } from './download-report/download-report.component';
import { CouponCodeComponent } from './coupon-code/coupon-code.component';
import { PopExtendedThrasholdComponent } from './popups/pop-extended-thrashold/pop-extended-thrashold.component';
import { SpeedometerBloooomScoreComponent } from './speedometer-bloooom-score/speedometer-bloooom-score.component';
import { PlansOfPaymentComponent } from './plans-of-payment/plans-of-payment.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { TheSearchedProductComponent } from './the-searched-product/the-searched-product.component';
import { IconTooltipComponent } from './icon-tooltip/icon-tooltip.component';
import { ProductReviewToStatsPipe } from '../pipes/product-review-to-stats.pipe';
import { CompetittorsHeaderComponent } from './competittors-header/competittors-header.component';
import { FindByKeyPipe } from '../pipes/find-by-key.pipe';
import { DataToObservablePipe } from '../pipes/data-to-observable.pipe';
import { SalesProfitsComponent } from './sales-profits/sales-profits.component';
import { MarketInNumbersComponent } from './market-in-numbers/market-in-numbers.component';
import { BarChartWithPredictionComponent } from './bar-chart-with-prediction/bar-chart-with-prediction.component';
import { ReportHeaderComponent } from './report-header/report-header.component';
import { MenuLinksComponent } from './menu-links/menu-links.component';
import { GeneralReactiveFormComponent } from './general-reactive-form/general-reactive-form.component';
import { PaymentLandingPageComponent } from './payment-landing-page/payment-landing-page.component';
import { SimpleBarChartComponent } from './simple-bar-chart/simple-bar-chart.component';
// import { MarketplaceCardComponent } from './marketplace-card/marketplace-card.component';
import { SmallProductBoxComponent } from './small-product-box/small-product-box.component';
import { PluginSmallBannerComponent } from './plugin-small-banner/plugin-small-banner.component';
import { ProductLauncherComponent } from './product-launcher/product-launcher.component';
import { CampaignCardComponent } from './campaign-card/campaign-card.component';
import { GenericDropdownComponent } from './generic-dropdown/generic-dropdown.component';
import { CreateCampaignComponent } from './popups/create-campaign/create-campaign.component';
import { PreviewBoxComponent } from './preview-box/preview-box.component';
import { GenericFilterForTableComponent } from './generic-filter-for-table/generic-filter-for-table.component';
import { InitialzeDataTableComponent } from './initialze-data-table/initialze-data-table.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { SaparateArrayStringPipe } from '../pipes/saparate-array-string.pipe';
import { ValidationMessageCreateCampaignComponent } from '../validation-message-create-campaign/validation-message-create-campaign.component';
import { EditCampaignComponent } from './popups/edit-campaign/edit-campaign.component';
import { ExtractPlatformAndTargetPipe } from '../pipes/extract-platform-and-target.pipe';
import { AdgroupComponent } from './adgroup/adgroup.component';
import { CheckTotalDaysPipe } from '../pipes/check-total-days.pipe';
import { GetCampaignTotalBudgetPipe } from '../pipes/get-campaign-total-budget.pipe';
import { EditButtonDirective } from '../directives/edit-button.directive';
import { ImageTextBlockComponent } from './image-text-block/image-text-block.component';
import { ChormeExLinkComponent } from './chorme-ex-link/chorme-ex-link.component';


@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        SidebarComponent,
        PageNotFoundComponent,
        AccessDeniedComponent,
        LoginUserHeaderComponent,
        SearchComponent,
        TooltipComponent,
        DropdownLoginComponent,
        ReadMorePopupComponent,
        LogosSectionComponent,
        VideoSectionComponent,
        VideoSliderComponent,
        SafePipe,
        NumberFormatPipe,
        MinPipePipe,
        MaxPipePipe,
        FindDaysPipe,
        GetMonthPipe,
        FindByKeyPipe,
        DataToObservablePipe,
        ProductReviewToStatsPipe,
        NumberOfStarsToArrayPipe,
        StatusColorGeneratorPipe,
        VariationTypesPipe,
        SaparateArrayStringPipe,
        BloooomSectionComponent,
        BenefitsSectionComponent,
        BenefitsSliderComponent,
        CardsSectionComponent,
        CardsComponent,
        TeamComponent,
        CallToActionSectionComponent,
        GetPremiumComponent,
        ReportOverviewComponent,
        ProductHighlightComponent,
        OverviewReviewsComponent,
        ProductSpecsComponent,
        ProductOptimizationComponent,
        MarketChartComponent,
        ShowTooltipDirective,
        LoadingDataDirective,
        EditButtonDirective,
        LimitTextPipe,
        BloooomScoreComponent,
        ReportEcommerceComponent,
        BestVariationsComponent,
        CompettitorsComponent,
        KeywordsComponent,
        ReviewsComponent,
        PopWaitingReportComponent,
        CtaMemberPopupComponent,
        NoDataFoundComponent,
        PopSearchAgainComponent,
        VariationsPopComponent,
        MarketGraphComponent,
        PopPasswordComponent,
        GoogleAdsComponent,
        PopTutorialSectionComponent,
        AlibabaComponent,
        GraphScatterComponent,
        MarketAnalysisComponent,
        KeywordsAndCategoriesComponent,
        BannerForCampaignComponent,
        SummaryResultsKeywordsComponent,
        RatingAndReviewsComponent,
        WodsonDataReviewsComponent,
        ReviewsInsightsComponent,
        GraphBarsComponent,
        ProductAndOptimizatoinComponent,
        ListingTableComponent,
        AlibabaProductSliderComponent,
        PopupRelatedProductComponent,
        SingleVariationComponent,
        PopUnderConstructionsComponent,
        SupplierComponent,
        SubscriptionPlanComponent,
        CheckoutLoggedUserComponent,
        CheckoutLoginUserComponent,
        SignupFromSearchComponent,
        SmallMessageComponent,
        BreadCrumbsComponent,
        PopLazyLoadingComponent,
        DownloadReportComponent,
        CouponCodeComponent,
        PopExtendedThrasholdComponent,
        SpeedometerBloooomScoreComponent,
        PlansOfPaymentComponent,
        ProductDetailsComponent,
        TheSearchedProductComponent,
        IconTooltipComponent,
        CompetittorsHeaderComponent,
        SalesProfitsComponent,
        MarketInNumbersComponent,
        BarChartWithPredictionComponent,
        ReportHeaderComponent,
        MenuLinksComponent,
        GeneralReactiveFormComponent,
        PaymentLandingPageComponent,
        SimpleBarChartComponent,
        SmallProductBoxComponent,
        PluginSmallBannerComponent,
        ProductLauncherComponent,
        CampaignCardComponent,
        GenericDropdownComponent,
        CreateCampaignComponent,
        PreviewBoxComponent,
        GenericFilterForTableComponent,
        InitialzeDataTableComponent,
        ToastNotificationComponent,
        ValidationMessageCreateCampaignComponent,
        EditCampaignComponent,
        ExtractPlatformAndTargetPipe,
        AdgroupComponent,
        CheckTotalDaysPipe,
        GetCampaignTotalBudgetPipe,
        ImageTextBlockComponent,
        ChormeExLinkComponent
    ],
    imports: [AppRoutingModule, CommonModule, BrowserAnimationsModule, TranslateModule, FormsModule, ReactiveFormsModule],
    exports: [
        FooterComponent,
        HeaderComponent,
        PageNotFoundComponent,
        AccessDeniedComponent,
        SidebarComponent,
        ReadMorePopupComponent,
        TranslateModule,
        LoginUserHeaderComponent,
        SearchComponent,
        LogosSectionComponent,
        VideoSectionComponent,
        BloooomSectionComponent,
        BenefitsSectionComponent,
        SafePipe,
        NumberFormatPipe,
        FindDaysPipe,
        GetMonthPipe,
        FindByKeyPipe,
        DataToObservablePipe,
        NumberOfStarsToArrayPipe,
        ProductReviewToStatsPipe,
        StatusColorGeneratorPipe,
        SaparateArrayStringPipe,
        CardsSectionComponent,
        CardsComponent,
        TeamComponent,
        CallToActionSectionComponent,
        ReportOverviewComponent,
        ProductHighlightComponent,
        OverviewReviewsComponent,
        ProductSpecsComponent,
        ProductOptimizationComponent,
        ReportEcommerceComponent,
        PopWaitingReportComponent,
        CtaMemberPopupComponent,
        PopSearchAgainComponent,
        SignupFromSearchComponent,
        MarketGraphComponent,
        AlibabaComponent,
        GraphScatterComponent,
        MarketAnalysisComponent,
        KeywordsAndCategoriesComponent,
        BannerForCampaignComponent,
        SummaryResultsKeywordsComponent,
        RatingAndReviewsComponent,
        WodsonDataReviewsComponent,
        ReviewsInsightsComponent,
        GraphBarsComponent,
        ProductAndOptimizatoinComponent,
        ListingTableComponent,
        AlibabaProductSliderComponent,
        PopupRelatedProductComponent,
        SingleVariationComponent,
        SupplierComponent,
        PopTutorialSectionComponent,
        SubscriptionPlanComponent,
        CheckoutLoggedUserComponent,
        CheckoutLoginUserComponent,
        GetPremiumComponent,
        SmallMessageComponent,
        BreadCrumbsComponent,
        PopLazyLoadingComponent,
        DownloadReportComponent,
        CouponCodeComponent,
        PopExtendedThrasholdComponent,
        SpeedometerBloooomScoreComponent,
        PlansOfPaymentComponent,
        ProductDetailsComponent,
        IconTooltipComponent,
        CompetittorsHeaderComponent,
        SalesProfitsComponent,
        MarketInNumbersComponent,
        BarChartWithPredictionComponent,
        ReportHeaderComponent,
        MenuLinksComponent,
        GeneralReactiveFormComponent,
        PaymentLandingPageComponent,
        SimpleBarChartComponent,
        TheSearchedProductComponent,
        SmallProductBoxComponent,
        PluginSmallBannerComponent,
        ProductLauncherComponent,
        CampaignCardComponent,
        GenericDropdownComponent,
        PreviewBoxComponent,
        GenericFilterForTableComponent,
        InitialzeDataTableComponent,
        ToastNotificationComponent,
        ValidationMessageCreateCampaignComponent,
        ExtractPlatformAndTargetPipe,
        AdgroupComponent,
        CheckTotalDaysPipe,
        GetCampaignTotalBudgetPipe,
        ImageTextBlockComponent,
        ChormeExLinkComponent
    ],
    entryComponents: [ReadMorePopupComponent]
})
export class CoreModule { }
