import { AuthServiceService } from '../../services/auth-service.service';

export class JsScript {
    public static init(): void {
        this.processCookie();
    }

    // Send a message to the parent
    public static sendMessage(msg: string): void {
        // Make sure you are sending a string, and to stringify JSON
        window.parent.postMessage(msg, '*');
    }

    public static processCookie(): void {
        const errorStatusJson: any = { eventType: 'login-check', status: '0', token: '' };
        const successStatusJson: any = { eventType: 'login-check', status: '1', token: '' };
        const accessToken: string = localStorage.getItem(AuthServiceService.TOKEN);
        if (accessToken && accessToken.length > 0) {
            successStatusJson.token = accessToken;
            this.sendMessage(JSON.stringify(successStatusJson));
        } else {
            this.sendMessage(JSON.stringify(errorStatusJson));
        }
    }
}
