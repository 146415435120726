import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productReviewToStats',
    pure: true
})
export class ProductReviewToStatsPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value) {
            const stars: string[] = [];
            const [int, dec] = value.split('.');

            for (let i: number = 0; i < 5; i++) {
                if (i < parseInt(int, 10)) {
                    stars.push('good');
                } else if (parseInt(dec, 10) > 0 && i === parseInt(int, 10)) {
                    stars.push('semi');
                } else {
                    stars.push('bad');
                }
            }
            return stars;
        }
        return null;
    }
}
