import { ReportActionsEnum } from '../store/actions/report.actions';

export interface IMessageIdentifier {
    type: string;
    token: string;
    sender: string;
}

export class SendMessageGetReportByAsin {
    constructor(public messageIdentifier: IMessageIdentifier, public asin: string) {
        this.messageIdentifier.type = ReportActionsEnum.GET_REPORT_BY_ASIN;
    }
}

export class MessageCheckout {
    constructor(public messageIdentifier: IMessageIdentifier, public payload: any) {
        this.messageIdentifier.type = ReportActionsEnum.CHECKOUT;
    }
}
