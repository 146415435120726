import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-header-section-content-page',
    templateUrl: './header-section-content-page.component.html',
    styleUrls: ['./header-section-content-page.component.scss']
})
export class HeaderSectionContentPageComponent implements OnInit {
    @Input() bgImage: string;
    @Input() pageTitle: string;

    ngOnInit(): void {}
}
