import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findDays'
})
export class FindDaysPipe implements PipeTransform {
    todayTime: Date = new Date();
    transform(value: any, args?: any): any {
        if (value || value !== '') {
            const daysCount: number = Math.floor((this.todayTime.getTime() - Date.parse(value)) / 86400000);
            const monthCount: number = Math.floor(daysCount / 30);
            const yearsCount: number = Math.floor(monthCount / 12);
            const timeRelative: string[] = [
                yearsCount >= 1 ? yearsCount + 'yr,' : '',
                monthCount - yearsCount * 12 >= 1 ? monthCount - yearsCount * 12 + 'mo and' : '',
                daysCount - monthCount * 30 - yearsCount * 12 >= 1 ? daysCount - monthCount * 30 - yearsCount * 12 + ' days' : ' 0 days'
            ];
            return timeRelative.join(' ');
        } else {
            return 'No date found..';
        }
    }
}
