import { BestVariation, IReportDTO } from './../../model/report.interface';
import { createSelector } from '@ngrx/store';
import { selectReportsState } from '../reducers/report.reducers';

export const bestVaritionSelector: any = createSelector(
    selectReportsState,
    (state: IReportDTO): BestVariation => {
        if (state && state.bestVariations) {
            const bestVariations: BestVariation = state.bestVariations;
            if (bestVariations.variation) {
                return bestVariations;
            }
        }
        return null;
    }
);

bestVaritionSelector.release();
