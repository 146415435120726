import { ICardsData } from './../../model/ui-interfaces';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
    cards: ICardsData[];
    constructor(private translate: TranslateService) {}
    ngOnInit(): void {
        this.translate.get('cards').subscribe((res: ICardsData) => {
            this.cards = Object.keys(res).map((key: any) => res[key]);
        });
    }
}
