import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findByKey'
})
export class FindByKeyPipe implements PipeTransform {
    transform(productDetailsArray: any, key?: string): any {
        let findValue: string;
        productDetailsArray.forEach((element: any) => {
            if (element.name === key) {
                findValue = element['value'];
            }
        });
        return findValue;
    }
}
