import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { inputInAnimation } from '../../../app/animations/inputAnimation';
import { ElabelInputName } from '../get-premium/get-premium.component';
import { fade } from '../../../app/animations/fade';


class ContactUsRequest {
  constructor(public name?: string, public mailTo?: string, public phone?: string, public message?: string) {
  }
}


@Component({
  selector: 'app-general-reactive-form',
  templateUrl: './general-reactive-form.component.html',
  styleUrls: ['./general-reactive-form.component.scss'],
  animations: [inputInAnimation, fade]
})


export class GeneralReactiveFormComponent implements OnInit {

  public formFields: any = [
    { id: 324, type: 'text', label: 'Full Name', formControl: 'fullName', errorMessage: 'Please add full Name' },
    { id: 436, type: 'email', label: 'Email', formControl: 'mail', errorMessage: 'Please add Email' },
    { id: 576, type: 'tel', label: 'Phone', formControl: 'phone' },
    { id: 674, type: 'text', label: 'Message', formControl: 'message' },
  ]
  public form: FormGroup;
  playAnimation: boolean = false;
  isCurrentInput: boolean = false;
  inputVarName: string = '';
  labelInputName: any = ElabelInputName;
  public messageForm: string = '';
  constructor(private fb: FormBuilder, private httpClient: HttpClient) { }


  public ngOnInit(): void {
    this.form = this.fb.group({
      fullName: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.pattern('[0-9]{7,20}')],
      message: [''],
    })
  }
  public getFormData(form: FormGroup) {
    if (this.form.valid) {
      const request = new ContactUsRequest(form.value.fullName, form.value.mail, form.value.phone, form.value.message);
      this.httpClient.post(environment.contactUsUrl, request, { responseType: 'text' }).pipe(map((response) => {
        if (response) {
          return response;
        }
      },
        (error: any) => {
          this.messageForm = 'Something went wrong, Try again.'
          return error;
        }
      )).subscribe(() => {
        form.reset();
        this.messageForm = 'Thank You. We will handle your request soon as possible.'
        setTimeout(() => {
          this.messageForm = '';
        }, 5000);
      });
    } else {
      Object.keys(this.form.controls).forEach((field: string) => {
        const control: AbstractControl = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }
  public moveLabel(e: any, inputName: any): void {
    switch (inputName.name) {
      case this.labelInputName.FULLNAME:
        this.inputVarName = inputName.name;
        break;
      case this.labelInputName.EMAIL:
        this.inputVarName = inputName.name;
        break;
      case this.labelInputName.INTERESTS:
        this.inputVarName = inputName.name;
        break;
      default:
        break;
    }
    if (e.type === 'focus') {
      this.playAnimation = true;
    } else {
      if (e.target.value !== '') {
        this.playAnimation = true;
      } else {
        this.playAnimation = false;
      }
    }
  }

  public isRequiredField(field: string): boolean {
    const form_field = this.form.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }
}
