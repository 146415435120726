import { logging } from 'protractor';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { AuthServiceService } from '../../services/auth-service.service';

@Component({
    selector: 'dropdown-login',
    templateUrl: './dropdown-login.component.html',
    styleUrls: ['./dropdown-login.component.scss']
})
export class DropdownLoginComponent implements OnInit {
    @Output() dropdownUserIsOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
    labelToggle: string = 'closing the login/signup menu';
    loggedInUserName: string = null;
    constructor(private authService: AuthServiceService) { }

    ngOnInit(): void {
        this.getUsername().then((res: string) => (this.loggedInUserName = res));
    }

    closeDropdown(): void {
        this.dropdownUserIsOpen.emit(false);
    }

    public async login(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.login();
        }
    }

    public async signup(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.signUp();
        }
    }

    public async logout(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
            await this.authService.logOut();
        }
    }
    public getUsername(): Promise<string> {
        return this.authService.getUsername();
    }
}
