import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limitText'
})
export class LimitTextPipe implements PipeTransform {
    transform(value: any, textLength: number, isRelated: boolean): any {
        if (value && textLength && isRelated) {
            if (value.length > textLength) {
                return value.substring(0, textLength) + '...';
            } else {
                return value;
            }
        } else {
            return value;
        }
    }
}
