import { Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import GetKeywordsFilter from '../../../app/dtos/GetKeywordsFilter';
import * as CONSTANTS_CLASS from '../../utils/constants';

@Component({
  host: {
    '(document:click)': 'onClick($event)'
  },
  selector: 'app-generic-filter-for-table',
  templateUrl: './generic-filter-for-table.component.html',
  styleUrls: ['./generic-filter-for-table.component.scss']
})
export class GenericFilterForTableComponent implements OnInit {
  public CONSTANTS_CLASS = CONSTANTS_CLASS;
  public isOpen: boolean;
  public inputTextForPhrase: string;
  @Input() typeId: string;
  @Input() searchTo: any;
  @Input() searchFrom: any;
  @Input() filterText: string;
  @Input() list: string[];
  @Input() moreText: string;
  @Input() choosenOptions: string[];
  @Input() getKeywordsFilter: GetKeywordsFilter;
  @Output() emitPhrase: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }


  public emitPhraseFun(): void {
    this.emitPhrase.emit([this.inputTextForPhrase, this.typeId]);
    this.toggleFilter();
  }

  public toggleFilter(): void {

    this.isOpen = !this.isOpen;
  }


  public handleSelectedOptions(key: string, value: string): void {
    if (!this.getKeywordsFilter[key])
      this.getKeywordsFilter[key] = [];
    if (this.getKeywordsFilter[key].includes(value)) {
      this.getKeywordsFilter[key].splice(this.getKeywordsFilter[key].indexOf(value), 1);
    } else {
      this.getKeywordsFilter[key].push(value)
    }
    if (this.getKeywordsFilter[key].length <= 0)
      this.getKeywordsFilter[key] = null;
    this.choosenOptions = this.getKeywordsFilter[key];
  }

  public onClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) // or some similar check
      this.isOpen = false;
  }
  public clearFilter(): void {
    if (this.getKeywordsFilter) {
      this.getKeywordsFilter[this.searchFrom] = null;
      this.getKeywordsFilter[this.searchTo] = null;
    }
    this.inputTextForPhrase = null;
    this.emitPhraseFun();
  }

}
