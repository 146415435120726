import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

/**
 * Reference from 
 * https://www.npmjs.com/package/ng6-toastr-notifications
 */
@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(public toastr: ToastrManager) { }

  showSuccess(message) {
    this.toastr.successToastr(message);
  }

  showError(message) {
    this.toastr.errorToastr(message);
  }
}
