import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'no-data-found',
    templateUrl: './no-data-found.component.html',
    styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent implements OnInit {
    @Input() public showOnlyText: boolean;
    @Input() public noDataFoundText: string;
    @Input() public noDataFoundTitle: string;
    @Input() public imgSrc: string;

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.noDataFoundText = this.noDataFoundText ? this.noDataFoundText : this.translate.instant('noDataFound.defaultText');
    }
}
