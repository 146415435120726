import { IReportDTO } from '../../model/report.interface';

export interface IReportState {
    report: IReportDTO;
    errorKey: string;
}

export const initialReportState: IReportState = {
    report: null,
    errorKey: null
};
