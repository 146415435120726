import { Component, OnInit } from '@angular/core';
import { flyInOut } from '../../../app/animations/menuOpenCloseAnimation';
import { fade } from '../../../app/animations/fade';
import { AuthServiceService } from '../../../app/services/auth-service.service';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-report-header',
    templateUrl: './report-header.component.html',
    styleUrls: ['./report-header.component.scss'],
    animations: [fade, flyInOut]
})
export class ReportHeaderComponent implements OnInit {

    public accountText: string = 'Create an Account';
    public isLoggedIn: boolean;
    constructor(private authService: AuthServiceService) { }

    public ngOnInit(): void {
        this.getUsername().then((res: string) => {
            if (res) {
                this.accountText = 'Hi, ' + res;
                this.isLoggedIn = true;
            }
        });
    }

    public async login(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.login();
            this.isLoggedIn = false;
        }
    }

    public async signup(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
        } else {
            await this.authService.signUp();
        }
    }

    public async logout(): Promise<void> {
        const isLoggedIn: boolean = await this.authService.isUserLoggedIn();
        if (isLoggedIn) {
            await this.authService.logOut();
        }
    }
    public getUsername(): Promise<string> {
        return this.authService.getUsername();
    }
}
