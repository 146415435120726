import { Pipe, PipeTransform } from '@angular/core';
import { CampaignStatusInEngine } from '../model/google/newGoogleCampaignRequest';

@Pipe({
  name: 'statusColorGenerator'
})
export class StatusColorGeneratorPipe implements PipeTransform {
  public statuses: any = CampaignStatusInEngine;
  transform(status: CampaignStatusInEngine): string {
    switch (status) {
      case this.statuses.Active:
        return '#37d699';

      case this.statuses.Paused:
        return '#f2ab38';

      case this.statuses.Pending:
        return '#37d699';

      case this.statuses.Expired:
        return '#919191';

      case this.statuses.Scheduled:
        return '#775cff';

      case this.statuses.Suspended:
        return '#775cff';

      case this.statuses.Disapproved:
        return '#775cff';

    }

  }
}
